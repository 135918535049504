<script>
  import { Input } from 'element-ui';
  import CONST from '@/config/const';

  export default {
    name: 'CustomElementInput',
    extends: Input,
    watch: {
      $attrs() {
        this.$attrs.maxlength = this.$attrs.maxlength || CONST.MAX_LENGTH_INPUT;
      }
    },
  }
</script>
