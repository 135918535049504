<template>
	<div>
<p class="title" style="margin-bottom: 40px; font-weight: 500">Danh sách đồng bộ</p>
		<!-- Button Action -->
		<el-row type="flex" class="mt3" justify="space-between" align="bottom">
			<div class="el-pagination-size mt3">
				<span>Số bản ghi trên trang:&nbsp;</span>
				<el-dropdown @command="handleCommand">
					<span class="el-dropdown-link">
						{{ search.pageSize }}
						<i class="el-icon-caret-bottom"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item
							v-for="item in listPaginate"
							:key="item.key"
							:command="item.value"
						>{{ item.value }}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

		</el-row>
		<el-card shadow="never" class="mt3" v-loading="loading">
			<!-- Table Data -->
			<el-table :data="data.data" style="width: 100%">
				<el-table-column property="from_date" label="Từ ngày">
          <template scope="scope">
            {{ scope.row.from_date | formatDate }}
          </template>
        </el-table-column>
				<el-table-column property="to_date" label="Đến ngày">
          <template scope="scope">
            {{ scope.row.to_date | formatDate }}
          </template>
        </el-table-column>
				<el-table-column property="total_success" label="Số văn bằng"></el-table-column>
				<el-table-column property="status" label="Trạng thái"></el-table-column>
				<el-table-column property="created_at" label="Thời gian đồng bộ">
          <template scope="scope">
            {{ scope.row.created_at | formatDatetime }}
          </template>
        </el-table-column>
			</el-table>
			<!-- Pagination -->
			<el-row type="flex" justify="space-between" align="middle" class="mt3" v-show="data.total">
				<div class="el-pagination-info">
					<span>Hiển thị {{ data.from }}-{{ data.to }} của {{ data.total }} bản ghi</span>
				</div>
				<el-pagination
					layout="prev, pager, next, jumper"
					:total="data.total"
					:current-page="data.current_page"
					:page-size="data.per_page"
					@current-change="handleChangePage"
				></el-pagination>
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import CooperatorRequest from "../api/cooperator";
	import CONST from "@/config/const";
  import OrganizationRequest from "@/packages/statistical/api/organization";

	export default {
		name: "CrawlHistory",
		data() {
			return {
				loading: false,
				search: {
					number: "",
					from_submission_date: "",
					to_submission_date: "",
					organization_name: "",
					name: "",
					request_type: "",
					status: 2,
					pageSize: CONST.PAGINATION[0].value,
          school: ''
				},
				data: [],
				sortParams: [],
				listPaginate: CONST.PAGINATION,
				listRequestTypes: CONST.DEGREE_REQUEST_TYPES,
        paramsSearch: {},
        showSchool: false,
        placeholderSchool: "Tất cả",
        organizations: {},
        organizationsSearch: [],
        latestModel: ""
			};
		},
		methods: {
			async handleSearch() {
				this.loading = true;
				try {
          this.paramsSearch = {...this.search}
					let res = await CooperatorRequest.get(this.search);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			async handleChangePage(page) {
				this.loading = true;
				let param = { ...this.paramsSearch, page: page };
				try {
					let res = await CooperatorRequest.get(param);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			async handleCommand(command) {
				this.search.pageSize = command;
        this.paramsSearch.pageSize = command;
				this.loading = true;
				try {
					let res = await CooperatorRequest.get(this.paramsSearch);
					this.data = res.data;
				} catch (e) {}
				this.loading = false;
			},
			async handleExport() {
				if (!this.checkLimitExport(this.data.total)) {
					this.loading = true;
					try {
						let res = await CooperatorRequest.export(this.paramsSearch);
						window.location.assign(res);
					} catch (error) {}
					this.loading = false;
				}
			},
      autoSelectFocus() {
        this.search.organization_name = "";
      },
      autoSelectBlur() {
        if (this.search.organization_id) {
          this.search.organization_name = this.search.placeholder;
        }
      },
      autoSelectChoice(item) {
        this.search.organization_id = item.id;
        this.search.placeholder = item.value;
        this.search.organization_name = item.value;
        if (item.type === "2") {
          this.showSchool = true;
        } else {
          this.showSchool = false;
          this.placeholderSchool = "";
          this.search.school = "";
        }
      },
      autoSelectClear() {
        this.search.organization_id = "";
        this.search.placeholder = "Tất cả";
        this.search.degree_authority_unit_type = "";
      },
      async autoSelectSearch(name, cb) {
        try {
          let res = await OrganizationRequest.getOrganization({
            name: this.search.organization_name,
          });
          let results = res.data.data.map((e) => {
            return { value: e.name, type: e.degree_authority_unit_type, id: e.id };
          });
          cb(results);
        } catch (error) {}
      },
      autoSelectFocusSchool() {
        this.$refs.popup.suggestions = [];
      },
      autoSelectBlurSchool() {
      },
      autoSelectChoiceSchool(item) {},
      autoSelectClearSchool() {
        this.placeholderSchool = "Tất cả";
      },
      async autoSelectSearchSchool(name, cb) {
        try {
          let res = await OrganizationRequest.getSchool({
            name: this.search.school,
            id: this.search.organization_id
          });
          let results = res.data.data.map((e) => {
            return { value: e.school };
          });
          cb(results);
        } catch (error) {}
      },
      selectItem(item) {
        this.latestModel = item.name;
        this.search.organization_name = item.name;
        this.search.organization_id = item.id;
        this.showSchool = false;
        if (item.degree_authority_unit_type === "2") {
          this.showSchool = true;
        }
      },
      setDataLatest() {
        this.search.organization_name = this.latestModel;
      },
      filterText() {
			  this.search.school = '';
        let key = _.toLower(this.search.organization_name);
        this.organizations = _.filter(this.organizationsSearch, (item) => {
          return this.nonAccentVietnamese(_.toLower(item.name)).indexOf(this.nonAccentVietnamese(key)) !== -1;
        })
      },
      async getOrganization() {
        let res = await OrganizationRequest.getOrganization({
          name: this.search.organization_name,
        });
        this.organizations = res.data;
        this.organizationsSearch = res.data
      },
		},
		async created() {
			this.loading = true;
			try {
        this.paramsSearch = {...this.search}
				let res = await CooperatorRequest.getHistory(this.search);
				this.data = res.data;
			} catch (error) {}
			this.loading = false;
		},
    watch: {
      "search.organization_name": function () {
        this.filterText();
      }
    }
	};
</script>

<style lang="scss" scoped>
  .box {
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  background-color: #fff;
  max-height: 180px;
  margin: 5px 0;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 5px;
    height: 100px;
  }
  ::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    //border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px #C0C4CC;
  }
  ul {
    margin: 0;
  }
  .wrap {
    max-height: 280px;
    padding: 10px 0;
    box-sizing: border-box;
    overflow: scroll;
    li {
      padding: 0 20px;
      margin: 0;
      line-height: 34px;
      cursor: pointer;
      color: #606266;
      font-size: 14px;
      list-style-type: none;
    }
    .item {
      white-space: normal;
      text-overflow: unset;
      overflow: unset;
      height: auto;
      word-break: break-word;
      &:hover {
        background-color: #F5F7FA;
      }
    }
  }
}
  .el-form {
    .search-number {
      margin-top: 10px;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
    .search-request_type {
      .el-form-item {
        margin-bottom: 0px;
      }
    }
  }
  /deep/ .el-card__body {
    padding: 15px 20px;
  }
</style>
