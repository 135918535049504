<template>
  <el-row class="header">
    <el-col :span="24" class="header-first">
      <div>
        <img v-bind:src="'/image/logo.png'" />
      </div>
      <el-col justify="end">
          <ul class="menu-header">
            <li class="item">
              <el-dropdown>
              <span class="el-dropdown-link">
                Quản trị hệ thống<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <div @click="gotoUserInfo()"><el-dropdown-item>Tài khoản</el-dropdown-item></div>
                  <div @click="logout"><el-dropdown-item>Đăng Xuất</el-dropdown-item></div>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
      </el-col>
    </el-col>
    <el-col :span="24" class="header-second">
        <p>
          <span>--------------</span>
          HỆ THỐNG TRA CỨU VĂN BẰNG, CHỨNG CHỈ
          <span>--------------</span>
        </p>
    </el-col>
  </el-row>
</template>

<script>
import AuthenticationStore from "@/requests/AuthenticationStore";
import UserRequest from "@/requests/UserRequest";
import { mapState } from 'vuex';

export default {
  name: "Header",
  props: {
    msg: String,
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    logout() {
      UserRequest.logout(this.user);
      AuthenticationStore.removeAuthenticationData();
      this.$router.push('/login')
    },
    gotoUserInfo() {
      let userRoute = 'UserInfo';
      if (this.user.type_user === 2) {
        userRoute = 'CoopUserInfo';
      }
      this.$router.push({'name': userRoute});
      this.$store.dispatch('getAdminMenu');
    }
  },
};
</script>
<style lang="scss">
  .header-search {
    .el-input--prefix .el-input__inner {
      background: rgba(192, 196, 204, 0.2);
      border-radius: 20px;
    }
  }
  .logout.el-dropdown-menu__item {
    padding: 0px;
    span {
      padding: 0px 20px;
      height: 100%;
      width: 100%;
    }
  }
</style>
<style scoped lang="scss">
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 88px;
    z-index: 10;
    background: #eeeeee;
    .header-first {
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      height: 60px;
      display: flex;
      align-items: center;
      .menu-header {
        list-style: none;
        display: inline-flex;
        float: right;
        align-items: center;
        margin-right: 50px;
        li {
          padding: 0px 25px;
        }
        .header-search {
          width: 105px;
        }
      }
    }
    .header-second {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #196CBA;
      p {
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        span {
          padding: 0px 10px;
        }
      }
    }
  }

</style>
