import MasterdataRequest from "@/requests/MasterdataRequest";

const state = {
  masterdata: {},
};

const getters = {
  getMasterData(state) {
    return state.masterdata;
  },
};

const actions = {
  async reloadMasterData ({ commit }) {
    return new Promise((resolve) => {
      MasterdataRequest.getAll().then((res) => {
        commit('updateMasterdata', res);
        resolve(res);
      });
    });
  },
};

const mutations = {
  updateMasterdata (state, data) {
    state.masterdata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
