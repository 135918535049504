import Request from './Request';

class HistoryRequest extends Request {
  async getList(params) {
    let url = '/admin/history/email';
    return await this.get(url, params);
  }
  async getLogImport(params) {
    let url = '/admin/history/log-import';
    return await this.get(url, params);
  }

  async getLogExport(params) {
    let url = '/admin/history/log-export';
    return await this.get(url, params);
  }

  async getLogChangeData(params) {
    let url = '/admin/history/log-change-data';
    return await this.get(url, params);
  }

  async getLogLoginOut(params) {
    let url = '/admin/history/log-login-logout';
    return await this.get(url, params);
  }

  async getLogCooperator(params) {
    let url = '/admin/history/log-cooperator';
    return await this.get(url, params);
  }

  async exportHistoryLoginOut(params) {
    let url = '/admin/history/export-login-logout';
    return await this.get(url, params, {
      'api-action': 'ExHistoryRequest-LoginLogout-export'
    }, {responseType: 'blob'});
  }

  async exportHistoryUser(params) {
    let url = '/admin/history/export-history-user';
    return await this.get(url, params, {
      'api-action': 'ExHistoryRequest-Email-export'
    }, {responseType: 'blob'});
  }

  async exportHistoryImport(params) {
    let url = '/admin/history/export-history-import';
    return await this.get(url, params, {
      'api-action': 'ExHistoryRequest-Import-export'
    }, {responseType: 'blob'});
  }

  async exportHistoryExport(params) {
    let url = '/admin/history/export-history-export';
    return await this.get(url, params, {
      'api-action': 'ExHistoryRequest-Export-export'
    }, {responseType: 'blob'});
  }

  async exportHistoryChangeData(params) {
    let url = '/admin/history/export-history-change-data';
    return await this.get(url, params, {
      'api-action': 'ExHistoryRequest-ChangeData-export'
    }, {responseType: 'blob'});
  }
}
export default HistoryRequest.getInstance()
