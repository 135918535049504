<template>
  <el-row>
    <el-col :span="24" class="group-search">
      <el-form label-position="left">
        <p class="title-search">Quản lý đơn vị cấp bằng</p>
        <el-row>
          <el-col :span="11">
            <el-form-item label="ID đơn vị">
              <el-input
                maxlength="8"
                v-model="params.code"
                @keyup.enter.native="searchOrganization"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="Tên đơn vị cấp bằng" label-width="160px">
              <el-input v-model="params.name" @keyup.enter.native="searchOrganization"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="16" class="button-search">
            <el-form-item>
              <el-button type="primary" class="btn" @click="searchOrganization">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <el-col class="list-button">
      <div class="pagination-size">
        <p>Số bản ghi trên trang:</p>
        <el-dropdown @command="ChangePageSize">
          <span class="el-dropdown-link">
            {{ paramsSearch.limit }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in listPaginate"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button
        type="success"
        class="btn"
        @click="$router.push({name: 'OrganizationCreateUpdate'})"
        :disabled="hasPermissionOnScreen(PERMISSION_CREATE)"
      >
        <span>Thêm Mới</span>
      </el-button>
      <el-button
        type="danger"
        class="btn"
        v-show="multipleSelection.length !== 0"
        @click="deleteMultiOrganization()"
        :disabled="hasPermissionOnScreen(PERMISSION_DELETE)"
      >Xóa</el-button>
      <el-button
        type="danger"
        class="btn"
        v-show="multipleSelection.length === 0"
        @click="deleteMultiOrganization()"
        disabled="disabled"
      >Xóa</el-button>
      <template>
        <div class="upload-btn-wrapper">
          <el-button
            for="upload-organization"
            type="primary"
            class="btn"
            @click="$router.push({name: 'OrganizationImport'})"
            :disabled="hasPermissionOnScreen(PERMISSION_CREATE)"
          >Tải Dữ Liệu</el-button>
        </div>
      </template>
      <el-button
        type="warning"
        class="btn"
        @click="exportOrganization()"
        :disabled="hasPermissionOnScreen(PERMISSION_READ)"
      >Kết Xuất</el-button>
    </el-col>

    <el-card shadow="never">
      <el-table
        :data="getData"
        style="width: 100%"
        empty-text="Không có dữ liệu"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="code" label="ID đơn vị"></el-table-column>
        <el-table-column prop="name" label="Tên đơn vị cấp bằng" width="300"></el-table-column>
        <el-table-column prop="degree_authority_unit_type" label="Loại đơn vị cấp bằng" width="200">
          <template scope="scope"><span v-if="['1', '2', 1, 2].includes(scope.row.degree_authority_unit_type)">{{ lodash.find(CONST.DEGREE_AUTHORITY_UNITS, (item) => {
              return item.key === scope.row.degree_authority_unit_type;
            }).value }}</span></template>
        </el-table-column>
        <el-table-column prop="deleted_at" label="Tình trạng">
          <template scope="scope">
            <el-switch
              @change="updateOrganization(scope.row, $event)"
              :value="filterStatus(scope.row.status)"
              class="item-status"
              :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="Số văn bằng đã gửi">
          <template scope="scope">
            {{ scope.row.degree_verification_count | formatNumber}}
          </template>
        </el-table-column>
        <el-table-column label="Đã chấp nhận">
          <template scope="scope">
            {{ scope.row.degree_verification_approved_count | formatNumber}}
          </template>
        </el-table-column>
        <el-table-column label align="center">
          <template class="action-organization" scope="scope">
            <el-button
              type="info"
              icon="el-icon-edit"
              circle
              size="mini"
              @click="$router.push({name: 'OrganizationCreateUpdate', query: { organization: scope.row.id }})"
              :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              @click="deleteOrganization(scope.row)"
              :disabled="hasPermissionOnScreen(PERMISSION_DELETE)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <custom-el-pagination
        :length="total"
        :paginateFrom="paginateFrom"
        :paginateTo="paginateTo"
        :total="total"
        :pageSize="pageSize"
        :page="paramsSearch.page"
        @handleCurrentChange="handleCurrentChange"
      ></custom-el-pagination>
    </el-card>
  </el-row>
</template>

<script>
import OrganizationRequest from "@/requests/OrganizationRequest";
import CONST from "@/config/const";
import _ from 'lodash'

export default {
  name: "Organization",
  props: {
    msg: String,
  },
  data() {
    return {
      getData: [],
      CONST: CONST,
      lodash : _,
      params: {
        limit: CONST.PAGINATION[0].value,
        page: 1,
      },
      paramsSearch: {
        limit: CONST.PAGINATION[0].value,
        page: 1,
      },
      multipleSelection: [],
      page: 1,
      pageSize: CONST.PAGINATION[0].value,
      total: 0,
      paginateFrom: 1,
      paginateTo: CONST.PAGINATION[0].value,
      listPaginate: CONST.PAGINATION,
      lastPage: 2,
    };
  },
  methods: {
    async getList() {
      const data = await OrganizationRequest.getListOrganization(this.paramsSearch);
      if (data.data.data.length === 0 && this.page != 1) {
        this.params.page = this.page - 1;
        this.getList();
      }
      this.getData = data.data.data;
      this.page = data.data.current_page;
      this.pageSize = parseInt(data.data.per_page);
      this.total = data.data.total;
      this.paginateFrom = data.data.from;
      this.paginateTo = data.data.to;
      this.lastPage = parseInt(data.data.last_page);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    filterStatus(value) {
      return value !== 1 ? false : true;
    },
    searchOrganization() {
      this.params.page = 1;
      this.paramsSearch = {...this.params}
      this.getList();
    },
    async updateOrganization(data, status) {
      await OrganizationRequest.updateStatusOrganization({
        id: data.id,
        status: data.status,
      })
        .then(() => {
          this.showSuccess("Thành công.");
          data.status = status ? 1 : 0;
        })
        .catch(() => {
          this.showError("Thất bại.");
          data.status = status ? 0 : 1;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      const param = Object.assign(this.paramsSearch, {
        page: this.page,
      });
      this.getList(param);
    },
    async deleteOrganization(row) {
      if (await this.showConfirmMixin()) {
        OrganizationRequest.deleteOrganization(row.id, row.code)
          .then(() => {
            this.showSuccess("Xoá thành công.");
            this.getList(this.params);
          })
          .catch(() => {
            this.showError("Lỗi khi xóa đơn vị cấp bằng.");
            this.getList(this.params);
          });
      }
    },
    async deleteMultiOrganization() {
      if (await this.showConfirmMixin()) {
        const listId = [];
        this.multipleSelection.map(function (value) {
          listId.push(value.id);
        });

        OrganizationRequest.deleteMultiOrganization({ list: listId })
          .then(() => {
            this.showSuccess("Xoá thành công.");
            this.getList(this.params);
          })
          .catch(() => {
            this.showError("Lỗi khi xóa đơn vị cấp bằng.");
            this.getList(this.params);
          });
      }
    },
    exportOrganization() {
      OrganizationRequest.exportOrganization(this.paramsSearch).then((res) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([res]));
        link.setAttribute("download", "Quan_ly_don_vi_cap_bang.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    },
    ChangePageSize(e) {
      this.paramsSearch.limit = e;
      this.paramsSearch.page = 1;
      this.getList(this.paramsSearch);
    },
  },
  created() {
    this.getList(this.params);
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/style";
.organization-table {
  thead {
    .el-table_1_column_1 {
      .el-checkbox__input {
        display: none;
      }
    }
  }
}
.button-search {
  padding-top: 25px;
  .el-form-item__content {
    text-align: right;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/style";
.upload-btn-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
h3 {
  margin: 40px 0 0;
}
a {
  color: #42b983;
}
.link-back {
  background: #fff;
  .link-back-list {
    display: table-cell;
    vertical-align: middle;
    height: 37px;
    a {
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #303133;
      padding: 0px 20px;
      height: 37px;
    }
    a.active {
      border-bottom: 2px solid #409eff;
      padding-bottom: 6px;
    }
  }
}
.btn-import {
  margin: 0px 12px;
}
</style>
