<template>
    <div>
        <template>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'Ranking' }">Xếp loại</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.query.ranking !== undefined ? 'Chỉnh sửa' : 'Thêm mới'}}</el-breadcrumb-item>
            </el-breadcrumb>
        </template>
        <el-row>
            <el-form class="create-edit-form" ref="form" :rules="rules" :model="form"  label-position="left">
                <p class="title-form">{{$route.query.ranking !== undefined ? 'Chỉnh sửa' : 'Thêm mới'}} xếp loại</p>
                <el-row>
                    <el-col :span="14" class="ranking-search_id">
                        <el-form-item label="Mã" prop="code">
                            <el-input maxlength='8' v-model="form.code"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="14" class="ranking-search_name">
                        <el-form-item label="Xếp loại" prop="type">
                            <el-input v-model="form.type"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
                <el-row>
                    <el-col :span="10" class="organization-status">
                        <el-form-item label="Tình trạng">
                            <el-switch v-model="form.status">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item class="btn-group-form">
                    <el-button type="danger" class="btn" v-if="this.$route.query.ranking !== undefined"
                               @click="deleteRanking(form)" :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)">Xóa</el-button>
                    <el-button class="btn" type="primary" plain @click="resetForm()" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)" v-if="this.$route.query.ranking !== undefined">Đặt Lại</el-button>
                    <el-button class="btn" type="primary" @click="submitForm()" v-if="this.$route.query.ranking !== undefined" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Lưu</el-button>
                    <el-button class="btn" type="primary" @click="submitForm()" v-else :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">Lưu</el-button>
                </el-form-item>
            </el-form>
        </el-row>
    </div>
</template>

<script>
    import RankingRequest from '@/requests/RankingRequest';

    export default {
        name: 'RankingCreateUpdate',
        props: {
            msg: String
        },
        data() {
            let checkCode = (rule, value, callback) => {
                RankingRequest.checkCode({code: value, id: this.form.id}).then((res) => {
                    if (res.data.length === 1) {
                        callback(new Error('Mã đã tồn tại.'));
                    } else {
                        callback();
                    }
                });
            };
            return {
                form: {
                    code: '',
                    type: '',
                    id:null,
                    status: true
                },
                temp: {},
                rules: {
                    code: [
                        { required: true, message: 'Vui lòng nhập mã.', trigger: 'blur' },
                        { max: 8, message: 'Mã nhỏ hơn 8 kí tự', trigger: 'blur' },
                        { pattern:/^[a-zA-Z0-9]*$/, message: 'Mã chỉ chứa chữ cái tiếng Anh và chữ số.', trigger: 'blur' },
                        { validator: checkCode, trigger: 'blur'}
                    ],
                    type: [
                        { required: true, message: 'Vui lòng nhập xếp loại.', trigger: 'blur' },
                        { max: 250, message: 'Xếp loại nhỏ hơn 250 kí tự', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            submitForm() {
                this.$refs['form'].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    if (this.$route.query.ranking !== undefined) {
                        this.updateRanking();
                        return;
                    }
                    this.createRanking();
                });

            },
            async deleteRanking(row) {
              let checkConfirm = await this.showConfirmMixin(`Bạn có chắc chắn muốn xóa không?`);
              if (checkConfirm) {
                RankingRequest.deleteRanking(row.id, row.code).then(() => {
                  this.showSuccess('Xóa thành công.')
                  this.$router.push({name: 'Ranking'});
                }).catch(() => {
                  this.showError('Lỗi khi xóa.');
                });
              }
            },
            createRanking() {
                RankingRequest.createRanking(this.form).then(() => {
                    this.showSuccess('Thêm mới xếp loại thành công.');
                    this.$router.push({name: 'Ranking'});
                }).catch(() => {
                    const error = "Có lỗi khi thêm xếp loại";
                    this.showError(error);
                });
            },
            updateRanking() {
                RankingRequest.updateRanking(this.form, this.$route.query.ranking).then(() => {
                    this.showSuccess('Cập nhật xếp loại thành công.');
                    this.$router.push({name: 'Ranking'});
                }).catch(() => {
                    const error = "Có lỗi khi sửa xếp loại";
                    this.showError(error);
                });
            },
            resetForm() {
              this.form = _.clone(this.temp);
            }
        },
        created() {
            if (this.$route.query.ranking !== undefined) {
                RankingRequest.showRanking(this.$route.query.ranking).then((res) => {
                    this.form.code = res.data.code;
                    this.form.id = res.data.id;
                    this.form.type = res.data.type;
                    this.form.status = res.data.status == 1 ? true : false;
                    this.temp = _.clone(this.form)
                });
            }

        }
    }
</script>
<style lang="scss">
    @import "@/assets/sass/_variables";
    .breadcrumb-ranking {
        .el-breadcrumb__item {
            .el-breadcrumb__inner.is-link, .el-breadcrumb__inner {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #2C2C2C;
            }
        }
    }
    .certificate-list {
        .el-form-item__content {
            margin-left: 0px !important;
        }
    }
    .ranking-status {
        .el-form-item__label {
            width: 140px!important;
        }
        .el-form-item__content {
            margin-left: 140px!important;
        }
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .certificate-title, .ranking-status-title{
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #909399;
        margin-bottom: 24px;
    }
    .certificate-list {
        .el-checkbox {
            display: block;
        }
    }
    .el-form-item__label {
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #2C2C2C;
    }
</style>
