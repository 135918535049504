<template>
	<div>
		<!-- Form Search -->
		<el-card shadow="never" class="mt3">
			<el-form label-position="left" label-width="120px" :model="search" style="border:none">
				<h3 class="mt0 mb1">Chờ chấp nhận ({{ data.count_degree }})</h3>
				<el-link type="danger" :underline="false">
					Hiện tại có {{ data.count_degree | formatNumber }} văn bằng đang chờ chấp nhận,
					được gửi lên từ {{ data.count_organization | formatNumber }} đơn vị cấp bằng.
				</el-link>
        <el-row :gutter="20" class="mt4 search-number">
          <el-col :md="12">
            <el-row>
              <el-col :sm="24" :md="20">
                <el-form-item label="Đơn vị cấp bằng" label-width="150px">
                  <el-input @keypress.native="filterText" v-model="search.organization_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="20">
                <el-form-item label-width="150px">
                  <div class="box">
                    <ul class="wrap">
                      <li class="item" v-for="item in organizations" @click="selectItem(item)">
                        {{item.name}}
                      </li>
                    </ul>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :sm="24" :md="20">
                <el-form-item label="Trường THPT" label-width="150px">
                  <el-autocomplete
                    :disabled="!this.showSchool"
                    :placeholder="search.placeholderSchool"
                    style="width: 100%"
                    v-model="search.school"
                    ref="popup"
                    :fetch-suggestions="autoSelectSearchSchool"
                    :trigger-on-focus="true"
                    @select="autoSelectChoiceSchool"
                    @focus="autoSelectFocusSchool"
                    @blur="autoSelectBlurSchool"
                    @clear="autoSelectClearSchool"
                    clearable
                  ></el-autocomplete>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :md="12">
            <el-row>
              <el-col :sm="24" :md="{span: 20, push: 2}">
                <el-form-item label="Số hiệu bằng">
                  <el-input @keyup.enter.native="handleSearch()" v-model="search.number"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :sm="24" :md="{span: 20, push: 2}">
                <el-form-item label="Họ và tên">
                  <el-input @keyup.enter.native="handleSearch()" v-model="search.name"></el-input>
                </el-form-item>
                </el-col>
            </el-row>
            <el-row>
              <el-col :sm="24" :md="{span: 20, push: 2}">
                <el-form-item label="Loại yêu cầu">
                  <el-select v-model="search.request_type" clearable placeholder="Tất cả" style="width: 100%">
                    <el-option v-for="(item, key) in listRequestTypes" :label="item" :value="key" :key="key"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :sm="24" :md="{span: 20, push: 2}">
                <el-form-item label="Ngày nộp">
                  <el-row :gutter="0">
                    <el-col :span="11">
                      <el-date-picker
                        type="date"
                        placeholder="Ngày/tháng/năm"
                        v-model="search.from_submission_date"
                        style="width: 100%;"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                      ></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2" style="text-align:center">~</el-col>
                    <el-col :span="11">
                      <el-date-picker
                        type="date"
                        placeholder="Ngày/tháng/năm"
                        v-model="search.to_submission_date"
                        style="width: 100%;"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                      ></el-date-picker>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="search-request_type" style="margin-top: 20px">
              <el-col :sm="24" :md="{span: 20, push: 2}">
                <el-row type="flex" align="middle" justify="end">
                  <el-button type="primary" @click="handleSearch()">Tìm</el-button>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
			</el-form>
		</el-card>
		<div class="el-pagination-size mt3">
			<span>Số bản ghi trên trang:&nbsp;</span>
			<el-dropdown @command="handleCommand">
				<span class="el-dropdown-link">
					{{ search.pageSize }}
					<i class="el-icon-caret-bottom"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item
						v-for="item in listPaginate"
						:key="item.key"
						:command="item.value"
					>{{ item.value }}</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<!-- Button Action -->
		<el-row :gutter="20">
			<el-col :sm="24" :md="11" class="mt3 mb2">
				<span
					class="count_check"
				>Đã chọn ({{ (checkAll ? data.total - multipleSelection.length : multipleSelection.length) | formatNumber }}/{{ data.total | formatNumber }} văn bằng)</span>
			</el-col>
			<el-col :sm="24" :md="13" style="text-align:right">
				<el-button
					v-if="multipleSelection.length || (checkAll && data.data.length)"
					:disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
					type="success"
					@click="handleConfirmApproved()"
				>Chấp Nhận</el-button>
				<el-button v-else disabled type="success">Chấp Nhận</el-button>
				<el-button
					v-if="multipleSelection.length || (checkAll && data.data.length)"
					:disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
					type="danger"
					@click="handleConfirmReject()"
				>Từ Chối</el-button>
				<el-button v-else disabled type="danger">Từ Chối</el-button>
				<el-button
					:disabled="hasPermissionOnScreen(PERMISSION_READ)"
					type="warning"
					@click="handleExport()"
				>Kết Xuất</el-button>
			</el-col>
		</el-row>
		<el-checkbox
			class="moet-checkbox-all"
			:indeterminate="isIndeterminate"
			v-model="checkAll"
			@change="handleCheckAll()"
		></el-checkbox>
		<el-card shadow="never" v-loading="loading">
			<!-- Table Data -->
			<el-table ref="table" @select="handleSelect" :data="data.data" style="width: 100%">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="Mã định danh (ID)" >
					<template slot-scope="scope">
						<el-link
							type="primary"
							@click="$router.push({name: 'PendingDegree',
							query: {id: scope.row.id}})"
						>{{ scope.row.number }}</el-link>
					</template>
				</el-table-column>
				<el-table-column property="certificate.name" label="Trình độ"></el-table-column>
				<el-table-column property="organization.name" label="Đơn vị cấp bằng"></el-table-column>
<!--        <el-table-column property="organization.degree_authority_unit_type" label="Loại đơn vị cấp bằng"></el-table-column>-->
				<el-table-column property="name" label="Họ và tên"></el-table-column>
				<el-table-column property="graduation_year" label="Năm tốt nghiệp"></el-table-column>
				<el-table-column property="ranking.type" label="Xếp loại tốt nghiệp"></el-table-column>
				<el-table-column property="request_type" label="Loại yêu cầu"></el-table-column>
				<el-table-column property="submission_date" label="Ngày nộp"></el-table-column>
			</el-table>
			<!-- Pagination -->
			<el-row type="flex" justify="space-between" align="middle" class="mt3" v-show="data.total">
				<div class="el-pagination-info">
					<span>Hiển thị {{ data.from }}-{{ data.to }} của {{ data.total }} bản ghi</span>
				</div>
				<el-pagination
					layout="prev, pager, next, jumper"
					:total="data.total"
					:current-page="data.current_page"
					:page-size="data.per_page"
					@current-change="handleChangePage"
				></el-pagination>
			</el-row>
		</el-card>
    <el-dialog class="custom_dialog" title="Xác Nhận" :visible.sync="dialogFormVisible">
      {{mes}}
      <el-form :model="form_password" :rules="rules"  ref="form_password">
		  <el-form-item v-if="checkReject" label="Vui lòng nhập lý do từ chối:" prop="reason_reject">
          <el-input type="text" v-model="form_password.reason_reject" name="reason_reject"></el-input>
        </el-form-item>
        <el-form-item label="Vui lòng nhập mật khẩu:" prop="password">
          <el-input type="password" v-model="form_password.password" name="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="el-button el-button--default el-button--small" @click="dialogFormVisible = false">Hủy</el-button>
        <el-button class="el-button el-button--default el-button--small" type="primary" @click="handleUpdate({ _status: status });">Xác Nhận</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
	import CooperatorRequest from "../api/cooperator";
	import CONST from "@/config/const";
  import OrganizationRequest from "@/packages/statistical/api/organization";

export default {
		name: "SearchPending",
		data() {
			return {
				loading: false,
        CONST: CONST,
				search: {
					number: "",
					from_submission_date: "",
					to_submission_date: "",
					organization_name: "",
					name: "",
					request_type: "",
					status: 0,
					pageSize: CONST.PAGINATION[0].value,
					response_time: "",
          school: "",
          placeholderSchool: "Tất cả",
				},
				data: [],
				sortParams: [],
				multipleSelection: [],
				listPaginate: CONST.PAGINATION,
				listRequestTypes: CONST.DEGREE_REQUEST_TYPES,
				checkAll: false,
        isIndeterminate: false,
        paramsSearch: {},
        mes: '',
        checkReject: false,
        status: 1,
        dialogFormVisible: false,
        form_password: {
          reason_reject: "",
          password: ""
        },
        rules: {
          password: [
            {
              required: true,
              message: "Vui lòng nhập mật khẩu.",
              trigger: "blur",
            },
          ],
          reason_reject: [
            {
              required: true,
              message: "Vui lòng nhập lý do từ chối.",
              trigger: "blur",
            },
          ]
        },
        showSchool: false,
        organizations: {},
        organizationsSearch: [],
        latestModel: ""
      };
    },
		methods: {
      closePopup() {
        this.dialogFormVisible = false
        this.form_password = {};
      },
      filterText() {
        this.search.school = '';
        let key = _.toLower(this.search.organization_name);
        this.organizations = _.filter(this.organizationsSearch, (item) => {
          return this.nonAccentVietnamese(_.toLower(item.name)).indexOf(this.nonAccentVietnamese(key)) !== -1;
        })
      },
			async handleSearch() {
				this.loading = true;
				try {
          this.paramsSearch = {...this.search}
					let res = await CooperatorRequest.get(this.search);
					this.data = res.data;
					this.search.response_time = res.response_time;
				} catch (error) {}
				this.loading = false;
				this.multipleSelection = [];
			},
			async handleConfirmApproved() {
				if (this.multipleSelection.length || this.checkAll) {
				  let rows = this.checkAll ? (this.data.total - this.multipleSelection.length) : this.multipleSelection.length
				  this.mes = 'Bạn có chắc chắn muốn chấp nhận ' + rows +  ' văn bằng không?';
          this.status = 1;
          this.dialogFormVisible = true;
				}
			},
			async handleConfirmReject(msg) {
				if (this.multipleSelection.length || this.checkAll) {
          let rows = this.checkAll ? (this.data.total - this.multipleSelection.length) : this.multipleSelection.length
          this.mes = 'Bạn có chắc chắn muốn từ chối ' + rows +  ' văn bằng không?';
          this.status = 2;
		  this.dialogFormVisible = true;
		  this.checkReject = true;
				}
			},
			async handleUpdate(_status) {
        let valid = await this.$refs['form_password'].validate();
        if (!valid) {
          return;
        }
				this.loading = true;
				try {
					let params ;
					if(this.checkReject){
						params= {
						password: this.form_password.password,
							...this.search,
							select_all: this.checkAll,
							ids: this.multipleSelection,
							..._status,
							reason_reject:this.form_password.reason_reject?this.form_password.reason_reject:'',
					};
					}else{
						params= {
						password: this.form_password.password,
							...this.search,
							select_all: this.checkAll,
							ids: this.multipleSelection,
							..._status,
					}
					}

					let res = await CooperatorRequest.patchMulti(
						params,
						this.$options.filters.formatNumber(
							this.checkAll
								? this.data.total - this.multipleSelection.length
								: this.multipleSelection.length
						)
					);
					this.$message({
						type: "success",
						message: "Cập nhật thành công.",
					});
          this.paramsSearch = {...this.search}
					res = await CooperatorRequest.get(this.search);
					this.data = res.data;
          this.checkAll = false;
          this.multipleSelection = [];
          this.dialogFormVisible = false
				} catch (error) {
					this.showError(error.response.data.message);
				}
				this.loading = false;
			},
			async handleExport() {
				if (!this.checkLimitExport(this.data.total)) {
					this.loading = true;
					try {
						let res = await CooperatorRequest.export(this.paramsSearch);
						window.location.assign(res);
					} catch (error) {}
					this.loading = false;
				}
			},
			async handleChangePage(page) {
				this.loading = true;
				let param = { ...this.paramsSearch, page: page };
				try {
					let res = await CooperatorRequest.get(param);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			handleSelect(selection, row) {
				if (this.multipleSelection.includes(row.id)) {
					this.multipleSelection.splice(
						this.multipleSelection.indexOf(row.id),
						1
					);
				} else {
					this.multipleSelection.push(row.id);
				}
			},
			async handleCommand(command) {
				this.search.pageSize = command;
				this.loading = true;
				try {
          this.paramsSearch = {...this.search}
					let res = await CooperatorRequest.get(this.search);
					this.data = res.data;
				} catch (e) {}
				this.loading = false;
			},
			handleCheckAll(val) {
				this.multipleSelection = [];
				if (!this.checkAll) {
					this.$refs.table.clearSelection();
				}
			},
      autoSelectFocusSchool() {
        this.$refs.popup.suggestions = [];
      },
      autoSelectBlurSchool() {
      },
      autoSelectChoiceSchool(item) {
      },
      autoSelectClearSchool() {
        this.placeholderSchool = "Tất cả";
      },
      async autoSelectSearchSchool(name, cb) {
        try {
          let res = await OrganizationRequest.getSchool({
            name: this.search.school,
            id: this.search.organization_id
          });
          let results = res.data.data.map((e) => {
            return { value: e.school };
          });
          cb(results);
        } catch (error) {}
      },

      selectItem(item) {
        this.latestModel = item.name;
        this.search.organization_name = item.name;
        this.search.organization_id = item.id;
        this.showSchool = false;
        if (item.degree_authority_unit_type === "2") {
          this.showSchool = true;
        }
      },
      setDataLatest() {
        this.search.organization_name = this.latestModel;
      },

      async getOrganization() {
        let res = await OrganizationRequest.getOrganization({
          name: this.search.organization_name,
        });
        this.organizations = res.data;
        this.organizationsSearch = res.data;
      },
		},
		async created() {
			this.loading = true;
			try {
        this.paramsSearch = {...this.search}
				let res = await CooperatorRequest.get(this.search);
				this.data = res.data;
				this.search.response_time = res.response_time;
			} catch (error) {}
			this.loading = false;
			await this.getOrganization();
		},
		updated() {
			this.data.data.forEach((row) => {
				if (this.multipleSelection.includes(row.id)) {
					this.$refs.table.toggleRowSelection(row, !this.checkAll);
				} else {
					this.$refs.table.toggleRowSelection(row, this.checkAll);
				}
			});
		},
		watch: {
    multipleSelection: function (newval, oldVal) {
      if (this.checkAll) {
        this.isIndeterminate =
          newval.length > 0 && newval.length < this.data.total;
      } else {
        this.isIndeterminate = false;
      }
    },
    dialogFormVisible:function (){
		this.$refs['form_password']?this.$refs['form_password'].resetFields():null;
		if(!this.dialogFormVisible){
		this.checkReject = false
		}
      },
    "search.organization_name": function () {
      this.filterText();
    }
		},
	};
</script>

<style>
	.moet-checkbox-all .el-checkbox__inner {
		top: 53px;
		left: 35px;
	}
</style>
<style lang="scss" scoped>
  .box {
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    background-color: #fff;
    max-height: 180px;
    margin: 5px 0;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    overflow: hidden;
    ::-webkit-scrollbar {
      width: 5px;
      height: 100px;
    }
    ::-webkit-scrollbar-track {
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      //border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      -webkit-box-shadow: inset 0 0 6px #C0C4CC;
    }
    ul {
      margin: 0;
    }
    .wrap {
      max-height: 280px;
      padding: 10px 0;
      box-sizing: border-box;
      overflow: scroll;
      li {
        padding: 0 20px;
        margin: 0;
        line-height: 34px;
        cursor: pointer;
        color: #606266;
        font-size: 14px;
        list-style-type: none;
      }
      .item {
        white-space: normal;
        text-overflow: unset;
        overflow: unset;
        height: auto;
        word-break: break-word;
        &:hover {
          background-color: #F5F7FA;
        }
      }
    }
  }
	.count_check {
		font-size: 14px;
		color: #2c2c2c;
	}
  .el-form {
    .search-number {
      margin-top: 10px;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
    .search-request_type {
      .el-form-item {
        margin-bottom: 0px;
      }
    }
  }

  /deep/ .el-card__body {
    padding: 15px 20px;
  }
  .custom_dialog {
    text-align: center;
    display: flex;
    align-items: center;
    /deep/.el-dialog{
      margin-top: unset;
      display: inline-block;
      width: 420px;
      padding-bottom: 10px;
      vertical-align: middle;
      background-color: #FFF;
      border-radius: 4px;
      border: 1px solid #EBEEF5;
      font-size: 18px;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      text-align: left;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    /deep/.el-dialog__header {
      position: relative;
      padding: 15px 15px 10px;

      .el-dialog__title {
        font-weight: bold;
      }
    }
    /deep/.el-dialog__body {
      padding: 0px 15px 10px;
      .el-form{
        border: 0;
        .el-form-item__label, .el-form-item__content{
          width: 100% !important;
          text-align: left !important;
          margin-left: 0 !important;
        }
      }
    }
    /deep/.el-dialog__footer {
      padding: 0px 15px 0;
    }
  }
</style>
