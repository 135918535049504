<template>
	<div>
		<!-- Form Search -->
		<el-card shadow="never" class="mt3">
			<el-form label-position="left" :model="search" style="border:none">
				<h3 class="mt0 title-search-account">Quản trị viên</h3>
				<el-row :gutter="20">
					<el-col :sm="24" :md="11">
						<el-form-item label="Tên đăng nhập">
							<el-input @keyup.enter.native="handleSearch()" v-model="search.username" maxlength="50"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24" :md="{span: 11, push: 2}">
						<el-form-item label="Họ và tên">
							<el-input @keyup.enter.native="handleSearch()" v-model="search.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :sm="24" :md="11">
						<el-form-item label="Tình trạng">
							<el-select v-model="search.status" clearable placeholder="Tất cả" style="width: 100%">
								<el-option label="Đang hoạt động" value="1"></el-option>
								<el-option label="Không hoạt động" value="0"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :sm="24" :md="{span: 11, push: 2}">
						<el-form-item label="Vai trò">
							<el-select v-model="search.roleId" clearable placeholder="Tất cả" style="width: 100%">
								<el-option
									v-for="role in masterdata.roles"
									:key="role.id"
									:label="role.name"
									:value="role.id"
								></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item style="text-align: right;" class="mb0">
					<el-button type="primary" class="btn" @click="handleSearch()">Tìm</el-button>
				</el-form-item>
			</el-form>
		</el-card>
		<!-- Button Action -->
		<el-row type="flex" class="mt3" justify="space-between" align="bottom">
			<div class="el-pagination-size">
				<span>Số bản ghi trên trang:&nbsp;</span>
				<el-dropdown @command="handleCommand">
					<span class="el-dropdown-link">
						{{ search.pageSize }}
						<i class="el-icon-caret-bottom"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item
							v-for="item in listPaginate"
							:key="item.key"
							:command="item.value"
						>{{ item.value }}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<el-button type="success" class="btn" @click="$router.push({name: 'AdminComponentCreate'})" :disabled="hasPermissionOnScreen(PERMISSION_CREATE)">Thêm Mới</el-button>
		</el-row>
		<el-card shadow="never" class="mt3" v-loading="loading">
			<el-table @selection-change="handleSelectionChange" :data="data.data" style="width: 100%">
				<el-table-column property="username" label="Tên đăng nhập"></el-table-column>
				<el-table-column property="name" label="Họ và tên"></el-table-column>
				<el-table-column label="Vai trò">
					<template slot-scope="scope">{{ scope.row.roles[0] ? scope.row.roles[0].name : '' }}</template>
				</el-table-column>
				<el-table-column label="Tình trạng">
					<template slot-scope="scope">
						<el-switch
							@change="handleChangeStatus(scope.$index, scope.row, $event)"
							v-model="scope.row.status"
							active-color="#67C23A"
							inactive-color="#C0C4CC"
							disabled
							v-if="(user && scope.row.id == user.id) || scope.row.id == SUPER_ADMIN_ID"
						></el-switch>
						<el-switch
							@change="handleChangeStatus(scope.$index, scope.row, $event)"
							v-model="scope.row.status"
							active-color="#67C23A"
							inactive-color="#C0C4CC"
							:disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
							v-else
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="Lần đăng nhập cuối" align="right">
					<template slot-scope="scope">
						{{ scope.row.last_login | formatDatetime}}
					</template>
				</el-table-column>
				<el-table-column align="right" label>
					<template slot-scope="scope">
						<el-button
							@click="$router.push({name: 'AdminComponentEdit', query: {id: scope.row.id}})"
							type="info"
							icon="el-icon-edit"
							size="mini"
							circle
							v-if="(user && scope.row.id == user.id) || scope.row.id == SUPER_ADMIN_ID"
							disabled
						></el-button>
						<el-button
							@click="$router.push({name: 'AdminComponentEdit', query: {id: scope.row.id}})"
							type="info"
							icon="el-icon-edit"
							size="mini"
							circle
							v-else
							:disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
						></el-button>
						<el-button
							@click="multipleSelection = [scope.row.id]; handleConfirm()"
							type="danger"
							icon="el-icon-delete"
							size="mini"
							circle
							v-if="(user && scope.row.id == user.id) || scope.row.id == SUPER_ADMIN_ID"
							disabled
						></el-button>
						<el-button
							@click="multipleSelection = [scope.row.id]; handleConfirm()"
							type="danger"
							icon="el-icon-delete"
							size="mini"
							circle
							v-else
							:disabled="hasPermissionOnScreen(PERMISSION_DELETE)"
						></el-button>
					</template>
				</el-table-column>
			</el-table>
      <custom-el-pagination
        :length="data.total"
        :paginateFrom="data.from"
        :paginateTo="data.to"
        :total="data.total"
        :pageSize="data.per_page"
        :page="data.current_page"
        @handleCurrentChange="handleChangePage"
      ></custom-el-pagination>
		</el-card>
	</div>
</template>

<script>
	import AccountAdminRequest from "../api/AccountAdminRequest";
	import { mapGetters } from "vuex";
	import CONST from "@/config/const";

	export default {
		name: "AccountAdmin",
		data() {
			return {
				loading: false,
				search: {
					username: "",
					name: "",
					status: "",
					roleId: "",
					pageSize: CONST.PAGINATION[0].value,
				},
        paramsSearch: {},
				data: [],
				sortParams: [],
				multipleSelection: [],
				listPaginate: CONST.PAGINATION,
				SUPER_ADMIN_ID: CONST.SUPER_ADMIN_ID,
			};
		},
		computed: {
			...mapGetters({
				masterdata: "getMasterData",
				user: "user",
			}),
		},
		methods: {
			async handleSearch() {
				this.loading = true;
				try {
          this.paramsSearch = {...this.search}
					let res = await AccountAdminRequest.search(this.search);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			async handleConfirm() {
				if (this.multipleSelection.length) {
					if (await this.showConfirmMixin()) {
						this.handleDelete();
					}
				}
			},
			async handleDelete() {
				this.loading = true;
				try {
					let res = await AccountAdminRequest.destroyMulti(
						this.multipleSelection
					);
					let param = {
						...this.paramsSearch,
						page:
							this.data.current_page < this.data.last_page
								? this.data.current_page
								: this.data.data.length != this.multipleSelection.length
								? this.data.last_page
								: this.data.last_page - 1,
					};
					res = await AccountAdminRequest.search(param);
					this.data = res.data;

					this.showSuccess('Xóa thành công.')
				} catch (error) {}
				this.loading = false;
				this.multipleSelection = [];
			},
			async handleChangeStatus(index, row, status) {
				try {
					let res = await AccountAdminRequest.patch(row.id, {
						status: row.status,
					});
					this.showSuccess('Thành công.');
				} catch (error) {
          this.showError("Thất bại.");
          row.status = !status;
        }
			},
			async handleChangePage(page) {
				this.loading = true;
				let param = { ...this.paramsSearch, page: page };
				try {
					let res = await AccountAdminRequest.search(param);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			handleSelectionChange(val) {
				let ids = val.map(function (i) {
					return i.id;
				});
				this.multipleSelection = ids;
			},
			async handleCommand(command) {
				this.search.pageSize = command;
        this.paramsSearch.pageSize = command;
				this.loading = true;
				try {
					let res = await AccountAdminRequest.search(this.paramsSearch);
					this.data = res.data;
				} catch (e) {}
				this.loading = false;
			},
		},
		async created() {
			this.loading = true;
			try {
        this.paramsSearch = {...this.search}
        let res = await AccountAdminRequest.search(this.search);
				this.data = res.data;
			} catch (error) {}
			this.loading = false;
		},
	};
</script>

<style scoped>
</style>
