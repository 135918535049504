import AuthenticationUtils from './AuthenticationStore';
import MasterdataUtils from "@/common/MasterdataUtils";
import CONST from '@/config/const'
import axios from 'axios';
import app from '@/main'
import _ from 'lodash';
import mixin from '@/utils/mixin';
import moment from "moment";

export default class BaseRequest {
  static getInstance() {
    return new this
  }

  getAxios() {
    window.axios = window.axios ?? axios
    let url = '';
    url += process.env.VUE_APP_ENABLE_ADMIN == "TRUE" ? 'admin' : '';
    url += ' - ' + (process.env.VUE_APP_ENABLE_CO_OP == "TRUE" ? 'coop' : '');
    window.axios.defaults.headers.common['url'] = url;
    window.axios.defaults.headers.common['local-timezone-offset'] = moment().utcOffset();
    if (AuthenticationUtils.isAuthenticated()) {
      const user = AuthenticationUtils.getUserId()
      window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + AuthenticationUtils.getAccessToken();
      window.axios.defaults.headers.common['auth-user-id'] = user.id;
      window.axios.defaults.headers.common['auth-user-username'] = user.username;
      window.axios.defaults.headers.common['auth-user-email'] = user.email;
      window.axios.defaults.headers.common['auth-user-organization-id'] = user.organization_id || '';
    }
    return window.axios
  }

  setHeader(key, val) {
    this.getAxios().defaults.headers.common[key] = val;
  }

  setUserAuth(data) {
    AuthenticationUtils.saveAuthenticationData(data)
  }

  buildUrl(url) {
    // remove both leading and trailing a slash
    url = url.replace(/^\/+|\/+$/g, '')
    return `${this.getUrlPrefix()}/${url}`
  }

  getUrlPrefix() {
    return CONST.API_URL + '/api';
  }

  async post(url, data = {}, headers = {}, otherConfigs = {}) {
    try {
      return this._responseHandler(await this.getAxios().post(this.buildUrl(url), data, {headers: headers, ...otherConfigs}));
    } catch (e) {
      this._errorHandler(e)
    }
  }

  async put(url, data = {}, headers = {}, otherConfigs = {}) {
    try {
      return this._responseHandler(await this.getAxios().put(this.buildUrl(url), data, {headers: headers, ...otherConfigs}));
    } catch (e) {
      this._errorHandler(e)
    }
  }

  async patch(url, data = {}, headers = {}, otherConfigs = {}) {
    try {
      return this._responseHandler(await this.getAxios().patch(this.buildUrl(url), data, { headers: headers, ...otherConfigs }));
    } catch (e) {
      this._errorHandler(e)
    }
  }

  async get(url, data = {}, headers = {}, otherConfigs = {}) {
    try {
      return this._responseHandler(await this.getAxios().get(this.buildUrl(url), {params: data, headers: headers, ...otherConfigs}));
    } catch (e) {
      this._errorHandler(e)
    }
  }

  async delete(url, data = {}, headers = {}, otherConfigs = {}) {
    try {
      return this._responseHandler(await this.getAxios().delete(this.buildUrl(url), {params: data, headers: headers, ...otherConfigs}));
    } catch (e) {
      this._errorHandler(e)
    }
  }

  getCurrentLocale() {
    if (window.i18n) {
      return window.i18n.locale;
    }
  }

  appendLocale(data) {
    const lang = this.getCurrentLocale();
    return Object.assign(data, {lang});
  }

  async _responseHandler(response) {
    const data = response.data;
    await this._checkMasterdataVersion(data);
    return data;
  }

  _errorHandler(err) {
    console.log(err, err.response)
    if (err.response && err.response.status === 401) { // Unauthorized (session timeout)
      if (err.response.data.error === "User blocked") {
        app.showError('Tài khoản của bạn đã bị tạm khoá.');
        window.is_permission_error = true;
        AuthenticationUtils.removeAuthenticationData();
        window.app.$router.push({name: 'login'});
        setTimeout(() => {
          window.is_permission_error = false;
        }, 0)
        return;
      }
      if (err.response.data.error === "token_expired") {
        app.showError('Phiên đăng nhập đã hết hạn.');
        window.is_permission_error = true;
        AuthenticationUtils.removeAuthenticationData();
        window.app.$router.push({name: 'login'});
        setTimeout(() => {
          window.is_permission_error = false;
        }, 0)
        return;
      }
      if (err.response.data === "Unauthorized.") {
        app.showError('Phiên đăng nhập đã hết hạn.');
        window.is_permission_error = true;
        AuthenticationUtils.removeAuthenticationData();
        window.app.$router.push({name: 'login'});
        setTimeout(() => {
          window.is_permission_error = false;
        }, 0)
        return;
      }
      AuthenticationUtils.removeAuthenticationData();
    }
    if (err.response && err.response.status === 501) { //Cooperate user have deactivated organization.
      let msgError = 'Đơn vị cấp bằng của bạn đã bị xóa.';
      if (err.response.data && err.response.data.error === 'organization_inactive') {
        msgError = 'Đơn vị cấp bằng của bạn đã bị tạm khóa.';
      }
      if (app.$router.currentRoute.name !== 'login') {
        AuthenticationUtils.removeAuthenticationData();
        app.showError(msgError);
        app.$router.push({'name': 'login'});
      }
      return;
    }
    if (err.response && err.response.status === 555) { //wrong permission cooperation
      this.fetchTheRightPermissionData();
      return;
    }
    if (err.response && (err.response.status === 550)) { // not granted permission
      const user = AuthenticationUtils.getUserId()
      if (user.type_user !== CONST.TYPE_ELEMENTARY_SCHOOL) {
        app.showError('Bạn không còn quyền thực hiện hành động này.');
      }
      window.is_permission_error = true;
      this.fetchTheRightPermissionData();
      setTimeout(() => {
        window.is_permission_error = false;
      }, 0)
    }
    throw err;
  }

  fetchTheRightPermissionData() {
    app.$store.dispatch('getUser');
    app.$store.dispatch('getPermissionsByUser').then(() => {
      this.redirectAfterCheckPermission();
    });
    app.$store.dispatch('getAdminMenu');
  }

  redirectAfterCheckPermission() {
    const screenName = app.$router.currentRoute.matched[1] ? app.$router.currentRoute.matched[1].name : app.$router.currentRoute.name;
    if (mixin.methods.hasPermissionOnScreen(mixin.data.PERMISSION_READ, app)) {
      const user = AuthenticationUtils.getUserId()
      if (user.type_user !== CONST.TYPE_ELEMENTARY_SCHOOL) {
        window.app.$router.push({'name': 'UserInfo'});
        return;
      }
      window.app.$router.push({'name': 'CoopUserInfo'});
      return;
    }
    window.app.$router.push({'name': screenName});

  }

  async _checkMasterdataVersion(data) {
    if (MasterdataUtils.isDataChanged(data.dataVersion)) {
      await MasterdataUtils.clearMasterdata();
      window.app.$store.dispatch('reloadMasterData');
    }
  }

  filterData(response) {
    return response.data
  }
}
