<template>
  <el-row>
    <el-col :span="24" class="group-search">
      <el-form label-width="140px" :model="params" label-position="left" ref="form">
        <p class="title-search">Danh sách văn bằng có lỗi từ tệp danh sách tải lên</p>
        <el-row>
          <el-col :span="12" class="degree-search_code">
            <el-form-item label="Ngày tải lên" prop="end_date">
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Ngày/tháng/năm"
                  format="dd/MM/yyyy"
                  style="width: 100%;"
                  v-model="params.start_date"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">~</el-col>
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Ngày/tháng/năm"
                  format="dd/MM/yyyy"
                  style="width: 100%;"
                  v-model="params.end_date"
                  value-format="yyyy/MM/dd"
                ></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="degree-btn-search">
            <el-form-item>
              <el-button type="primary" class="btn-search" @click="searchDegreeError">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <el-col :span="24" class="list-button">
      <div class="pagination-size">
        <p>Số bản ghi trên trang:</p>
        <el-dropdown @command="ChangePageSize">
          <span class="el-dropdown-link">
            {{ params.limit }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in listPaginate"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button
        type="danger"
        class="btn-delete"
        :disabled="loading || (getData.length === 0)"
        @click="handleConfirmDelete()"
      >Xóa</el-button>
      <el-button type="warning" class="btn-export" @click="exportDegreeError()">Kết Xuất</el-button>
    </el-col>

    <el-card>
      <el-col :span="24" class="degree-table">
        <template>
          <el-table ref="table" :data="getData" style="width: 100%" empty-text="Không có dữ liệu"  header-align="center" width="100%">
            <el-table-column label="Tên tệp tin" :min-width="25">
              <template scope="scope">{{ scope.row.file ? scope.row.file.file_name : '' }}</template>
            </el-table-column>
            <el-table-column  :min-width="30">
              <template slot-scope="scope" slot="header">Nội dung lỗi <span style="color: #F56C6C">(có thể có nhiều hơn 1 lỗi)</span></template>
              <template slot-scope="scope">Dòng {{scope.row.row_error }} lỗi trường {{ $t('degree_error.' + scope.row.error) | upperFirstCharacter }}</template>
            </el-table-column>
            <el-table-column label="Thời gian tải lên" :min-width="30">
              <template scope="scope">{{ scope.row.created_at | formatHour }}</template>
            </el-table-column>
            <el-table-column label="Ngày tải lên" :min-width="15">
              <template slot-scope="scope">{{ scope.row.created_at | formatDate }}</template>
            </el-table-column>
          </el-table>
          <custom-el-pagination
            :length="getData.length"
            :paginateFrom="paginateFrom"
            :paginateTo="paginateTo"
            :total="total"
            :pageSize="pageSize"
            :page="page"
            @handleCurrentChange="handleCurrentChange"
          ></custom-el-pagination>
        </template>
      </el-col>
    </el-card>
  </el-row>
</template>

<script>
import DegreeCooperatorRequest from "@/requests/DegreeCooperatorRequest";
import CONST from "@/config/const";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "DegreeSuccess",
  props: {
    msg: String,
  },
  data() {
    return {
      getData: [],
      params: {
        limit: CONST.PAGINATION[0].value,
      },
      multipleSelection: [],
      page: 1,
      pageSize: CONST.PAGINATION[0].value,
      total: 0,
      paginateFrom: 1,
      paginateTo: CONST.PAGINATION[0].value,
      listPaginate: CONST.PAGINATION,
      lastPage: 2,
      isIndeterminate: false,
      loading: false,
      numberChecked: 0,
      paramsSearch: {}
    };
  },
  computed: {
    ...mapGetters(["getMasterData"]),
  },
  methods: {
    async getList(params) {
      this.paramsSearch = {...params}
      const data = await DegreeCooperatorRequest.getDegreeError(params);
      this.getData = data.data;
      this.page = data.current_page;
      this.pageSize = parseInt(data.per_page);
      this.total = data.total;
      this.paginateFrom = data.from;
      this.paginateTo = data.to;
      this.lastPage = parseInt(data.last_page);
    },
    searchDegreeError() {
      this.params.page = 1;
      this.getList(this.params);
    },
    async handleConfirmDelete() {
      const params = { check_all: true };

      if (await this.showConfirmMixin()) {
        this.loading = true;
        DegreeCooperatorRequest.deleteDegreeError(params, this.total)
          .then(() => {
            this.showSuccess("Xóa thành công.");
            this.getList(this.params);
            this.loading = false;
          })
          .catch((err) => {
            this.showError(err.response.data.message);
            this.loading = false;
          });
      }
    },
    exportDegreeError() {
      DegreeCooperatorRequest.exportDegreeError(this.paramsSearch).then((res) => {
        this.downloadFile(res, "Van_bang_that_bai");
      });
    },
    setEndDate() {
      this.params.end_date = moment(String(this.params.end_date)).format("YYYY/MM/DD");
    },
    handleCurrentChange(val) {
      this.page = val;
      const param = Object.assign(this.params, {
        page: this.page,
      });
      this.getList(param);
    },
    ChangePageSize(e) {
      this.params.limit = e;
      this.params.page = 1;
      this.getList(this.params);
    },
  },
  created() {
    this.getList(this.params);
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
.degree-btn-search {
  .el-form-item__content {
    margin-left: 20px !important;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/_variables";
/*/deep/ .el-input__inner {*/
/*  padding-left: 15px;*/
/*}*/
.line {
  text-align: center;
}
</style>
