<template>
  <el-row>
    <el-col :span="24" class="group-search">
      <el-form label-position="left">
        <p class="title-search">Quản lý trình độ ngoại ngữ</p>
        <el-row>
          <el-col :span="11">
            <el-form-item label="Mã">
              <el-input maxlength='8' @keyup.enter.native="searchLanguageLevel()" placeholder="Mã" v-model="params.code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="Trình độ ngoại ngữ">
              <el-input @keyup.enter.native="searchLanguageLevel()" placeholder="Trình độ ngoại ngữ" v-model="params.level"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="16" class="button-search">
            <el-form-item>
              <el-button type="primary" class="btn" @click="searchLanguageLevel">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <el-col :span="8" :offset="16" class="language-level-list-btn">
      <div class="paginate-page">
        <p>Số bản ghi trên trang:</p>
        <el-dropdown @command="ChangePageSize">
          <span class="el-dropdown-link">
          {{ params.limit }}
          <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in listPaginate"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button type="success" class="btn" @click="$router.push({name: 'LanguageLevelCreate'})" :disabled="hasPermissionOnScreen(PERMISSION_CREATE)">
          <span>Thêm Mới</span>
      </el-button>
      <el-button type="danger" class="btn" disabled v-show="multipleSelection.length === 0"
                  @click="deleteMultiLanguageLevel()">Xóa</el-button>
      <el-button type="danger" class="btn" v-show="multipleSelection.length !== 0"
                   @click="deleteMultiLanguageLevel()" :disabled="hasPermissionOnScreen(PERMISSION_DELETE)">Xóa</el-button>
    </el-col>

    <el-card>
      <el-table
        :data="getData"
        style="width: 100%"
        empty-text="Không có dữ liệu"
        @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="code"
            label="Mã">
          </el-table-column>
          <el-table-column
            prop="level"
            label="Trình độ ngoại ngữ">
          </el-table-column>
          <el-table-column
            prop="status"
            label="Tình trạng">
              <template scope="scope">
                  <el-switch
                    @change="updateLanguageLevel(scope.row)"
                    :value="filterStatus(scope.row.status)"
                    class="language-level-item-status"
                    :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)">
                  </el-switch>
              </template>
          </el-table-column>
          <el-table-column
            label=""
            align="right">
              <template class="action-language-level" scope="scope">
                  <el-button type="info" icon="el-icon-edit" circle size="mini"
                              @click="$router.push({name: 'LanguageLevelEdit', params: { id: scope.row.id }})"
                             :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"></el-button>
                  <el-button type="danger" icon="el-icon-delete" circle size="mini"
                              @click="deleteLanguageLevel(scope.row)" :disabled="hasPermissionOnScreen(PERMISSION_DELETE)"></el-button>
              </template>
          </el-table-column>
      </el-table>
      <custom-el-pagination
      :length="total"
      :paginateFrom="paginateFrom"
      :paginateTo="paginateTo"
      :total="total"
      :pageSize="pageSize"
      :page="page"
      @handleCurrentChange="handleCurrentChange"
      ></custom-el-pagination>
    </el-card>
  </el-row>
</template>

<script>
    import LanguageLevelRequest from '@/requests/LanguageLevelRequest';
    import CONST from '@/config/const';

    export default {
        name: 'LanguageLevel',
        props: {
            msg: String
        },
        data() {
            return {
                getData: [],
                params: {
                    limit: CONST.PAGINATION[0].value
                },
                multipleSelection: [],
                page: 1,
                pageSize: 25,
                total: 0,
                paginateFrom: 1,
                paginateTo: 25,
                listPaginate: CONST.PAGINATION
            }
        },
        methods: {
            async getList(params) {
                const data = await LanguageLevelRequest.getListLanguageLevel(params);
                this.getData = data.data.data;
                this.page = data.data.current_page;
                this.pageSize = parseInt(data.data.per_page);
                this.total = data.data.total;
                this.paginateFrom = data.data.from;
                this.paginateTo = data.data.to;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            filterStatus(value) {
                return value !== 1 ? false : true;
            },
            searchLanguageLevel() {
                this.getList(this.params);
            },
            async updateLanguageLevel(data) {
                await LanguageLevelRequest.updateStatusLanguageLevel({id:data.id, status:data.status});
                this.showSuccess('Thành công.');
                this.getList(this.params);
            },
            handleCurrentChange(val) {
                this.page = val;
                const param = Object.assign(this.params, {
                    page: this.page
                });
                this.getList(param);
            },
            async deleteLanguageLevel(row) {
              if (await this.showConfirmMixin()) {
                LanguageLevelRequest.deleteLanguageLevel(row.id).then(() => {
                  const message = this.$t('Xoá thành công.');
                  this.showSuccess(message);
                  this.getList(this.params);
                }).catch(() => {
                  const message = 'Lỗi khi xóa trình độ ngoại ngữ.';
                  this.showError(message);
                  this.getList(this.params);
                });
              }
            },
            async deleteMultiLanguageLevel() {
              if (await this.showConfirmMixin()) {
                const listId = [];
                this.multipleSelection.map(function (value) {
                  listId.push(value.id);
                });

                LanguageLevelRequest.deleteMultiLanguageLevel({list: listId}).then(() => {
                  const message = this.$t('Xoá thành công.');
                  this.showSuccess(message);
                  this.getList(this.params);
                }).catch(() => {
                  const message = 'Lỗi khi xóa trình độ ngoại ngữ.';
                  this.showError(message);
                  this.getList(this.params);
                });
              }
            },
            ChangePageSize(e) {
                this.params.limit = e;
                const params = Object.assign(this.params, {
                  page: 1
                });
                this.getList(params);
            }
        },
        created() {
            this.getList(this.params);
        }
    }
</script>
<style lang="scss">
    @import "@/assets/sass/_variables";
    .language-level-pagination {
        ul.el-pager {
            list-style-type: none;
            padding: 0;
            li {
                display: inline-block;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                color: #606266;
                padding: 0px 15px;
            }
            li.active {
                color: #1989FA;
            }
        }
    }
    .language-level-search {
        .el-form {
            padding: 15px;
        }
    }
    .language-level-search_button {
        display: flex;
        float: right;
        width: auto;
        .el-form-item__content {
            margin-left: 0px !important;
            float: right;
            width: 100%;
        }
    }
    .language-level-search_id {
        label {
            width: 90px !important;
        }
        .el-form-item__content {
            margin-left: 90px !important;
        }
    }
    .language-level-search_name .el-form-item__content,
    .language-level-search_id .el-form-item__content,
    .language-level-search_name_en .el-form-item__content,
    .language-level-status .el-form-item__content{
        float: none;
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/_variables";
    .paginate-page {
        position: absolute;
        left: 0px;
        width: 220px;
        display: inline-flex;
        .el-dropdown {
            height: 20px;
            border: revert;
            border: 1px solid var(--select-border);
            background: #F7F8FC;
            color: #4B506D;
            margin-top: 17px;
            width: 45px;
        }
        p {
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #9FA2B4;
            width: 150px;
        }
    }
    .language-level-pagination {
        position: relative;
        .title-paginate {
            position: absolute;
            top: 25px;
            left: 35px;
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #9FA2B4;
        }
    }
    h3 {
        margin: 40px 0 0;
    }
    a {
        color: #42b983;
    }
    .link-back {
        background: #FFF;
        .link-back-list {
            display: table-cell;
            vertical-align: middle;
            height: 37px;
            a {
                font-family: Helvetica;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #303133;
                padding: 0px 20px;
                height: 37px;
            }
            a.active {
                border-bottom: 2px solid #409EFF;
                padding-bottom: 6px;
            }
        }
    }
    .language-level-search {
        margin-top: 20px;
        .title-search {
            font-family: Helvetica;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #2C2C2C;
            margin-bottom: 24px;
        }
    }
    .btn-search {
        background: #409EFF;
        border-radius: 4px;
        border-color: #409EFF;
        width: 95px;
        height: 40px;
    }
    .language-level-list-btn {
        justify-content: flex-end;
        display: flex;
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .btn-add {
        background: #67C23A;
        border-radius: 4px;
        border-color: #67C23A;
        font-weight: bold;
    }
    .btn-delete {
        background: #F56C6C;
        border-radius: 4px;
        border-color: #F56C6C;
        font-weight: bold;
    }
    .btn-delete:disabled {
    background: $color_disable_btn;
    border-color: $color_disable_btn;
    }
    .btn-import {
        background: #409EFF;
        border-radius: 4px;
        border-color: #409EFF;
        font-weight: bold;
    }
    .upload-btn-wrapper {
        position: relative;
        display: inline-block;
        input[type=file] {
            width: 95px;
            height: 40px;
            margin-left:12px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }
        .btn-import {
            font-family: Helvetica;
            font-style: normal;
            font-size: 14px;
            line-height: 16px;
            color: #FFF;
            letter-spacing: 0.01em;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 95px;
            height: 40px;
            margin: 0px 12px;
        }
    }
    .btn-export {
        font-weight: bold;
        background: #E6A23C;
        border-radius: 4px;
        border-color: #E6A23C;
    }
    .language-level-pagination {
        .el-pagination {
            padding: 20px 0px 10px 0px;
        }
    }
</style>
