import ListNational from "@/pages/Admin/nationals/List";
import CreateNational from "@/pages/Admin/nationals/Create";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteNationalManagement = [
  {
    path: '/admin/national-management',
    component: WrapPage,
    name: 'NationalManager',
    children: [
      {
        path: 'list',
        component: ListNational,
        name: 'NationalManager',
      },
      {
        path: 'create',
        component: CreateNational,
        name: 'nation.create',
      },
      {
        path: 'edit/:id',
        component: CreateNational,
        name: 'nation.edit',
      },
    ]
  },
];

export default RouteNationalManagement;