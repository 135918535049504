import RoleManagementEdit from "@/pages/Admin/RoleManagementEdit";
import RoleManagement from "@/pages/Admin/RoleManagement";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteRoleManagement = [
  {
    path: '/admin/role-management',
    component: WrapPage,
    name: 'RoleManagement',
    children: [
      {
        path: '',
        name: 'RoleManagement',
        component: RoleManagement,
      }, {
        path: 'create',
        name: 'RoleManagementCreate',
        component: RoleManagementEdit,
      }, {
        path: ':id/edit',
        name: 'RoleManagementEdit',
        component: RoleManagementEdit,
      },
    ],
  },
];

export default RouteRoleManagement;