import ListDoet from "@/pages/Admin/Doet/List";
import CreateDoet from "@/pages/Admin/Doet/Create";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteDoetManagement = [
  {
    path: '/admin/doet-management',
    component: WrapPage,
    name: 'DoetManager',
    children: [
      {
        path: 'list',
        component: ListDoet,
        name: 'DoetManager',
      },
      {
        path: 'create',
        component: CreateDoet,
        name: 'doet.create',
      },
      {
        path: 'edit/:id',
        component: CreateDoet,
        name: 'doet.edit',
      },
    ]
  },
];

export default RouteDoetManagement;