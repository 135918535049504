import Request from '@/requests/Request';

class DegreeRequest extends Request {
    prefix = '/admin/certificates/';

    async get(data) {
        let url = this.prefix;
        return await super.get(url, data);
    }

    async show(id) {
        let url = this.prefix + id;
        return await super.get(url);
    }

    async post(data) {
        let url = this.prefix;
        return await super.post(url, data, {
            'api-action': 'DegreeRequest-create',
            'api-action-meta': data.code
        });
    }

    async put(id, data) {
        let url = this.prefix + id;
        return await super.put(url, data, {
            'api-action': 'DegreeRequest-edit',
            'api-action-meta': data.code
        });
    }

    async patch(id, data) {
        let url = this.prefix + id;
        return await super.patch(url, data);
    }


    async delete(id, code) {
        let url = this.prefix + id;
        return await super.delete(url, {}, {
            'api-action': 'DegreeRequest-delete',
            'api-action-meta': code
        });
    }

    async destroy(data, code) {
        let url = this.prefix;
        let meta = '';
        let nameDegree = '';
        if (nameDegree === undefined) {
            meta = data.length;
            nameDegree = 'DegreeRequest-deleteAll';
        } else {
            meta = code;
            nameDegree = 'DegreeRequest-delete';
        }
        return await super.delete(url, data, {
            'api-action': nameDegree,
            'api-action-meta': meta
        });
    }

    async checkExitCode(data) {
        let url = this.prefix + 'check-exit/code';
        return await super.get(url, data);
    }

    async checkExitName(data) {
        let url = this.prefix + 'check-exit/name';
        return await super.get(url, data);
    }
}

export default DegreeRequest.getInstance()
