<template>
    <div>
        <template>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'LanguageLevel' }">Trình độ ngoại ngữ</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.params.id !== undefined ? 'Chỉnh sửa' : 'Thêm mới'}}</el-breadcrumb-item>
            </el-breadcrumb>
        </template>
        <el-row>
            <el-form class="create-edit-form" ref="form" :rules="rules" :model="form"  label-position="left">
                <p class="title-form">{{$route.params.id !== undefined ? 'Chỉnh sửa' : 'Thêm mới'}} trình độ ngoại ngữ</p>
                <el-row>
                    <el-col :span="14" class="language-level-code">
                        <el-form-item label="Mã" prop="code">
                            <el-input maxlength='8' placeholder="Mã" v-model="form.code"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="14" class="language-level-search_name">
                        <el-form-item label="Trình độ ngoại ngữ" prop="level">
                            <el-input placeholder="Trình độ ngoại ngữ" v-model="form.level"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
                <el-row>
                    <el-col :span="10" class="organization-status">
                        <el-form-item label="Tình trạng">
                            <el-switch v-model="form.status">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item class="btn-group-form">
                    <el-button class="btn" type="danger" v-if="$route.params.id !== undefined"
                               @click="deleteLanguageLevel(form)" :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)">Xóa</el-button>
                    <el-button class="btn" type="primary" plain @click="resetForm()" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)" v-if="$route.params.id !== undefined">Đặt Lại</el-button>
                    <el-button class="btn" type="primary" @click="submitForm()" v-if="$route.params.id !== undefined" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Lưu</el-button>
                    <el-button class="btn" type="primary" @click="submitForm()" v-else :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">Lưu</el-button>
                </el-form-item>
            </el-form>
        </el-row>
    </div>
</template>

<script>
    import LanguageLevelRequest from '@/requests/LanguageLevelRequest';

    export default {
        name: 'LanguageLevelCreateEdit',
        props: {
            msg: String
        },
        data() {
            let checkCode = (rule, value, callback) => {
                LanguageLevelRequest.checkCode({code: value, id: this.form.id}).then((res) => {
                    if (res.data.length === 1) {
                        callback(new Error('Mã đã tồn tại'));
                    } else {
                        callback();
                    }
                });
            };
            return {
                form: {
                    code: '',
                    level: '',
                    id:null,
                    status: true
                },
                temp: {},
                rules: {
                    code: [
                        { required: true, message: 'Vui lòng nhập mã.', trigger: 'blur' },
                        { max: 8, message: 'Mã nhỏ hơn 8 kí tự', trigger: 'blur' },
                        { pattern:/^[a-zA-Z0-9]*$/, message: 'Mã không đúng định dạng', trigger: 'blur' },
                        { validator: checkCode, trigger: 'blur'}
                    ],
                    level: [
                        { required: true, message: 'Vui lòng nhập trình độ ngoại ngữ', trigger: 'blur' },
                        { max: 250, message: 'Trình độ ngoại ngữ nhỏ hơn 250 kí tự', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            submitForm() {
                this.$refs['form'].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    if (this.$route.params.id !== undefined) {
                        this.updateRanking();
                        return;
                    }
                    this.createRanking();
                });

            },
            async deleteLanguageLevel(row) {
              let checkConfirm = await this.showConfirmMixin(`Bạn có chắc chắn muốn xóa không?`);
              if (checkConfirm) {
                  LanguageLevelRequest.deleteLanguageLevel(row.id).then(() => {
                  this.showSuccess('Xóa thành công.')
                  this.$router.push({name: 'LanguageLevel'});
                }).catch(() => {
                  this.showError('Lỗi khi xóa.');
                });
              }
            },
            createRanking() {
                LanguageLevelRequest.createLanguageLevel(this.form).then(() => {
                    this.showSuccess('Thêm trình độ ngoại ngữ thành công');
                    this.$router.push({name: 'LanguageLevel'});
                }).catch(() => {
                    this.showError('Có lỗi khi thêm trình độ ngoại ngữ');
                });
            },
            updateRanking() {
                LanguageLevelRequest.updateLanguageLevel(this.form, this.$route.params.id).then(() => {
                    this.showSuccess('Cập nhật trình độ ngoại ngữ thành công');
                    this.$router.push({name: 'LanguageLevel'});
                }).catch(() => {
                    this.showError('Có lỗi khi sửa trình độ ngoại ngữ');
                });
            },
            resetForm() {
                this.form = _.clone(this.temp);
            }
        },
        created() {
            if (this.$route.params.id !== undefined) {
                LanguageLevelRequest.showLanguageLevel(this.$route.params.id).then((res) => {
                    this.form.code = res.data.code;
                    this.form.id = res.data.id;
                    this.form.level = res.data.level;
                    this.form.status = res.data.status == 1 ? true : false;
                    this.temp = _.clone(this.form)
                });
            }

        }
    }
</script>
<style lang="scss">
    @import "@/assets/sass/_variables";
    .breadcrumb-language-level {
        .el-breadcrumb__item {
            .el-breadcrumb__inner.is-link, .el-breadcrumb__inner {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #2C2C2C;
            }
        }
    }
    .certificate-list {
        .el-form-item__content {
            margin-left: 0px !important;
        }
    }
    .language-level-status {
        .el-form-item__label {
            width: 140px!important;
        }
        .el-form-item__content {
            margin-left: 140px!important;
        }
    }
    .btn-language-level-action {
        .el-form-item__content {
            margin-left: 0px!important;
        }
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .language-level-form {
        padding: 20px;
    }
    .title-form {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #2C2C2C;
        margin-bottom: 35px;
    }
    .certificate-title, .language-level-status-title{
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #909399;
        margin-bottom: 24px;
    }
    .certificate-list {
        .el-checkbox {
            display: block;
        }
    }
    .el-form-item__label {
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #2C2C2C;
    }
    .btn-language-level-action {
        margin-top: 16px;
    }
</style>
