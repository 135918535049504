import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enMessage from '@/locales/en.json'
import deMessage from '@/locales/de.json'
import esArMessage from '@/locales/es-AR.json'
import esUsMessage from '@/locales/es-US.json'
import itItMessage from '@/locales/it-IT.json'
import roMessage from '@/locales/ro.json'
import ruMessage from '@/locales/ru.json'
import viMessage from '@/locales/vi.json'
import zhCnMessage from '@/locales/zh-CN.json'
import zhTwMessage from '@/locales/zh-TW.json'


Vue.use(VueI18n)

const messages = {
  en: enMessage,
  de: deMessage,
  esAR: esArMessage,
  esUS: esUsMessage,
  itIT: itItMessage,
  ro: roMessage,
  ru: ruMessage,
  vi: viMessage,
  zhCN: zhCnMessage,
  zhTW: zhTwMessage,
}

const i18n = new VueI18n({
  locale: 'vi', // set locale
  messages,
  fallbackLocale: 'vn',
})

export default i18n