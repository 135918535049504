<template>
  <div>
    <el-card shadow="never" class="mt3">
      <el-form label-position="left" label-width="120px" :model="search" style="border:none">
        <h4 class="mt0 mb1">Thống kê yêu cầu truy xuất</h4>
        <h5 class="mt0 mb1 mt3">Tổng số lượt tìm kiếm văn bằng: {{totalSearch}}</h5>
        <el-row :gutter="20" class="mt4">
          <el-col :sm="24" :md="11">
            <el-form-item label="Chọn đơn vị cấp bằng" label-width="160px">
              <el-autocomplete
                :placeholder="search.placeholder"
                style="width: 100%"
                v-model="search.organization_name"
                :fetch-suggestions="autoSelectSearch"
                :trigger-on-focus="true"
                @select="autoSelectChoice"
                @focus="autoSelectFocus"
                @blur="autoSelectBlur"
                @clear="autoSelectClear"
                clearable
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="{span: 11, push: 2}">
            <el-form-item label="Theo thời gian">
              <el-select v-model="search.time" placeholder="Tất cả" style="width: 100%">
                <el-option label="Theo ngày" value="date"></el-option>
                <el-option label="Theo tuần" value="week"></el-option>
                <el-option label="Theo tháng" value="month"></el-option>
                <el-option label="Theo năm" value="year"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24">
            <el-row type="flex" align="middle" justify="end">
              <el-button type="primary" @click="handleSearch()">Cập Nhật</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div id="chart" style="width:100%; height:400px;" class="mt3"></div>
      <h4 class="mt5 mb1">Dữ liệu gốc</h4>
      <el-row type="flex" align="bottom" justify="space-between">
        <div class="el-pagination-size">
          <span>Số bản ghi trên trang:&nbsp;</span>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ searchTable.pageSize }}
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in listPaginate"
                :key="item.key"
                :command="item.value"
              >{{ item.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-button type="warning" @click="handleExport()">Kết Xuất</el-button>
      </el-row>
      <el-row :gutter="40" style="border-top: 1px solid #eee" class="mt2"></el-row>
      <el-table :data="dataTable.data" style="width: 100%" v-loading="loading">
        <el-table-column prop="name" label="Đơn vị cấp bằng"></el-table-column>
        <el-table-column label="7 ngày qua" align="right">
          <template slot-scope="scope">{{ scope.row.accu_one_week_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column label="1 tháng qua" align="right">
          <template slot-scope="scope">{{ scope.row.accu_one_month_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column label="Trong năm nay" align="right">
          <template slot-scope="scope">{{ scope.row.current_year_view_tracker_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column label="Trong năm trước" align="right">
          <template slot-scope="scope">{{ scope.row.sub_one_year_view_tracker_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column label="Tổng" align="right">
          <template slot-scope="scope">{{ scope.row.all_time_count | formatNumber }}</template>
        </el-table-column>
      </el-table>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="mt3"
        v-show="dataTable.total"
      >
        <div class="el-pagination-info">
          <span>Hiển thị {{ dataTable.from }}-{{ dataTable.to }} của {{ dataTable.total }} bản ghi</span>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="dataTable.total"
          :current-page="dataTable.current_page"
          :page-size="dataTable.per_page"
          @current-change="handleChangePage"
        ></el-pagination>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import StatisticalRequest from "../api/statistical";
import OrganizationRequest from "../api/organization";
import Highcharts from "highcharts";
import CONST from "@/config/const";
var chart;
export default {
  name: "StatisticExtractRequest",
  data() {
    return {
      loading: false,
      search: {
        organization_id: "",
        organization_name: "",
        time: "date",
        placeholder: "Tất cả",
      },
      dataColumnChart: [],
      dataTable: [],
      totalSearch: 0,
      listPaginate: CONST.PAGINATION,
      searchTable: {
        pageSize: CONST.PAGINATION[0].value,
        page: 1,
      },
    };
  },
  methods: {
    async handleSearch() {
      try {
        await this.handleDataChart();
      } catch (error) {}
    },
    async handleChangePage(page) {
      this.loading = true;
      try {
        this.searchTable.page = page;
        await this.handleDataTable();
      } catch (error) {}
      this.loading = false;
    },
    async autoSelectSearch(name, cb) {
      try {
        let res = await OrganizationRequest.index({
          name: this.search.organization_name,
        });
        let results = res.data.map((e) => {
          return { value: e.name, id: e.id };
        });
        cb(results);
      } catch (error) {}
    },
    autoSelectFocus() {
      this.search.organization_name = "";
    },
    autoSelectBlur() {
      if (this.search.organization_id) {
        this.search.organization_name = this.search.placeholder;
      }
    },
    autoSelectChoice(item) {
      this.search.organization_id = item.id;
      this.search.placeholder = item.value;
      this.search.organization_name = item.value;
    },
    autoSelectClear() {
      this.search.organization_id = "";
      this.search.placeholder = "Tất cả";
    },
    async handleDataTable() {
      let res = await StatisticalRequest.viewTrackerIndex(this.searchTable);
      this.dataTable = res.data;
    },
    async handleDataChart() {
      let res = await StatisticalRequest.viewTrackerChart(this.search);
      this.dataColumnChart = res.data;
      this.renderChart();
    },

    async getTotalSearch() {
      let res = await StatisticalRequest.getTotalSearch();
      this.totalSearch = res.data;
    },
    renderChart() {
      chart.series[0].setData(this.dataColumnChart.map((item) => item.count));
      chart.xAxis[0].setCategories(
        this.dataColumnChart.map((item) => item.name)
      );
    },
    async handleCommand(command) {
      this.loading = true;
      try {
        this.searchTable.page = 1;
        this.searchTable.pageSize = command;
        await this.handleDataTable();
      } catch (e) {}
      this.loading = false;
    },
    async handleExport() {
      if (!this.checkLimitExport(this.dataTable.total)) {
        this.loading = true;
        try {
          let res = await StatisticalRequest.viewTrackerExport();
          window.location.assign(res);
        } catch (error) {}
        this.loading = false;
      }
    },
  },
  async created() {
    this.loading = true;
    try {
      await Promise.all([this.handleDataTable(), this.handleDataChart(), this.getTotalSearch()]);
    } catch (error) {}
    this.loading = false;
  },
  mounted() {
    chart = new Highcharts.Chart({
      chart: {
        renderTo: "chart",
        type: "column",
        spacingLeft: 0,
        spacingRight: 0,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
        labels: {
          style: {
            color: "#8D9AA9",
          },
        },
      },
      yAxis: {
        floor: 0,
        minRange: 10,
        title: {
          enabled: false,
        },
        gridLineDashStyle: "Dash",
        gridLineColor: "#BBBBBB",
        labels: {
          style: {
            color: "#8D9AA9",
          },
        },
      },
      series: [
        {
          name: "Văn bằng đã chấp nhận",
          data: [],
          dataLabels: {
            enabled: true,
            y: 30,
            style: {
              color: "#FFFFFF",
              textOutline: "none",
              fontWeight: "normal",
            },
            formatter: function () {
              return Highcharts.numberFormat(this.y, 0, ".", ",");
            },
          },
          color: "#57D1BB",
          showInLegend: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
    });
  },
};
</script>
