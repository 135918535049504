import Vue from 'vue'
import CustomElementInput from '@/pages/common/CustomElementInput';
import CustomElementPagination from '@/pages/common/CustomElementPagination';
import CustomElementSwitch from '@/pages/common/CustomElementSwitch';
import CustomElementForm from '@/pages/common/CustomElementForm';
import DegreeDetailCommon from '@/pages/common/DegreeDetailCommon';
import VueBarcode from 'vue-barcode';
import VueQrcode from 'vue-qrcode';

Vue.component('el-input', CustomElementInput);
Vue.component('el-form', CustomElementForm);
Vue.component('el-switch', CustomElementSwitch);
Vue.component('custom-el-pagination', CustomElementPagination);
Vue.component('barcode', VueBarcode);
Vue.component('qrcode', VueQrcode);
Vue.component('degree-detail', DegreeDetailCommon);
