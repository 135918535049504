<template>
  <div>
    <template>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'Language' }">Ngoại ngữ</el-breadcrumb-item>
        <el-breadcrumb-item>{{ languageId ? 'Chỉnh sửa' : 'Thêm mới' }}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-row>
      <el-form class="create-edit-form" ref="form" :rules="rules" :model="form"  label-position="left">
        <p class="title-form">{{ languageId ? 'Chỉnh sửa' : 'Thêm mới' }} ngoại ngữ</p>
        <el-row>
          <el-col :span="14" class="language-search_id">
            <el-form-item label="Mã" prop="code">
                <el-input maxlength='8' placeholder="Mã" v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14" class="language-search_name">
            <el-form-item label="Ngoại ngữ" prop="name">
              <el-input placeholder="Ngoại ngữ" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
        <el-row>
          <el-col :span="10" class="organization-status">
            <el-form-item label="Tình trạng">
              <el-switch v-model="form.status">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="btn-group-form">
          <el-button class="btn" type="danger" v-if="this.languageId"
                     @click="deleteLanguage(form)" :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)">Xóa</el-button>
          <el-button class="btn" type="primary" plain @click="resetForm()" v-if="this.languageId" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Đặt Lại</el-button>
          <el-button class="btn" type="primary" @click="submitForm()" v-if="languageId" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Lưu</el-button>
          <el-button class="btn" type="primary" @click="submitForm()" v-else :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">Lưu</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import LanguageRequest from '@/requests/LanguageRequest';

export default {
  name: 'LanguageCreateUpdate',
  props: {
    msg: String
  },
  data() {
    let checkCode = (rule, value, callback) => {
      LanguageRequest.checkCode({code: value, id: this.form.id}).then((res) => {
        if (res.data.length === 1) {
            callback(new Error('Mã đã tồn tại'));
        } else {
            callback();
        }
      });
    };
    return {
      form: {
        code: '',
        name: '',
        id:null,
        status: true,
      },
      rules: {
        code: [
            { required: true, message: 'Vui lòng nhập mã.', trigger: 'blur' },
            { max: 8, message: 'Mã nhỏ hơn 8 kí tự', trigger: 'blur' },
            { pattern:/^[a-zA-Z0-9]*$/, message: 'Mã không đúng định dạng', trigger: 'blur' },
            { validator: checkCode, trigger: 'blur'}
        ],
        name: [
            { required: true, message: 'Vui lòng nhập ngoại ngữ', trigger: 'blur' },
            { max: 250, message: 'Ngoại ngữ nhỏ hơn 250 kí tự', trigger: 'blur' }
        ]
      },
      languageId: null,
      originalLanguage: {},
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
            return;
        }
        if (this.languageId) {
          this.updateLanguage();
          return;
        }
        this.createLanguage();
      });
    },
    async deleteLanguage(row) {
      let checkConfirm = await this.showConfirmMixin(`Bạn có chắc chắn muốn xóa không?`);
      if (checkConfirm) {
          LanguageRequest.deleteLanguage(row.id).then(() => {
          this.showSuccess('Xóa thành công.')
          this.$router.push({name: 'Language'});
        }).catch(() => {
          this.showError('Lỗi khi xóa.');
        });
      }
    },
    createLanguage() {
      LanguageRequest.createLanguage(this.form).then(() => {
        const messsage = "Thêm mới ngoại ngữ thành công";
        this.showSuccess(messsage);
        this.$router.push({name: 'Language'});
      }).catch(() => {
        const error = "Có lỗi khi thêm ngoại ngữ";
        this.showError(error);
      });
    },
    updateLanguage() {
      LanguageRequest.updateLanguage(this.form, this.languageId).then(() => {
        this.$router.push({name: 'Language'});
      }).catch(() => {
        const error = "Có lỗi khi sửa ngoại ngữ";
        this.showError(error);
      });
    },
    resetForm() {
      this.$refs['form'].resetFields();
      if (this.languageId) {
        this.form.name = this.originalLanguage.name
        this.form.code = this.originalLanguage.code
        this.form.status = this.originalLanguage.status
        return;
      }
    }
  },
  created() {
    this.languageId = this.$route.params.id;
    if (this.languageId) {
      LanguageRequest.showLanguage(this.languageId).then((res) => {
        this.form.code = res.data.code;
        this.form.id = res.data.id;
        this.form.name = res.data.name;
        this.form.status = !!res.data.status;
        this.originalLanguage = _.clone(this.form)
      });
    }
  }
}
</script>
<style lang="scss">
  @import "@/assets/sass/_variables";
  .status-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #909399;
    margin-bottom: 24px;
  }
  .breadcrumb-language {
    .el-breadcrumb__item {
      .el-breadcrumb__inner.is-link, .el-breadcrumb__inner {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #2C2C2C;
      }
    }
  }
  .certificate-list {
    .el-form-item__content {
      margin-left: 0px !important;
    }
  }
  .language-status {
    .el-form-item__label {
      width: 140px!important;
    }
    .el-form-item__content {
      margin-left: 140px!important;
    }
  }
  .btn-language-action {
    .el-form-item__content {
      margin-left: 0px!important;
    }
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .language-form {
    padding: 20px;
  }
  .title-form {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #2C2C2C;
    margin-bottom: 35px;
  }
  .certificate-title, .language-status-title{
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #2C2C2C;
  }
  .certificate-list {
    .el-checkbox {
      display: block;
    }
  }
  .el-form-item__label {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #2C2C2C;
  }
  .btn-language-action {
    margin-top: 16px;
  }
</style>
