import Request from './Request';

class EthnicityManagementRequest extends Request {
    async getListEthnicity(params) {
        let url = '/admin/ethnicity/list';
        return await this.get(url, params);
    }
    async createEthnicity(params) {
        let url = '/admin/ethnicity/create';
        return await this.post(url, params, {
            'api-action': 'EthnicityManagementRequest-create',
            'api-action-meta': params.code
        });
    }

    async checkField(params) {
        let url = '/admin/ethnicity/check-field';
        return await this.post(url, params);
    }

    async deleteMultiEthnicity(params) {
        let url = '/admin/ethnicity/delete';
        return await this.post(url, params, {
            'api-action': 'EthnicityManagementRequest-deleteAll',
            'api-action-meta': params.list.length
        });
    }

    async deleteEthnicity(id, code) {
        let url = '/admin/ethnicity/'+ id;
        return await this.delete(url, {}, {
            'api-action': 'EthnicityManagementRequest-delete',
            'api-action-meta': code
        });
    }

    async updateStatusEthnicity(params) {
        let url = '/admin/ethnicity/update-status';
        return await this.post(url, params);
    }

    async updateEthnicity(params, id) {
        let url = '/admin/ethnicity/'+id;
        return await this.put(url, params, {
            'api-action': 'EthnicityManagementRequest-edit',
            'api-action-meta': params.code
        });
    }

    async showEthnicity(id) {
        let url = '/admin/ethnicity/'+id;
        return await this.get(url);
    }
}
export default EthnicityManagementRequest.getInstance()
