<template>
  <div>
    <el-row>
      <el-col :span="24" class="group-search">
        <el-form label-position="left">
          <p class="title-search">Quản lý dân tộc</p>
          <el-row>
            <el-col :span="11" class="ethnicity-search_id">
              <el-form-item label="Mã">
                <el-input maxlength='8' @keyup.enter.native="searchEthnicity()" v-model="params.code"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
              <el-form-item label="Dân tộc">
                <el-input @keyup.enter.native="searchEthnicity()" v-model="params.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="16" class="button-search">
              <el-form-item>
                <el-button type="primary" class="btn" @click="searchEthnicity">{{$t("btnSearch")}}</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <el-col :span="8" :offset="16" class="ethnicity-list-btn">
        <div class="paginate-page">
          <p>Số bản ghi trên trang:</p>
          <el-dropdown @command="ChangePageSize">
                      <span class="el-dropdown-link">
                      {{ paramsSearch.limit }}
                      <i class="el-icon-caret-bottom"></i>
                      </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  v-for="item in listPaginate"
                  :key="item.key"
                  :command="item.value"
              >{{ item.value }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-button type="success" class="btn"
                  @click="$router.push({name: 'EthnicityManagementCreate'})"
                  :disabled="hasPermissionOnScreen(PERMISSION_CREATE)">{{ $t('btnCreate')}}
        </el-button>
        <el-button  type="danger" class="btn" disabled v-show="multipleSelection.length === 0"
                    @click="deleteMultiEthnicity()">{{$t("btnDelete")}}</el-button>
        <el-button  type="danger" class="btn" disabled v-show="multipleSelection.length !== 0"
                      @click="deleteMultiEthnicity()" :disabled="hasPermissionOnScreen(PERMISSION_DELETE)">{{$t("btnDelete")}}
        </el-button>
      </el-col>
    </el-row>

    <el-card shadow="never">
      <el-table
          :data="getData"
          :header-cell-class-name="removeSelectionHeader"
          style="width: 100%"
          empty-text="Không có dữ liệu"
          @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            prop="code"
            label="Mã">
        </el-table-column>
        <el-table-column
            prop="name"
            label="Dân tộc">
        </el-table-column>
        <el-table-column
            prop="deleted_at"
            label="Tình trạng">
          <template scope="scope">
            <el-switch
                @change="updateStatusEthnicity(scope.row)"
                :value="filterStatus(scope.row.status)"
                class="ethnicity-item-status"
                :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
            label=""
            align="right">
          <template class="action-organization" scope="scope">
            <el-button type="info" icon="el-icon-edit" circle size="mini"
                        @click="$router.push({name: 'EthnicityManagementEdit', params: { id: scope.row.id }})"
                       :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"></el-button>
            <el-button type="danger" icon="el-icon-delete" circle size="mini"
                        @click="deleteEthnicity(scope.row)" :disabled="hasPermissionOnScreen(PERMISSION_DELETE)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <custom-el-pagination
        :length="total"
        :paginateFrom="paginateFrom"
        :paginateTo="paginateTo"
        :total="total"
        :pageSize="pageSize"
        :page="paramsSearch.page"
        @handleCurrentChange="handleCurrentChange"
      ></custom-el-pagination>
    </el-card>
  </div>
</template>

<script>
import CONST from '@/config/const'
import EthnicityManagementRequest from "@/requests/EthnicityManagementRequest";

export default {
  name: 'EthnicityManagement',
  props: {
    msg: String
  },
  data() {
    return {
      getData: [],
      params: {
        page: 1,
        limit: CONST.PAGINATION[0].value
      },
      paramsSearch: {
        page: 1,
        limit: CONST.PAGINATION[0].value
      },
      multipleSelection: [],
      pageSize: CONST.PAGINATION[0].value,
      total: 0,
      paginateFrom: 1,
      paginateTo: CONST.PAGINATION[0].value,
      listPaginate: CONST.PAGINATION
    }
  },
  methods: {
    async getList() {
      const data = await EthnicityManagementRequest.getListEthnicity(this.paramsSearch);
      if (data.data.data.length === 0 && this.page != 1) {
        this.params.page = this.page - 1;
        this.getList();
      }
      this.getData = data.data.data;
      this.page = data.data.current_page;
      this.pageSize = parseInt(data.data.per_page);
      this.total = data.data.total;
      this.paginateFrom = data.data.from;
      this.paginateTo = data.data.to;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    filterStatus(value) {
      return value !== 1 ? false : true;
    },
    searchEthnicity() {
      this.params.page = 1;
      this.paramsSearch = {...this.params}
      this.getList();
    },
    async updateStatusEthnicity(data) {
      await EthnicityManagementRequest.updateStatusEthnicity({id: data.id, status: data.status});
      this.showSuccess('Thành công.');
      this.getList(this.params);
    },
    handleCurrentChange(val) {
      this.page = val;
      const param = Object.assign(this.paramsSearch, {
        page: this.page
      });
      this.getList(param);
    },
    async deleteEthnicity(row) {
      if (await this.showConfirmMixin()) {
        EthnicityManagementRequest.deleteEthnicity(row.id, row.code).then(() => {
          const message = this.$t('Xoá thành công.');
          this.showSuccess(message);
          this.getList(this.params);
        }).catch(() => {
          const message = 'Lỗi khi xóa dân tộc.';
          this.showError(message);
          this.getList(this.params);
        });
      }
    },
    async deleteMultiEthnicity() {
      if (await this.showConfirmMixin()) {
        const listId = [];
        this.multipleSelection.map(function (value) {
          listId.push(value.id);
        });

        EthnicityManagementRequest.deleteMultiEthnicity({list: listId}).then(() => {
          const message = this.$t('Xoá thành công.');
          this.showSuccess(message);
          this.getList(this.params);
        }).catch(() => {
          const message = 'Lỗi khi xóa dân tộc.';
          this.showError(message);
          this.getList(this.params);
        });
      }
    },
    ChangePageSize(e) {
      this.paramsSearch.limit = e;
      this.paramsSearch.page = 1;
      this.getList(this.paramsSearch);
    },
    removeSelectionHeader({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        return "remove-selection";
      }
      return '';
    },
  },
  created() {
    this.getList();
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/style";

.ethnicity-search {
  .el-form {
    padding: 15px;
  }
}

.ethnicity-search_button {
  display: flex;
  float: right;
  width: auto;

  .el-form-item__content {
    margin-left: 0px !important;
    float: right;
    width: 100%;
  }
}

.ethnicity-search_name .el-form-item__content,
.ethnicity-search_id .el-form-item__content,
.ethnicity-search_name_en .el-form-item__content,
.organization-status .el-form-item__content {
  float: none;
}

.remove-selection {
  .cell {
    display: none !important;
  }
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/style";

.paginate-page {
  position: absolute;
  left: 0px;
  width: 220px;
  display: inline-flex;

  .el-dropdown {
    height: 20px;
    border: revert;
    border: 1px solid var(--select-border);
    background: #F7F8FC;
    color: #4B506D;
    margin-top: 17px;
    width: 45px;
  }

  p {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #9FA2B4;
    width: 150px;
  }
}

.organization-pagination {
  position: relative;

  .title-paginate {
    position: absolute;
    top: 25px;
    left: 35px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #9FA2B4;
  }
}

h3 {
  margin: 40px 0 0;
}

a {
  color: #42b983;
}

.link-back {
  background: #FFF;

  .link-back-list {
    display: table-cell;
    vertical-align: middle;
    height: 37px;

    a {
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #303133;
      padding: 0px 20px;
      height: 37px;
    }

    a.active {
      border-bottom: 2px solid #409EFF;
      padding-bottom: 6px;
    }
  }
}

// .ethnicity-search {
//   margin-top: 20px;

//   .title-search {
//     font-family: Helvetica;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 16px;
//     line-height: 18px;
//     color: #2C2C2C;
//     margin-bottom: 24px;
//   }
// }

.ethnicity-list-btn {
  justify-content: flex-end;
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
}

.organization-pagination {
  .el-pagination {
    padding: 20px 0px 10px 0px;
  }
}
</style>
