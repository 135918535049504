<template>
  <div class="userInfo">
    <el-form
      class="user-info-form"
      :model="model"
      :rules="rules"
      ref="form"
      @submit.native.prevent="changePassword"
      label-width="120px"
      label-position="left"
    >
      <div v-if="!isChangePassword" class="change-password">
        <i class="warning-change-pass"></i>
        <p class="mes-change-pass">Bạn đang sử dụng mật khẩu hệ thống cung cấp vui lòng đổi mật khẩu</p>
      </div>
      <p>QUẢN LÝ TÀI KHOẢN</p>
      <h5 class="user-name">{{ this.userInfomation.data.name }}</h5>
      <p class="user-info-title">THÔNG TIN CÁ NHÂN</p>
      <el-row>
        <el-col :span="12" class="user-info-form-item">
          <el-form-item label="Họ và tên">
            <el-input v-model="userInfomation.data.name" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="user-info-form-item">
          <el-form-item label="Email cá nhân">
            <el-input v-model="userInfomation.data.email" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="user-info-form-item">
          <div v-if="userInfomation.data.roles.length !== 0">
            <el-form-item label="Vai trò">
              <el-input v-model="userInfomation.data.roles[0].name" :disabled="true"></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="user-info-form-item">
          <el-form-item label="Tên đăng nhập">
            <el-input v-model="userInfomation.data.username" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p class="user-change-password-title">ĐỔI MẬT KHẨU</p>
      <el-row>
        <el-col :span="12" class="user-info-form-item">
          <el-form-item label="Mật khẩu cũ" prop="oldPassword">
            <el-input v-model="model.oldPassword" type="password" rows="2"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="user-info-form-item">
          <el-form-item label="Mật khẩu mới" prop="newPassword">
            <el-input v-model="model.newPassword" type="password"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="btn-user-info">
        <el-button
          :loading="loading"
          class="change-pass-button"
          type="primary"
          native-type="submit"
          block
        >Lưu</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UserRequest from "@/requests/UserRequest";
export default {
  name: "userInfo",
  data() {
    var checkSamePassword = (rule, value, callback) => {
      if (
        this.model.oldPassword &&
        value == this.model.oldPassword &&
        !this.isWrongOldPass
      ) {
        callback(new Error("Mật khẩu mới phải khác mật khẩu cũ."));
      } else {
        callback();
      }
    };
    var checkOldPassword = (rule, value, callback) => {
      const oldPass = {
        oldPassword: value,
      };
      const res = UserRequest.checkCorrectOldPassword(
        this.userInfomation.data.id,
        oldPass
      ).then((res) => {
        if (!res.data) {
          this.isWrongOldPass = true;
          callback(new Error("Mật khẩu cũ không đúng."));
        } else {
          this.isWrongOldPass = false;
          callback();
        }
      });
    };
    var checkOldPasswordNotmes = (rule, value, callback) => {
      const oldPass = {
        oldPassword: this.model.oldPassword,
      };
      const res = UserRequest.checkCorrectOldPassword(
        this.userInfomation.data.id,
        oldPass
      ).then((res) => {
        if (!res.data) {
          callback();
        } else {
          callback();
        }
      });
    };
    return {
      userInfomation: {
        data: {
          name: "",
          email: "",
          username: "",
          roles: [],
        },
      },
      model: {
        oldPassword: "",
        newPassword: "",
      },
      isWrongOldPass: false,
      isCorrectOldPass: true,
      isChangePassword: true,
      loading: false,
      rules: {
        oldPassword: [
          {
            required: true,
            message: "Vui lòng nhập mật khẩu cũ.",
            trigger: "blur",
          },
          {
            required: true,
            validator: checkOldPassword,
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "Vui lòng nhập mật khẩu mới.",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*(),.?":{}|<>\d]{8,50}$/,
            message:
              "Mật khẩu mới phải từ 8-50 ký tự, phải có ít nhất 1 chữ cái viết hoa, 1 chữ cái thường và 1 chữ số.",
            trigger: "blur",
          },
          {
            required: true,
            validator: checkOldPasswordNotmes,
            trigger: "blur",
          },
          {
            required: true,
            validator: checkSamePassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created: async function () {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    this.userInfomation = await UserRequest.getUserInfo(userInfo.id);

    UserRequest.isChangePassword({
      username: this.userInfomation.data.username,
    }).then((res) => {
      this.isChangePassword = res.isChange;
    });
  },
  methods: {
    async changePassword() {
      let valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      try {
        await UserRequest.changePassword(
          this.userInfomation.data.id,
          this.model,
          this.userInfomation.data.email
        );
        this.$message.success("Cập nhật mật khẩu thành công.");
        this.isChangePassword = true;
      } catch (e) {
        this.isCorrectOldPass = false;
      }
    },
  },
};
</script>

<style lang="scss">
.user-name{
  word-break: break-word;
}
.btn-user-info {
  margin-top: 30px;
  .el-form-item__content {
    margin-left: 0px !important;
  }
}
.user-info-form-item {
  .el-form-item {
    .el-form-item__label {
      color: #2c2c2c;
      font-family: Helvetica;
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.warning-change-pass {
  padding-right: 10px;
  &:before {
    content: url("../../assets/images/icons/warning-change-pass.svg");
  }
}
.change-password {
  display: inline-flex;
  margin-bottom: 20px;
}
.mes-change-pass {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  color: #f56c6c;
}
.userInfo {
  margin-top: 30px;
  .user-info-form {
    padding: 35px 21px;
  }
}
.user-info-form-item {
  .el-form-item {
    margin-bottom: 30px;
    .el-form-item__label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #2c2c2c;
      font-family: Helvetica;
    }
  }
}
p {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #909399;
  margin: 0px;
}
h5 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #363740;
  justify-content: left;
  margin: 31px 0px 43px;
}
.user-info-title,
.user-change-password-title {
  margin-bottom: 24px;
}
label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #2c2c2c;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.change-pass-button {
  background: #409eff;
  border-radius: 4px;
  border-color: #409eff;
  width: 95px;
  height: 40px;
}
</style>
<style lang="scss">
.passwordOld {
  .el-input__inner {
    width: 470px;
  }
}
</style>
