import Request from './Request';

class LanguageLevelRequest extends Request {
    async getListLanguageLevel(params) {
        let url = '/admin/language-levels/list';
        return await this.get(url, params);
    }
    async updateStatusLanguageLevel(params) {
        let url = '/admin/language-levels/update-status';
        return await this.post(url, params);
    }
    async deleteLanguageLevel(id) {
        let url = '/admin/language-levels/'+ id;
        return await this.delete(url);
    }
    async deleteMultiLanguageLevel(params) {
        let url = '/admin/language-levels/delete';
        return await this.post(url, params);
    }
    async createLanguageLevel(params) {
        let url = '/admin/language-levels/create';
        return await this.post(url, params);
    }
    async showLanguageLevel(id) {
        let url = '/admin/language-levels/'+id;
        return await this.get(url);
    }
    async updateLanguageLevel(params, id) {
        let url = '/admin/language-levels/'+id;
        return await this.put(url, params);
    }
    async checkCode(params) {
        let url = '/admin/language-levels/check-code';
        return await this.post(url, params);
    }
}
export default LanguageLevelRequest.getInstance()
