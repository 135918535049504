import RoleManagementRequest from "@/requests/RoleManagementRequest";

const state = {
  admin_role_permissions: null,
};

const getters = {
  admin_role_permissions(state) {
    return state.admin_role_permissions;
  },
};

const actions = {
  async changePermissionsByUser({commit}, admin_role_permissions) {
    commit("SET_PERMISSIONS", admin_role_permissions);
  },
  async getPermissionsByUser({commit}) {
    const res = await RoleManagementRequest.getPermissionsByUser();
    commit("SET_PERMISSIONS", res.data);
  }
}

const mutations = {
  SET_PERMISSIONS(state, admin_role_permissions) {
    state.admin_role_permissions = admin_role_permissions;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
