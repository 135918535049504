import Request from './Request';
import app from '@/main'
import AuthenticationUtils from "@/requests/AuthenticationStore";

class UserRequest extends Request {
  async login(data) {
    let url = '/auth/login';
    const res = await this.post(url, data, {
      'api-action': 'login',
      'api-action-meta': data.email
    });
    this.setUserAuth(res)
    window.app.$store.dispatch('getUser');
    window.app.$store.dispatch('getAdminMenu');
    window.app.$store.dispatch('getPermissionsByUser');
    return res
  }

  async logout(user) {
    let url = '/logout';
    return await this.get(url, {}, {
      'api-action': 'logout',
      'api-action-meta': user.username
    });
  }

  async checkBlockUser(data) {
    let url = '/auth/is-block-user';
    return await this.get(url, data, {
      'api-action': app.$t('checkBlockUser')
    });
  }

  async test(data) {
    let url = '/';
    return await this.get(url, data, {
      'X-Custom-Header': 'value'
    });
  }

  async getAuthInfo() {
    let url = '/auth/me';
    const user = AuthenticationUtils.getUserId()
    return await this.post(url);
  }

  async getUserInfo(id) {
    let url = '/admin/account-user/' + id;
    return await this.get(url);
  }

  async getAdminInfo(id) {
    let url = '/admin/account-admin/' + id;
    return await this.get(url);
  }

  async changePassword(id, data, email) {
    let url = '/admin/account/' + id + '/change-password';
    return await this.put(url, data, {
      'api-action': 'UserRequest-changePassword',
      'api-action-meta': email,
    });
  }

  async isChangePassword(data) {
    let url = '/admin/account/is-change-password';
    return await this.get(url, data, {
      'X-Custom-Header': 'value'
    });
  }

  async checkCorrectOldPassword(id, data) {
    let url = '/admin/account/' + id + '/check-password';
    return await this.get(url, data, {
      'X-Custom-Header': 'value'
    });
  }

  async updateKey(data) {
    let url = '/auth/update-key';
    return await this.put(url, data, {
      'api-action': 'UserRequest-update-key',
      'api-action-meta': data.email,
    });
  }

  async checkExistEmail(data) {
    let url = '/auth/is-exist-email';
    return await this.get(url, data, {
      'X-Custom-Header': 'value'
    });
  }

  async createUser(data) {
    let url = '/admin/account-user/create';
    return await this.post(url, data, {
      'api-action': 'UserRequest-create-user',
      'api-action-meta': data.email
    });
  }

  async editUser(id, data) {
    let url = `/admin/account-user/${id}`;
    return await this.put(url, data, {
      'api-action': 'UserRequest-edit-user',
      'api-action-meta': data.email
    });
  }

  async createAdmin(data) {
    let url = '/admin/account-admin/create';
    return await this.post(url, data, {
      'api-action': 'UserRequest-create-admin',
      'api-action-meta': data.email
    });
  }

  async editAdmin(id, data) {
    let url = `/admin/account-admin/${id}`;
    return await this.put(url, data, {
      'api-action': 'UserRequest-edit-admin',
      'api-action-meta': data.email
    });
  }

  async updatePassword(data) {
    let url = '/auth/update-password';
    return await this.put(url, data, {
      'X-Custom-Header': 'value'
    });
  }

  async validExpired(params) {
    let url = '/auth/valid-expired';
    return await this.get(url, params);
  }

  async uploadUserData(data) {
    let url = '/admin/account-user/upload-data';
    return await this.post(url, data, {'api-action': 'ImUserRequest-import'});
  }

  async getUploadStatus() {
    let url = '/admin/account-user/upload-status';
    return await this.get(url);
  }

  async fileExport() {
    let url = '/admin/account-user/file-download';
    return await this.get(url, {}, {}, {
      responseType: 'blob'
    });
  }

  async checkEmail(params) {
    let url = '/admin/account-user/check-email';
    return await this.post(url, params, {});
  }

  async checkUsername(params) {
    let url = '/admin/account-user/check-username';
    return await this.post(url, params, {});
  }

  async getListUser(params) {
    let url = '/list-user';
    return await this.get(url, params, {});
  }
}

export default UserRequest.getInstance()
