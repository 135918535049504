import Main from "@/pages/Admin/Layouts/Main"
import Sample from "@/pages/Cooperator/Sample"
import RouteDegreeManagement from '@/router/cooperator/degree-management';
import HistoryCooperator from '@/pages/Cooperator/History/History';
import UserInfo from "@/pages/Admin/UserInfo"

const Cooperator = [
    {
      path: '/sample',
      component: Sample,
      name: 'Sample'
    },
    {
        // cooperator site
        path: '/co-op/*',
        component: Main,
        meta: {
          auth: true
        },
        children: [
          // cooperator route
          {
            path: '/co-op/user-info',
            component: UserInfo,
            name: 'CoopUserInfo',
          },
          {
            path: '/co-op/sample',
            component: Sample,
            name: 'Sample1',
          },
          ...RouteDegreeManagement,
          {
            path: '/co-op/history',
            component: HistoryCooperator,
            name: 'HistoryCooperator',
          },
        ]
    }
];

export default Cooperator;
