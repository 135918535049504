import AccountAdmin from "./views/AccountAdmin"
import AccountUser from "./views/AccountUser"
import AdminComponent from "@/pages/Admin/accounts/AdminComponent";
import UserComponent from "@/pages/Admin/accounts/UserComponent";
import UploadData from "@/pages/Admin/accounts/UploadData";
import WrapPage from "@/pages/Admin/WrapPage";


const RouteAccount = [
  {
    path: "/admin/account-admins",
    component: WrapPage,
    name: 'AccountAdmin',
    children: [
      {
        path: '',
        component: AccountAdmin,
        name: 'AccountAdmin',
      },
      {
        path: 'create',
        component: AdminComponent,
        name: 'AdminComponentCreate',
      },
      {
        path: 'edit',
        component: AdminComponent,
        name: 'AdminComponentEdit',
      },
    ],
  },
  {
    path: "/admin/account-users",
    component: WrapPage,
    name: 'AccountUser',
    children: [
      {
        path: '',
        component: AccountUser,
        name: 'AccountUser',
      },
      {
        path: 'create',
        component: UserComponent,
        name: 'UserComponentCreate',
      },
      {
        path: 'edit',
        component: UserComponent,
        name: 'UserComponentEdit',
      },
      {
        path: 'upload-data',
        component: UploadData,
        name: 'UserComponentUploadData',
      },
    ],
  },
];

export default RouteAccount;