import EthnicityManagement from "@/pages/Admin/Ethnicity/EthnicityManagement";
import EthnicityManagementEdit from "@/pages/Admin/Ethnicity/EthnicityManagementEdit";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteEthnicity = [
  {
    path: '/admin/ethnicity-management',
    component: WrapPage,
    name: 'EthnicityManagement',
    children: [
      {
        path: '',
        name: 'EthnicityManagement',
        component: EthnicityManagement,
      },{
        path: 'create',
        name: 'EthnicityManagementCreate',
        component: EthnicityManagementEdit,
      },{
        path: ':id/edit',
        name: 'EthnicityManagementEdit',
        component: EthnicityManagementEdit,
      },
    ],
  },
];

export default RouteEthnicity;