import Request from './Request';

class OrganizationRequest extends Request {
    async getListOrganization(params) {
        let url = '/admin/organizations/list';
        return await this.get(url, params);
    }
    async updateStatusOrganization(params) {
        let url = '/admin/organizations/update-status';
        return await this.post(url, params);
    }
    async deleteOrganization(id, code) {
        let url = '/admin/organizations/'+ id;
        return await this.delete(url, {}, {
            'api-action': 'OrganizationRequest-delete',
            'api-action-meta': code
        });
    }
    async deleteMultiOrganization(params) {
        let url = '/admin/organizations/delete';
        return await this.post(url, params, {
            'api-action': 'OrganizationRequest-deleteAll',
            'api-action-meta': params.list.length
        });
    }
    async createOrganization(params) {
        let url = '/admin/organizations/create';
        return await this.post(url, params, {
            'api-action': 'OrganizationRequest-create',
            'api-action-meta': params.code
        });
    }
    async showOrganization(id) {
        let url = '/admin/organizations/'+id;
        return await this.get(url);
    }
    async updateOrganization(params, id) {
        let url = '/admin/organizations/'+id;
        return await this.put(url, params, {
            'api-action': 'OrganizationRequest-edit',
            'api-action-meta': params.code
        });
    }
    async downloadTemplate() {
        let url = '/admin/organizations/download-template';
        return await this.get(url, {}, {}, {responseType: 'blob'});
    }
    async importOrganization(formData) {
        let url = '/admin/organizations/import';
        return await this.post(url, formData,
          {
              'Content-Type': 'multipart/form-data',
              'api-action': 'ImOrganizationRequest-import'
          });
    }
    async exportOrganization(params) {
        let url = '/admin/organizations/export';
        return await this.get(url, params, {
            'api-action': 'ExOrganizationRequest-export'
        }, {responseType: 'blob'});
    }
    async checkCode(params) {
        let url = '/admin/organizations/check-code';
        return await this.post(url, params);
    }
    async getAllOrganization() {
        let url = '/admin/organizations/all';
        return await this.get(url);
    }
    async getActiveOrganizationList(params) {
      let url = '/admin/organizations/active-organization';
      return await this.get(url, params);
    }
}
export default OrganizationRequest.getInstance()
