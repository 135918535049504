import Ranking from "@/pages/Admin/Rankings/RankingList";
import RankingCreateUpdate from "@/pages/Admin/Rankings/RankingCreateUpdate";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteRanking = [
    {
      path: '/admin/rankings',
      component: WrapPage,
      name: 'Ranking',
      children: [
        {
          path: '',
          name: 'Ranking',
          component: Ranking,
        },
        {
          path: 'create-update',
          name: 'RankingCreateUpdate',
          component: RankingCreateUpdate,
        },
      ],
    },
];

export default RouteRanking;
