export default {
  API_URL: process.env.VUE_APP_API_URL ?? 'http://localhost:8001',
  APP_URL: process.env.VUE_APP_BASE_URL ?? 'http://localhost:8080',
  PAGINATION: [
    { key: 25, value: 25 },
    { key: 50, value: 50 },
    { key: 100, value: 100 },
  ],
  SUPER_ADMIN_ID: 1,
  TYPE_ADMIN: 1,
  TYPE_ELEMENTARY_SCHOOL: 2,
  MAX_LENGTH_INPUT: 250,
  DEGREE_REQUEST_TYPES: ['Cấp mới', 'Chỉnh sửa', 'Hủy bằng'],
  HISTORY_ACTION_EMAIL: ['UserRequest-edit-user', 'UserRequest-create-user',
    'UserRequest-update-key', 'UserRequest-create-admin', 'UserRequest-edit-admin'],
  REF_FORM_DEFAULT: 'form',
  DEGREE_TYPE_PENDING: 'pending',
  DEGREE_TYPE_APPROVED: 'approved',
  DEGREE_TYPE_REJECTED: 'rejected',
  COMPONENT_DEFAULT: 'CooperatorPending', //Chờ duyệt
  EMAIL_REGEX: /^([A-Za-z0-9_.])+@([A-Za-z0-9_.-])+\.([A-Za-z]{2,})$/,
  GUARD_NAME_PROVIDER_UNIT: 'provider_unit_api',
  MAX_EXPORT_XLSX: 50000,
  MESSAGE_LIMIT_EXPORT : "Hệ thống chỉ hỗ trợ kết xuất tối đa 10,000 bản ghi dữ liệu.",
  DEGREE_AUTHORITY_UNITS: [
    {key: '1', value: 'Giáo dục đại học'},
    {key: '2', value: 'Giáo dục phổ thông'}
  ],
};
