<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ name: 'CertificateMangement' }" class="clickable-link">Trình độ</el-breadcrumb-item>
			<el-breadcrumb-item>Thêm mới</el-breadcrumb-item>
		</el-breadcrumb>

		<!-- Search -->
		<el-row>
			<el-form
				class="create-edit-form"
				v-loading="loading"
				label-position="left"
				:model="degree"
				ref="formName"
				:rules="rules"
			>
				<p class="title-form">Thêm mới trình độ</p>
				<el-row>
					<el-col :sm="24" :md="12">
						<el-form-item label="Mã" prop="code">
							<el-input v-model="degree.code" placeholder maxlength="8"></el-input>
						</el-form-item>
						<el-form-item label="Trình độ" prop="name">
							<el-input v-model="degree.name" placeholder maxlength="250"></el-input>
						</el-form-item>
      					<p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
						<el-form-item label="Tình trạng">
							<el-switch v-model="degree.status"></el-switch>
						</el-form-item>
						<div class="mt5">
							<el-button type="primary" class="btn" @click="handleSubmit()" :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">Lưu</el-button>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</el-row>
	</div>
</template>

<script>
	import DegreeRequest from "../api/DegreeRequest";
	import Rule from "../validator/Rule";
	export default {
		name: "CreateCertificate",
		data() {
			return {
				loading: false,
				degree: {
					name: "",
					code: "",
					status: true,
				},
				rules: Rule,
			};
		},
		methods: {
			async handleSubmit() {
				this.loading = true;
				try {
					await this.$refs["formName"].validate();
					let res = await DegreeRequest.post(this.degree);
					this.degree = res.data;
					this.showSuccess("Thêm mới trình độ thành công.");
					this.$router.push({ name: "CertificateMangement" });
				} catch (error) {
					console.log(error.response);
				}
				this.loading = false;
			},
		},
	};
</script>

<style>
</style>
