import Vue from "vue";
import VueRouter from "vue-router";
import Sample from "@/pages/Admin/Sample"
import SearchDegree from "@/pages/SearchDegree"
import SearchDegreeDetail from "@/pages/SearchDegreeDetail"
import Header from "@/pages/Admin/Layouts/Header"
import Login from "@/pages/Login"
import ConfirmPassword from "@/pages/ConfirmPassword";
import ForgotPassword from "@/pages/ForgotPassword"
import ChangePassword from "@/pages/ChangePassword"
import AuthenticationStore from "@/requests/AuthenticationStore";
import NotFound from '@/pages/NotFound';
import Admin from '@/router/admin';
import Cooperator from '@/router/cooperator';

const User = [
  {
    path: '/',
    components: {keep_alive: SearchDegree},
    name: 'Landing'
  },
  {
    path: '/degree-detail',
    component: SearchDegreeDetail,
    name: 'SearchDegreeDetail'
  },
];

var admin = [];
var coop = []; 
var user = []; 
if (process.env.VUE_APP_ENABLE_ADMIN == "TRUE") {
  admin = [ ...Admin ];
}
if (process.env.VUE_APP_ENABLE_CO_OP == 'TRUE') {
  coop = [ ...Cooperator ];
}
if (process.env.VUE_APP_ENABLE_USER == 'TRUE') {
  user = [ ...User ];
} else {
  user = [{
    path: '/',
    component: Login,
    name: 'login'
  }]
}

Vue.use(VueRouter);


const routes = [
  ...user,
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
  {
    path: '/confirm-password',
    component: ConfirmPassword,
    name: 'ConfirmPassword'
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'ForgotPassword'
  },
  {
    path: '/change-password',
    component: ChangePassword,
    name: 'ChangePassword'
  },
  ...coop,
  ...admin,
  {
    // other route
    path: '/*',
    component: Sample,
    children: [
      {
        path: '/xxx',
        component: Header,
        name: 'Header'
      },
    ]
  },

  {
    path: "*",
    component: NotFound
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.length > 0 && to.matched[0].meta && to.matched[0].meta.auth && !AuthenticationStore.isAuthenticated()) {
    return next({ path: '/login' });
  }
  return next();
});

export default router;
