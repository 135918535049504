import Request from '@/requests/Request';

class AccountUserRequest extends Request {
    prefix = '/admin/account-users/';

    async search(data) {
        let url = this.prefix;
        return await this.get(url, data);
    }

    async show(id) {
        let url = this.prefix + id;
        return await this.get(url);
    }

    async update(id, data) {
        let url = this.prefix + id;
        return await this.put(url, data);
    }

    async patch(id, data) {
        let url = this.prefix + id;
        return await super.patch(url, data);
    }

    async destroy(id) {
        let url = this.prefix + id;
        return await this.delete(url);
    }

    async destroyMulti(data, email) {
        let url = this.prefix;
        let meta = '';
        let name = '';
        email === undefined ? meta = data.length : meta = email;
        email === undefined ? name = 'UserRequest-deleteAll' : name = 'UserRequest-delete';
        return await this.delete(url, data, {
            'api-action': name,
            'api-action-meta': meta
        });
    }
}

export default AccountUserRequest.getInstance()
