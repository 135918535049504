import Request from './Request';

class SettingRequest extends Request {
  async getEmail() {
    let url = '/admin/email/';
    return await this.get(url);
  }

  async changeEmail(data) {
    let url = '/admin/email/';
    return await this.post(url, data);
  }
}

export default SettingRequest.getInstance()