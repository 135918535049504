<template>
  <div>
    <template>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'EthnicityManagement' }" class="clickable-link">Dân tộc</el-breadcrumb-item>
        <el-breadcrumb-item>{{ form.id ? $t('Chỉnh sửa') : $t('Thêm mới') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-row>
      <el-form class="create-edit-form" ref="form" :rules="rules" :model="form"  label-position="left">
        <p class="title-form">{{ form.id ? $t('Chỉnh sửa') : $t('Thêm mới') }} dân tộc</p>
        <el-row>
          <el-col :span="14">
            <el-form-item label="Mã" prop="code">
              <el-input maxlength='8' v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="Dân tộc" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
        <el-row>
          <el-col :span="10" class="ethnicity-status">
            <el-form-item label="Tình trạng">
              <el-switch v-model="form.status">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="btn-group-form">
          <el-button type="danger" class="btn" v-if="this.$route.params.id !== undefined"
                     @click="deleteEthnicity(form)" :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)">{{$t("btnDelete")}}
          </el-button>
          <el-button type="primary" plain class="btn" v-if="this.$route.params.id !== undefined"
                     @click="resetForm()" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">{{$t("btnReset")}}
          </el-button>
          <el-button class="btn" type="primary" @click="submitForm()" v-if="this.$route.params.id !== undefined" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">{{$t("btnSave")}}</el-button>
          <el-button class="btn" type="primary" @click="submitForm()" v-else :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">{{$t("btnSave")}}</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import EthnicityManagementRequest from "@/requests/EthnicityManagementRequest";

export default {
  name: 'EthnicityCreateEdit',
  props: {
    msg: String
  },

  data() {
    let checkCode = (rule, value, callback) => {
      EthnicityManagementRequest.checkField({code: value}).then((res) => {
        if (res.data !== null && this.init.code !== res.data.code) {
          callback(new Error('Mã đã tồn tại.'));
        } else {
          callback();
        }
      });
    };


    return {
      init: {},
      form: {
        code: '',
        name: '',
        status: true,
        id: null,
      },

      rules: {
        code: [
          {required: true, message: 'Vui lòng nhập mã.', trigger: 'blur'},
          {max: 50, message: 'Mã phải nhở hơn 50 kí tự.', trigger: 'blur'},
          {pattern: /^[a-zA-Z0-9_]+([-.][a-zA-Z0-9_]+)*$/, message: 'Mã chỉ chứa chữ cái tiếng Anh và chữ số.', trigger: 'blur'},
          {validator: checkCode, trigger: 'blur'}
        ],
        name: [
          {required: true, message: 'Vui lòng nhập dân tộc.', trigger: 'blur'},
          {max: 250, message: 'Trường dân tộc phải nhở hơn 250 kí tự.', trigger: 'blur'},
        ],
      }
    }
  },

  computed: {
    ...mapGetters(['getMasterData']),
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.$route.params.id !== undefined) {
          this.updateEthnicity();
          return;
        }
        this.createEthnicity();
      });

    },
    async deleteEthnicity(row) {
      if (await this.showConfirmMixin()) {
        EthnicityManagementRequest.deleteEthnicity(row.id, row.code).then(() => {
          const message = this.$t('Xoá thành công.');
          this.showSuccess(message);
          this.$router.push({name: 'EthnicityManagement'});
        }).catch(() => {
          const message = 'Lỗi khi xóa dân tộc.';
          this.showError(message);
        });
      }
    },

    createEthnicity() {
      EthnicityManagementRequest.createEthnicity(this.form).then(() => {
        const message = this.$t('Thêm mới dân tộc thành công.');
        this.showSuccess(message);
        this.$router.push({name: 'EthnicityManagement'});
      }).catch(() => {
        const error = "Lỗi khi thêm dân tộc.";
        this.showError(error);
      });
    },
    updateEthnicity() {
      EthnicityManagementRequest.updateEthnicity(this.form, this.$route.params.id).then(() => {
        const message = this.$t('Cập nhật dân tộc thành công.');
        this.showSuccess(message);
        this.$router.push({name: 'EthnicityManagement'});
      }).catch(() => {
        const error = "Lỗi khi sửa dân tộc.";
        this.showError(error);
      });
    },
    resetForm() {
      this.form = _.clone(this.init)
      this.clearValidate()
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      EthnicityManagementRequest.showEthnicity(this.$route.params.id).then((res) => {
        this.init = res.data;
        this.init.status = !!res.data.status;
        this.form = _.clone(this.init)
      });
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";

.breadcrumb-ethnicity {
  .el-breadcrumb__item {
    .el-breadcrumb__inner.is-link, .el-breadcrumb__inner {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #2C2C2C;
    }
  }
}

.btn-ethnicity-action {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}

.clickable-link:hover {
  .is-link {
    color: $color_hover_link !important;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ethnicity-form {
  padding: 20px;
}
.el-form-item__label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #2C2C2C;
}
</style>
