<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ name: 'CertificateMangement' }" class="clickable-link">Trình độ</el-breadcrumb-item>
			<el-breadcrumb-item>Chỉnh sửa</el-breadcrumb-item>
		</el-breadcrumb>

		<!-- Search -->
		<el-row>
			<el-form
				class="create-edit-form"
				v-loading="loading"
				label-position="left"
				:model="degree"
				ref="formName"
				:rules="rules"
			>
				<p class="title-form">Chỉnh sửa trình độ</p>
				<el-row>
					<el-col :sm="24" :md="12">
						<el-form-item label="Mã" prop="code">
							<el-input v-model="degree.code" placeholder maxlength="8"></el-input>
						</el-form-item>
						<el-form-item label="Trình độ" prop="name">
							<el-input v-model="degree.name" placeholder maxlength="250"></el-input>
						</el-form-item>
      					<p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
						<el-form-item label="Tình trạng">
							<el-switch v-model="degree.status"></el-switch>
						</el-form-item>
						<div class="mt5">
							<el-button
								type="danger"
								class="btn"
								@click="handleConfirm()"
								:disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)"
							>Xoá</el-button>
							<el-button
								type="primary"
								class="btn"
								@click="fetchData()"
								plain
								:disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
							>Đặt Lại</el-button>
							<el-button
								type="primary"
								class="btn"
								@click="handleUpdate()"
								:disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
							>Lưu</el-button>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</el-row>
	</div>
</template>

<script>
	import DegreeRequest from "../api/DegreeRequest";
	import Rule from "../validator/Rule";
	export default {
		name: "EditCertificate",
		data() {
			return {
				loading: false,
				degree: {
					name: "",
					code: "",
					status: false,
				},
				rules: Rule,
			};
		},
		watch: {
			$router: "fetchData",
		},
		methods: {
			async fetchData() {
				this.loading = true;
				try {
					let res = await DegreeRequest.show(this.$route.params.id);
					this.degree = res.data;
				} catch (error) {}
				this.loading = false;
			},
			async handleDelete() {
				this.loading = true;
				try {
					let res = await DegreeRequest.delete(this.$route.params.id, this.degree.code);
					this.showSuccess("Xóa thành công.");
					this.$router.push({ name: "CertificateMangement" });
				} catch (error) {}
				this.loading = false;
			},
			async handleUpdate() {
				this.loading = true;
				try {
					await this.$refs["formName"].validate();
					let res = await DegreeRequest.put(
						this.$route.params.id,
						this.degree
					);
					this.degree = res.data;
					this.showSuccess("Cập nhật trình độ thành công.");
					this.$router.push({ name: "CertificateMangement" });
				} catch (error) {}
				this.loading = false;
			},
			async handleConfirm() {
				if (await this.showConfirmMixin()) {
					this.handleDelete();
				}
			},
		},
		created() {
			this.fetchData();
		},
	};
</script>

<style lang="scss" scoped>
</style>
