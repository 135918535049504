import _ from "lodash";
import mainApp from '@/main';
import CONST from '@/config/const';
import app from "@/main";

export default {
  data() {
    return {
      PERMISSION_READ: 'r',
      PERMISSION_CREATE: 'c',
      PERMISSION_UPDATE: 'u',
      PERMISSION_DELETE: 'd',
      GUARD_NAME_PROVIDER_UNIT: 'provider_unit_api',
    }
  },
  methods: {
    showSuccess(message) {
      this.$message({
        type: 'success',
        message: message
      });
    },
    showError(message) {
      if (window.is_permission_error) {
        window.is_permission_error = false;
        return;
      }
      this.$message({
        type: 'error',
        message: message
      });
    },
    async showConfirmMixin(message = "Bạn có chắc chắn muốn xóa không?", title = "Cảnh Báo", others) {
      let cf = false;
      cf = await this.$confirm(message, title, {
        confirmButtonText: "Có",
        cancelButtonText: "Không",
        customClass: "moet-cf-delete",
        ...others
      }).then(() => {
        return true;
      }).catch(() => {
        return false;
      });
      return cf;
    },
    downloadFile(data, name = "default") {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(
        new Blob([data])
      );
      link.setAttribute('download', `${name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    getAction(value) {
      const arr = value.split("-");
      return arr[1];
    },
    getNameAction(value) {
      const arr = value.split("-");
      return arr[0];
    },
    clearValidate(formName = 'form') {
      this.$refs[formName].clearValidate();
    },
    gotoLink(route) {
      this.$router.push(route);
      app.$store.dispatch('getAdminMenu');
    },
    hasPermissionOnScreen(checkingPermission = 'r', app = mainApp) {
      let hasPermission = true;
      if (!app) {
        return false;
      }
      const screenName = app.$router.currentRoute.matched[1] ? app.$router.currentRoute.matched[1].name : app.$router.currentRoute.name;
      _.forEach(app.$store.state.admin_role_permissions, (permission) => {
        if (permission.admin_menu && permission.admin_menu.name === screenName && permission.permission === checkingPermission) {
          hasPermission = false;
          return false;
        }
      })
      return hasPermission;
    },
    checkLimitExport(total) {
      const h = this.$createElement;
      if (total >= CONST.MAX_EXPORT_XLSX) {
        this.showError('Lượng dữ liệu quá lớn. Hệ thống chỉ hỗ trợ kết xuất tối đa 10,000 bản ghi dữ liệu, vui lòng thay đổi điều kiện lọc.');
        return true;
      }
      return false;
    },
    changeFileSize(size) {
      let mb = size / (1024 * 1024);
      if (mb >= 1) {
        return mb.toFixed(1) + " MB";
      }
      return (size / 1024).toFixed(1) + " KB";
    },
   nonAccentVietnamese(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
}
  }
}
