<template>
  <div>
    <template>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ name: 'Organization' }">Quản lý đơn vị cấp bằng</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.$route.query.organization !== undefined ? 'Chỉnh sửa' : 'Thêm mới'}}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-row>
      <el-form
        label-width="170px"
        class="create-edit-form"
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
      >
        <p class="title-form">Thông tin đơn vị cấp bằng</p>
        <el-row>
          <el-col :span="14" class="organization-search_id">
            <el-form-item label="ID đơn vị" prop="code">
              <el-input maxlength="8" v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14" class="organization-search_name">
            <el-form-item label="Tên đơn vị cấp bằng" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14" class="organization-search_name_en">
            <el-form-item label="Tên đơn vị tiếng anh" prop="name_en">
              <el-input v-model="form.name_en"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14" class="organization-degree-authority-unit-type">
            <el-form-item label="Loại đơn vị cấp bằng" prop="degree_authority_unit_type">
              <el-select v-model="form.degree_authority_unit_type" clearable placeholder="" style="width: 100%">
                <el-option v-for="unit in CONSTS.DEGREE_AUTHORITY_UNITS" :label="unit.value" :value="unit.key" :key="unit.key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="certificate-title">
          <span style="color: #F56C6C">*</span> ĐƯỢC PHÉP CẤP BẰNG TRÌNH ĐỘ
        </p>
        <el-row>
          <el-col :span="10" class="certificate-list">
            <el-form-item label prop="certificate">
              <el-checkbox-group v-model="form.certificate">
                <el-checkbox
                  v-for="certificate in getMasterData.certificaties"
                  :label="certificate.id"
                  :key="certificate.id"
                  name="type"
                >{{ certificate.name }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
        <el-row>
          <el-col :span="10" class="organization-status">
            <el-form-item label="Tình trạng">
              <el-switch v-model="form.status"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="btn-group-form">
          <template v-if="this.$route.query.organization !== undefined">
            <el-button
              type="danger"
              class="btn"
              @click="deleteOrganization(form)"
              :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)"
            >Xóa</el-button>
            <el-button
              type="primary"
              plain
              class="btn"
              @click="resetForm()"
              :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
            >Đặt Lại</el-button>
            <el-button
              type="primary"
              class="btn"
              @click="submitForm()"
              :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
            >Lưu</el-button>
          </template>
          <template v-else>
            <el-button
              type="primary"
              class="btn"
              @click="submitForm()"
              :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)"
            >Lưu</el-button>
          </template>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import OrganizationRequest from "@/requests/OrganizationRequest";
import { mapGetters } from "vuex";
import CONSTS from "@/config/const";

export default {
  name: "OrganizationCreateUpdate",
  props: {
    msg: String,
  },
  data() {
    let checkCode = (rule, value, callback) => {
      OrganizationRequest.checkCode({ code: value, id: this.form.id }).then(
        (res) => {
          if (res.data.length === 1) {
            callback(new Error("ID đơn vị đã tồn tại."));
          } else {
            callback();
          }
        }
      );
    };
    return {
      CONSTS: CONSTS,
      form: {
        code: "",
        name: "",
        name_en: "",
        degree_authority_unit_type: "",
        certificate: [],
        status: true,
        id: null,
      },
      rules: {
        code: [
          {
            required: true,
            message: "Vui lòng nhập ID đơn vị",
            trigger: "blur",
          },
          {
            max: 8,
            message: "ID đơn vị không vượt quá 8 ký tự.",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9_]+([-.][a-zA-Z0-9_]+)*$/,
            message: "ID đơn vị chỉ chứa chữ cái tiếng Anh và chữ số.",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        name: [
          {
            required: true,
            message: "Vui lòng nhập tên đơn vị cấp bằng.",
            trigger: "blur",
          },
          {
            max: 250,
            message: "Tên đơn vị cấp bằng không vượt quá 250 ký tự.",
            trigger: "blur",
          },
        ],
        name_en: [
          {
            message: "Vui lòng nhập tên đơn vị tiếng anh.",
            trigger: "blur",
          },
          {
            max: 250,
            message: "Tên đơn vị tiếng anh không vượt quá 250 ký tự.",
            trigger: "blur",
          },
        ],
        degree_authority_unit_type: [
          {
            required: true,
            message: "Vui lòng chọn loại đơn vị cấp bằng",
            trigger: "change",
          }
        ],
        certificate: [
          {
            required: true,
            message: "Vui lòng chọn các bằng được cấp phép.",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getMasterData"]),
  },
  methods: {
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.$route.query.organization !== undefined) {
          this.updateOrganization();
          return;
        }
        this.createOrganization();
      });
    },
    async deleteOrganization(row) {
      if (await this.showConfirmMixin()) {
        OrganizationRequest.deleteOrganization(row.id, row.code)
          .then(() => {
            this.showSuccess("Xoá thành công.");
            this.$router.push({ name: "Organization" });
          })
          .catch(() => {
            this.showError("Lỗi khi xóa đơn vị cấp bằng.");
          });
      }
    },
    createOrganization() {
      OrganizationRequest.createOrganization(this.form)
        .then(() => {
          this.showSuccess("Thêm mới đơn vị cấp bằng thành công.");
          this.$router.push({ name: "Organization" });
        })
        .catch(() => {
          this.showError("Có lỗi khi thêm đơn vị cấp bằng.");
        });
    },
    updateOrganization() {
      OrganizationRequest.updateOrganization(
        this.form,
        this.$route.query.organization
      )
        .then(() => {
          this.showSuccess("Cập nhật đơn vị cấp bằng thành công.");
          this.$router.push({ name: "Organization" });
        })
        .catch(() => {
          this.showError("Có lỗi khi sửa đơn vị cấp bằng.");
        });
    },
    getDetail() {
      OrganizationRequest.showOrganization(this.$route.query.organization).then(
        (res) => {
          this.form.code = res.data.code;
          this.form.id = res.data.id;
          this.form.name = res.data.name;
          this.form.name_en = res.data.name_en;
          this.form.status = res.data.status == 1 ? true : false;
          this.form.degree_authority_unit_type = res.data.degree_authority_unit_type
          const listId = [];
          res.data.certificate.map(function (value) {
            listId.push(value.id);
          });
          this.form.certificate = listId;
        }
      );
    },
    resetForm() {
      this.clearValidate();
      this.getDetail();
    },
  },
  created() {
    if (this.$route.query.organization !== undefined) {
      this.getDetail();
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/style";
.breadcrumb-organization {
  .el-breadcrumb__item {
    .el-breadcrumb__inner.is-link,
    .el-breadcrumb__inner {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #2c2c2c;
    }
  }
}
.certificate-list {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}
.btn-organization-action {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}
.certificate-list {
  .el-checkbox {
    display: block;
  }
}
.btn-group {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.organization-form {
  padding: 20px;
}
.title-form {
  text-transform: uppercase;
}
.title-form,
.certificate-title,
.organization-status-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #909399;
  margin-bottom: 24px;
}
.el-form-item__label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #2c2c2c;
}
</style>
