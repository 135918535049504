import Request from './Request';

class ProviderUnitRequest extends Request {
  async getListProviderUnit(params) {
    let url = '/admin/provider-unit/list';
    return await this.get(url, params);
  }
  async deleteProviderUnit(id) {
    let url = '/admin/provider-unit/'+ id;
    return await this.delete(url);
  }
  async deleteMultiProviderUnits(params) {
    let url = '/admin/provider-unit/';
    return await this.delete(url, params);
  }
  async createProviderUnit(params) {
    let url = '/admin/provider-unit/create';
    return await this.post(url, params);
  }
  async showProviderUnit(id) {
    let url = '/admin/provider-unit/'+id;
    return await this.get(url);
  }
  async checkCode(params) {
    let url = '/admin/provider-unit/check-code';
    return await this.post(url, params);
  }
  async generateNewToken(params) {
    let url = '/admin/provider-unit/generate-token/';
    return await this.post(url, params);
  }
}
export default ProviderUnitRequest.getInstance()
