<template>
  <div>
    <router-view/>
    <keep-alive>
      <router-view name="keep_alive" />
    </keep-alive>
  </div>

</template>

<script>
  import { MessageBox } from 'element-ui';

  export default {
    name: 'App',
    components: {
    },
    methods: {
      isAdminLayout() {

      }
    },
    async mounted() {
      window.onpopstate = function(event) {
        MessageBox.close()
      };
    }
  }
</script>
