import Request from '@/requests/Request';

class ExportRequest extends Request {
    prefix = '/admin/export/';

    async user(data) {
        let url = this.prefix + 'users';
        return await this.get(url, data, {
            'api-action': 'ExExportRequest-export',
        });
    }
}

export default ExportRequest.getInstance()
