import Vue from 'vue'
import router from "@/router";
import store from "@/store";
import _ from 'lodash';

Vue.directive('permissions', {
  bind: function (el, binding, vnode) {
    const componentName = router.currentRoute.matched[1]
        ? router.currentRoute.matched[1].name
        : router.currentRoute.name;
    const permissionName = binding.value
    el.disabled = 'disabled';
    el.classList.add("is-disabled");
    _.forEach(store.state.admin_role_permissions, (permission) => {
      if (permission.admin_menu.name === componentName && permission.permission === permissionName) {
        el.disabled = false;
        el.classList.remove("is-disabled");
        return;
      }
    })
  }
})