import Request from '@/requests/Request';

class OrganizationRequest extends Request {
    prefix = '/admin/suggestion-organization';

    async index(data) {
        let url = this.prefix;
        return await super.get(url, data);
    }

    async getOrganization(data) {
      let url = '/admin/organizations/get-organization';
      return await super.get(url, data);
    }

    async getSchool(data) {
      let url = '/admin/organizations/get-school';
      return await super.get(url, data);
    }
}

export default OrganizationRequest.getInstance()
