<template>
  <div class="department-management">
    <!-- Form Search -->
    <template>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'DepartmentMan' }">Ngành</el-breadcrumb-item>
        <el-breadcrumb-item>{{ departmentId ? 'Chỉnh sửa' : 'Thêm mới'}}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="params" class="create-edit-form" ref="form" :rules="rules">
      <p class="title-form">{{ departmentId ? 'Chỉnh sửa ngành' : 'Thêm mới ngành'}}</p>
      <el-row>
        <el-col :span="14">
          <el-form-item label="Mã" class="label-custom" prop="code">
            <el-input
                maxlength='8'
                class="custom-input"
                v-model="params.code"
                name="code"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="14">
          <el-form-item label="Tên ngành" class="label-custom" prop="name">
            <el-input
                class="custom-input"
                v-model="params.name"
                name="name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
      <el-row>
        <el-col :span="10">
          <el-form-item label="Tình trạng" class="label-custom">
            <el-switch
                v-model="params.status"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="btn-group-form">
        <el-button type="danger" class="btn" v-if="departmentId" @click="delData()" :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)">Xóa</el-button>
        <el-button type="primary" plain class="btn" v-if="departmentId" @click="reset()">Đặt Lại</el-button>
        <el-button type="primary" class="btn" @click="submit()" v-if="departmentId" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Lưu</el-button>
        <el-button type="primary" class="btn" @click="submit()" v-else :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">Lưu</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import DepartmentRequest from "@/requests/DepartmentRequest";
export default {
  name: "Create",
  data(){
    return {
      params: {
        id:"",
        name:"",
        status: true
      },
      rules: {
        code: [
            { required: true, message: 'Vui lòng nhập mã.', trigger: 'blur' },
            { max: 8, message: 'Mã tên ngành nhỏ hơn 8 kí tự', trigger: 'blur' },
            { pattern:/^[a-zA-Z0-9]*$/, message: 'Mã chỉ chứa chữ cái tiếng Anh và chữ số.', trigger: 'blur' },
            { required: true, validator: this.checkExistDepartment,  trigger: 'blur' }
        ],
        name: [
            { required: true, message: 'Vui lòng nhập tên ngành.', trigger: 'blur' },
            { max: 250, message: 'Tên ngành nhỏ hơn 250 kí tự', trigger: 'blur' }
        ]
      },
      temp: {},
      departmentId: this.$route.params.id || "",
    }
  },
  created() {
    if (this.departmentId) {
      DepartmentRequest.show(this.departmentId).then((res) => {
        this.params = res.data;
        this.params.status = !!res.data.status
        this.temp = _.clone(res.data)
      })
    }
  },
  methods: {
    async submit() {
      let valid = await this.$refs['form'].validate();
      if (!valid) {
        return;
      }
      const params = {
        ...this.params,
        'status': this.params.status ? 1 : 0
      }
      if (this.departmentId) {
        DepartmentRequest.edit(this.departmentId, params).then(() => {
          this.showSuccess('Cập nhật ngành thành công.');
          this.$router.push({name: 'DepartmentMan'})
        })
        return;
      }
      DepartmentRequest.create(params).then(() => {
        this.showSuccess('Thêm mới ngành thành công.');
        this.$router.push({name: 'DepartmentMan'})
      })
    },
    reset() {
      this.params = _.clone(this.temp);
    },
    async delData() {
      let checkConfirm = await this.showConfirmMixin(`Bạn có chắc chắn muốn xóa không?`);
      if (checkConfirm) {
        DepartmentRequest.destroy(this.params.id, this.params.code).then(() => {
          this.showSuccess('Xóa thành công.')
          this.$router.push({name: 'DepartmentMan'})
        }).catch(() => {
          this.showError('Lỗi khi xóa.');
        });
      }
    },
    checkExistDepartment(rule, value, callback) {
      if (this.params.code === this.temp.code) {
        callback();
      }
      DepartmentRequest.checkExist({'code': this.params.code}).then((res) => {
        if (res.data) {
          callback(new Error("Mã đã tồn tại."));
        } else {
          callback();
        }
      }).catch(() => {
        callback();
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.label-custom {
  /deep/.el-form-item__label {
    text-align: left !important;
  }
}
</style>
