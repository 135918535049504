<template>
  <el-tabs v-model="activeName" @tab-click="handleClick" class="custom-tab">
    <el-tab-pane label="Đăng nhập/xuất" name="LoginOut">
      <history-login-out :show="showTab('LoginOut')"></history-login-out>
    </el-tab-pane>
    <el-tab-pane label="Email gửi đi" name="Email">
      <user-history :show="showTab('Email')">
      </user-history>
    </el-tab-pane>
    <el-tab-pane label="Nhập dữ liệu" name="Import">
      <import-history :show="showTab('Import')">
      </import-history>
    </el-tab-pane>
    <el-tab-pane label="Kết xuất dữ liệu" name="Export">
      <export-history :show="showTab('Export')"></export-history>
    </el-tab-pane>
    <el-tab-pane label="Ghi/sửa/xoá dữ liệu" name="ChangeData">
      <change-data-history :show="showTab('ChangeData')"></change-data-history>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import UserHistory from "@/pages/Admin/History/UserHistory";
import ImportHistory from "@/pages/Admin/History/ImportHistory";
import ExportHistory from "@/pages/Admin/History/ExportHistory";
import ChangeDataHistory from "@/pages/Admin/History/ChangeDataHistory";
import HistoryLoginOut from "@/pages/Admin/History/HistoryLoginOut";

export default {
  components: {UserHistory, ImportHistory, ExportHistory, ChangeDataHistory, HistoryLoginOut},
  data() {
    return {
      activeName: 'LoginOut',
      params: {},
    };
  },
  methods: {
    handleClick(tab, event) {
      this.$router.push({name: 'History', query: {tab_name: tab._props.name}})
    },
    showTab(name) {
      return this.$route.query.tab_name === name ? true : false;
    }
  },
  created() {
    this.$route.query.tab_name === undefined ? this.activeName = 'LoginOut' :
      this.activeName = this.$route.query.tab_name;
  }
};
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
  .custom-tab {
    .el-tabs__nav-next,.el-tabs__nav-prev {
      display: none;
    }
    .el-tabs__header {
      background: $color_white;
      margin:0px -20px 15px;
      .el-tabs__nav-wrap::after {
        background-color: revert;
      }
    }
    .el-tabs__nav {
      .el-tabs__item:nth-child(2) {
        padding-left: 15px;
      }
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__item {
        padding: 0 30px;
      }
      .el-tabs__item.is-active {
        border-bottom: 2px solid #409EFF;
      }
      .el-tabs__item:last-child {
        padding-right: 30px;
      }
      .el-tabs__nav-scroll {
        overflow-x: auto;
      }
    }
  }
</style>
