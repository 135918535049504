<template>
  <el-aside class="menu">
    <el-menu :default-openeds="openedMenuId">
      <div class="menu-item"
        v-for="(menu, index) in adminMenus" :key="menu.id"
        :class="{'length-max': menu.unicode_name === 'Quản lý đơn vị cung cấp dịch vụ'}">
        <el-submenu
          :index="index.toString()"
          v-if="menu.sub_menus && menu.sub_menus.length"
          :class="{'is-active-menu': menu.active}">
          <template slot="title">
            <i :class="menu.icon_class + ' icon-menu-custom'"></i>
            <span class="menu-item-name">{{ menu.unicode_name }}</span>
          </template>
          <el-menu-item-group class="submenu-group">
            <div v-for="(subMenu, index) in menu.sub_menus" :key="subMenu.name">
              <el-menu-item
                :index="index.toString()"
                class="submenu-item"
                @click="gotoLink(subMenu, menu)">
                <span
                  :class="{'is-active-menu-item': subMenu.active}"
                  class="submenu-item-name">
                  {{ subMenu.unicode_name }}
                </span>
              </el-menu-item>
            </div>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item
          v-else
          @click="gotoLink(menu)"
          :class="{'is-active-menu': menu.active}">
          <i :class="menu.icon_class + ' icon-menu-custom'"></i>
          <span
            class="submenu-item-name"
            :class="{'is-active-menu-item': menu.active}">{{ menu.unicode_name }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
  import {mapState} from 'vuex';
  import AdminMenuRequest from '@/requests/AdminMenuRequest';

  export default {
    name: 'Header',
    props: {
      msg: String,
    },
    data() {
      return {
        openedMenuId: [],
        adminMenus: [],
      }
    },
    computed: {
      ...mapState(['masterdata', 'admin_menu']),
    },
    watch: {
      admin_menu() {
        this.initMenu();
      }
    },
    methods: {
      initMenu() {
        let menuList = JSON.parse(JSON.stringify(this.admin_menu));
        menuList.push({name: 'UserInfo', route: 'user-info', sub_menus: [], unicode_name: 'Tài khoản', icon_class: 'icon_menu_account'})
        this.resetActiveMenu(menuList);
        const rootComponentName = this.$router.currentRoute.matched[1]
                              ? this.$router.currentRoute.matched[1].name
                              : this.$router.currentRoute.name;
        _.forEach(menuList, (menu, index) => {
          if (menu.name == rootComponentName) {
            this.$set(menu, 'active', true);
            this.openedMenuId.push(rootComponentName === 'UserInfo' ? null : index.toString());
            return;
          } else {
            const subMenu = menu.sub_menus;
            if (subMenu) {
              for (let i = 0; i < subMenu.length; i++) {
                if (subMenu[i].name == rootComponentName) {
                  this.$set(subMenu[i], 'active', true);
                  this.$set(menu, 'active', true);
                  this.openedMenuId.push(rootComponentName === 'UserInfo' ? null : index.toString());
                  return;
                }
              }
            }
          }
        })
        this.adminMenus = menuList;
      },
      checkActive(menu) {
        this.resetActiveMenu(this.adminMenus);
        if (menu.name == this.$router.currentRoute.name) {
          this.$set(menu, 'active', true);
          return;
        }
        const subMenu = menu.sub_menus
        if (subMenu) {
          for (let i = 0; i < subMenu.length; i++) {
            if (subMenu[i].name == this.$router.currentRoute.name) {
              this.$set(subMenu[i], 'active', true);
              this.$set(menu, 'active', true);
              return;
            }
          }
        }
        return false;
      },
      gotoLink(menu, parentMenu = undefined) {
        this.$router.push({name: menu.name}).catch(()=>{});
        this.checkActive(parentMenu || menu);
      },
      resetActiveMenu(menuList) {
        _.forEach(menuList, (menuItem) => {
          this.$set(menuItem, 'active', false);
          _.forEach(menuItem.sub_menus, (subMenuItem) => {
            this.$set(subMenuItem, 'active', false);
          })
        });
      }
    },
    created() {
      this.initMenu();
    }
  }
</script>
<style lang="scss">
  .menu {
    .menu-item.length-max {
      padding-top: 5px;

      .el-menu-item {
        padding-left: 20px;
        height: 55px;
        line-height: 20px;
        padding-top: 7px;
        display: flex;
      }
    }

    .el-menu-item.submenu-item {
      padding: 0 30px;
    }
      
    .el-submenu__title {
      position: relative;

      .el-submenu__icon-arrow {
        position: absolute;
        left: 5px;
        right: revert;
        margin-top: -9px;
        font-size: 14px;
      }
    }

    .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
      transform: rotateZ(0deg);
    }

    .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
      transform: rotateZ(-90deg);
    }

    .icon_menu_degree:before {
      content: url('../../../assets/images/icons/degree.svg');
    }

    .icon_menu_system:before {
      content: url('../../../assets/images/icons/system.svg');
    }

    .icon_menu_user:before {
      content: url('../../../assets/images/icons/user.svg');
    }

    .icon_menu_role:before {
      content: url('../../../assets/images/icons/role.svg');
    }

    .icon_menu_organization:before {
      content: url('../../../assets/images/icons/organization.svg');
    }

    .icon_menu_category:before {
      content: url('../../../assets/images/icons/category.svg');
    }

    .icon_menu_service_provider:before {
      content: url('../../../assets/images/icons/service-provider.svg');
    }

    .icon_menu_report_statistic:before {
      content: url('../../../assets/images/icons/report-statistic.svg');
    }

    .icon_menu_account:before {
      content: url('../../../assets/images/icons/account.svg');
    }

    .icon-menu-custom {
      padding: 0px 19px 0px 5px;
    }

    .el-submenu__title:hover {
      background: #374253;

      .el-submenu__icon-arrow, .menu-item-name {
        color: #409EFF;
      }

      .icon_menu_degree:before {
        content: url('../../../assets/images/icons/degree-active.svg');
      }

      .icon_menu_system:before {
        content: url('../../../assets/images/icons/system-active.svg');
      }

      .icon_menu_user:before {
        content: url('../../../assets/images/icons/user-active.svg');
      }

      .icon_menu_role:before {
        content: url('../../../assets/images/icons/role-active.svg');
      }

      .icon_menu_organization:before {
        content: url('../../../assets/images/icons/organization-active.svg');
      }

      .icon_menu_category:before {
        content: url('../../../assets/images/icons/category-active.svg');
      }

      .icon_menu_service_provider:before {
        content: url('../../../assets/images/icons/service-provider-active.svg');
      }

      .icon_menu_report_statistic:before {
        content: url('../../../assets/images/icons/report-statistic-active.svg');
      }

      .icon_menu_account:before {
        content: url('../../../assets/images/icons/account-active.svg');
      }
    }

    .el-submenu.is-active-menu {
      &:focus {
        background-color: unset !important;
      }
      .el-submenu__title {
        background: #374253;

        .el-submenu__icon-arrow, .menu-item-name {
          color: #409EFF;
        }

        .icon_menu_degree:before {
          content: url('../../../assets/images/icons/degree-active.svg');
        }

        .icon_menu_system:before {
          content: url('../../../assets/images/icons/system-active.svg');
        }

        .icon_menu_user:before {
          content: url('../../../assets/images/icons/user-active.svg');
        }

        .icon_menu_role:before {
          content: url('../../../assets/images/icons/role-active.svg');
        }

        .icon_menu_organization:before {
          content: url('../../../assets/images/icons/organization-active.svg');
        }

        .icon_menu_category:before {
          content: url('../../../assets/images/icons/category-active.svg');
        }

        .icon_menu_service_provider:before {
          content: url('../../../assets/images/icons/service-provider-active.svg');
        }

        .icon_menu_report_statistic:before {
          content: url('../../../assets/images/icons/report-statistic-active.svg');
        }

        .icon_menu_account:before {
          content: url('../../../assets/images/icons/account-active.svg');
        }
      }
    }

    .el-menu-item.is-active-menu {
      &:focus {
        background-color: unset !important;
      }
      .el-menu-item__icon-arrow, .menu-item-name {
        color: #409EFF;
      }

      .icon_menu_degree:before {
        content: url('../../../assets/images/icons/degree-active.svg');
      }

      .icon_menu_system:before {
        content: url('../../../assets/images/icons/system-active.svg');
      }

      .icon_menu_user:before {
        content: url('../../../assets/images/icons/user-active.svg');
      }

      .icon_menu_role:before {
        content: url('../../../assets/images/icons/role-active.svg');
      }

      .icon_menu_organization:before {
        content: url('../../../assets/images/icons/organization-active.svg');
      }

      .icon_menu_category:before {
        content: url('../../../assets/images/icons/category-active.svg');
      }

      .icon_menu_service_provider:before {
        content: url('../../../assets/images/icons/service-provider-active.svg');
      }

      .icon_menu_report_statistic:before {
        content: url('../../../assets/images/icons/report-statistic-active.svg');
      }

      .icon_menu_account:before {
        content: url('../../../assets/images/icons/account-active.svg');
      }
    }

    .el-menu-item:hover {
      background: #374253;

      .submenu-item-name {
        color: #409EFF;
      }

      .icon_menu_degree:before {
        content: url('../../../assets/images/icons/degree-active.svg');
      }

      .icon_menu_system:before {
        content: url('../../../assets/images/icons/system-active.svg');
      }

      .icon_menu_user:before {
        content: url('../../../assets/images/icons/user-active.svg');
      }

      .icon_menu_role:before {
        content: url('../../../assets/images/icons/role-active.svg');
      }

      .icon_menu_organization:before {
        content: url('../../../assets/images/icons/organization-active.svg');
      }

      .icon_menu_category:before {
        content: url('../../../assets/images/icons/category-active.svg');
      }

      .icon_menu_service_provider:before {
        content: url('../../../assets/images/icons/service-provider-active.svg');
      }

      .icon_menu_report_statistic:before {
        content: url('../../../assets/images/icons/report-statistic-active.svg');
      }

      .icon_menu_account:before {
        content: url('../../../assets/images/icons/account-active.svg');
      }
    }
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .menu {
    width: 303px !important;
    background-color: #363740;
    overflow-x: hidden;
    height: auto !important;
  }

  .menu-item {
    background-color: #363740;
  }

  .submenu-item {
    background-color: #363740;
  }

  .submenu-group {
    background-color: #363740;
  }

  .menu-item-name {
    word-break: break-word;
    color: #A4A6B3;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.2px;
    font-style: normal;
    font-weight: normal;
  }

  .submenu-item-name {
    color: #A4A6B3;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.2px;
    font-style: normal;
    font-weight: normal;
    white-space: normal;
    word-break: break-word;
  }
  .is-active-menu-item {
    color: #409EFF
  }
</style>
