import Request from './Request';

class DoetRequest extends Request {
    prefix = '/admin/doet-management/';

    async getList(params) {
        let url = this.prefix + 'index';
        return await this.get(url, params);
    }

    async create(params) {
        let url = this.prefix + 'create';
        return await this.post(url, params);
    }

    async show(id) {
        let url = this.prefix + id;
        return await this.get(url);
    }

    async edit(id, params) {
        let url = this.prefix + id;
        return await this.put(url, params);
    }

    async destroy(id) {
        let url = this.prefix + id;
        return await this.delete(url);
    }

    async destroyAll(params) {
        let url = this.prefix + 'delete-all';
        return await this.post(url, params);
    }

    async import(params) {
        let url = this.prefix + 'export';
        return await this.get(url, params);
    }

    async checkExist(params) {
        let url = this.prefix + 'check-exist';
        return await this.get(url, params);
    }
}

export default DoetRequest.getInstance()