<template>
  <div class="doet-management">
    <template>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'DoetManager' }">Sở GD-DT</el-breadcrumb-item>
        <el-breadcrumb-item>{{ doetId ? 'Chỉnh sửa' : 'Thêm mới' }}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <!-- Form Search -->
    <el-form :model="params" class="create-edit-form" ref="form" :rules="rules">
      <p class="title-form">{{ doetId ? 'Chỉnh sửa' : 'Thêm mới' }} sở GD-ĐT</p>
      <el-row>
        <el-col :span="14">
          <el-form-item label="Mã" class="label-custom" prop="code">
            <el-input
                maxlength='8'
                class="custom-input"
                v-model="params.code"
                name="code"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="14">
          <el-form-item label="Sở GD-ĐT" class="label-custom" prop="name">
            <el-input
                class="custom-input"
                v-model="params.name"
                name="name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p class="status-title">TÌNH TRẠNG HOẠT ĐỘNG</p>
      <el-row>
        <el-col :span="10">
          <el-form-item label="Tình trạng" class="label-custom">
            <el-switch
                active-color="#13ce66"
                v-model="params.status"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="btn-group-form">
        <el-button type="danger" class="btn" v-if="doetId" @click="delData()" :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)">Xóa</el-button>
        <el-button type="primary" plain class="btn" v-if="doetId" @click="reset()" :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">Đặt Lại</el-button>
        <el-button type="primary" class="btn" @click="submit()" v-if= "doetId" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Lưu</el-button>
        <el-button type="primary" class="btn" @click="submit()" v-else :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">Lưu</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import DoetRequest from "@/requests/DoetRequest";
export default {
  name: "Create",
  data(){
    return {
      params: {
        code:"",
        name:"",
        status: true
      },
      rules: {
        code: [
          {
            required: true,
            message: "Vui lòng nhập mã.",
            trigger: "blur",
          },
          {
            max: 50,
            message: "Mã không vượt quá 50 ký tự.",
            trigger: "blur",
          },
          {
            required: true,
            validator:this.checkExistCode,
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: "Vui lòng nhập sở GD-ĐT.",
            trigger: "blur",
          },
          {
            max: 250,
            message: "Sở GD-ĐT không vượt quá 250 ký tự.",
            trigger: "blur",
          },
          {
            required: true,
            validator:this.checkExistDoet,
            trigger: 'blur'
          }
        ]
      },
      temp: {},
      doetId: this.$route.params.id || "",
    }
  },
  created() {
    if (this.doetId) {
      DoetRequest.show(this.doetId).then((res) => {
        console.log(res.data)
        this.params = res.data;
        this.params.status = !!res.data.status
        this.temp = _.clone(res.data)
      })
    }
  },
  methods: {
    async submit() {
      let valid = await this.$refs['form'].validate();
      if (!valid) {
        return;
      }
      const params = {
        ...this.params,
        'status': this.params.status ? 1 : 0
      }
      if (this.doetId) {
        DoetRequest.edit(this.doetId, params).then(() => {
          this.$router.push({name: 'DoetManager'})
        })
        return;
      }
      const { code, name } = this.params;
      DoetRequest.create({ ...params, code, name }).then(() => {
        this.showSuccess('Thêm mới sở GD-ĐT thành công.');
        this.$router.push({name: 'DoetManager'})
      })
    },
    reset() {
      this.params = _.clone(this.temp);
    },
    async delData() {
      let checkConfirm = await this.showConfirmMixin(`Bạn có chắc chắn muốn xóa không?`);
      if (checkConfirm) {
          DoetRequest.destroy(this.params.id).then(() => {
          this.showSuccess('Xóa thành công.')
          this.$router.push({name: 'DoetManager'})
        }).catch(() => {
          this.showError('Lỗi khi xóa.');
        });
      }
    },
    checkExistCode(rule, value, callback) {
      DoetRequest.checkExist({'code': this.params.code}).then((res) => {
        if (res.data) {
          callback(new Error("Mã đã tồn tại."));
        }
      }).catch(() => {
        callback();
      })
      callback();
    },
    checkExistDoet(rule, value, callback) {
      DoetRequest.checkExist({'name': this.params.name}).then((res) => {
        if (res.data) {
          callback(new Error("sở GD-ĐT đã tồn tại"));
        }
      }).catch(() => {
        callback();
      })
      callback();
    }
  }
}
</script>

<style lang="scss" scoped>
.status-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #909399;
  margin-bottom: 24px;
}
.form-custom {
  margin-top: 24px;
  padding: 17px;
  .custom-input {
    width: 382px;
    height: 39px;
  }
  .fl-right {
    float: right;
  }
}
.btn-custom {
  width: 95px;
  height: 40px;
}
.label-custom {
  /deep/.el-form-item__label {
    text-align: left !important;
  }
  .custom-input {
    width: 465px;
    height: 39px;
  }
}
</style>
