<template>
  <div>
    <template>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Quản lý người dùng</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'AccountUser' }">Đơn vị cấp bằng</el-breadcrumb-item>
        <el-breadcrumb-item>Tải dữ liệu</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-row :gutter="20" class="upload-data" v-loading="loading">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="wrap">
            <p class="title">Tải lên dữ liệu người dùng</p>
            <p class="note">
              <a href="javascript:void(0);" role="button">
                <i class="el-icon-download" @click="fileExport()"></i>
              </a>
              <span class="link-color" @click="fileExport()">
                Tải xuống dữ liệu mẫu tại đây
              </span>
            </p>
            <el-upload
              ref="fileUpload"
              class="upload-custom"
              drag
              action="post"
              :showFileList="false"
              :autoUpload="false"
              :onChange="onChange"
              v-if="!file.name"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Kéo thả hoặc
                <em>chọn tệp</em>
                <span class="upload-tip">xls, xlsx (Tối đa 5MB)</span>
              </div>
            </el-upload>
            <div class="file-detail" v-if="file.name">
              <p>{{file.name}}</p>
              <p>{{changeFileSize(file.size)}}</p>
            </div>
            <el-row>
              <el-button
                type="info"
                class="custom-button"
                @click="removeFile()"
                :disabled="!file.name"
              >Hủy</el-button>
              <el-button
                type="primary"
                class="custom-button"
                @click="submit()"
                :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this) || !file.name || loading"
              >Tải Lên</el-button>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UserRequest from "@/requests/UserRequest";
const UPLOAD_SUCCESS = "success";
const UPLOAD_ERROR = "error";
export default {
  name: "UploadData",
  data() {
    return {
      file: {},
      loading: false,
      statusInterval: null,
    };
  },
  created() {
    this.getUploadStatus();
  },
  mounted() {
    this.statusInterval = setInterval(() => {
      this.getUploadStatus();
    }, 10000);
  },
  methods: {
    async fileExport() {
      const res = await UserRequest.fileExport();
      this.downloadFile(res, "Du-lieu-mau-nguoi-dung-don-vi-cap-bang");
    },
    onChange() {
      this.file = this.$refs.fileUpload.uploadFiles[0];
    },
    removeFile() {
      this.file = {};
    },
    submit() {
      const formData = new FormData();
      formData.append("file", this.file.raw);
      const ext = this.file.name.substr(this.file.name.lastIndexOf(".") + 1);
      if (ext !== "xlsx" && ext !== "csv" && ext !== "xls") {
        this.loading = false;
        this.showError("Tệp đã chọn không đúng định dạng.");
        return;
      }
      if (this.file.size / (1024*1024) > 5) {
        this.loading = false;
        this.showError("Tệp tin không được lớn hơn 5MB.");
        return;
      }
      UserRequest.uploadUserData(formData)
        .then(() => {
          this.file = {};
          this.showSuccess("Tải dữ liệu thành công.");
          this.getUploadStatus();
          this.$router.push({ name: "AccountUser" });
        })
        .catch((err) => {
          this.loading = false;
          if (err.message === "Network Error") {
            this.removeFile();
            this.showError(
              "Nội dung tệp tin đã bị thay đổi, vui lòng chọn lại tệp tin."
            );
            return;
          }
          if (err.response) {
            this.showError(err.response.data.message);
          }
        });
    },

    getUploadStatus() {
      UserRequest.getUploadStatus().then((res) => {
        if (!res.status) {
          return;
        }
        this.loading =
          res.status !== UPLOAD_SUCCESS && res.status !== UPLOAD_ERROR;
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.statusInterval);
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-icon-download {
  color: #67c23a !important;
  font-size: 24px;
}
.note {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #2c2c2c;
  margin-top: 30px;
}
.upload-data {
  .custom-button {
    width: 95px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
  }
  .upload-custom {
    margin-bottom: 38px;
    /deep/ .el-upload-dragger {
      width: 100%;
      height: 231px;
      background-color: rgba(192, 196, 204, 0.1);
      font-size: 13px;
    }
    /deep/ .el-upload {
      width: 100%;
      position: relative;
    }
    .el-upload__text {
      color: #2c2c2c;
    }
    .upload-tip {
      font-size: 12px;
      position: absolute;
      color: #909399;
      top: 58%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .wrap {
    background-color: #fff;
    padding: 28px;
    .title {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
.link-color {
  color: #409eff;
  cursor: pointer;
  text-decoration: underline;
  padding-left: 10px;
}
</style>
