import Request from '@/requests/Request';

class AccountUserRequest extends Request {
    prefix = '/admin/account-users/';

    async search(data) {
        let url = this.prefix;
        return await this.get(url, data);
    }

    async show(id) {
        let url = this.prefix + id;
        return await this.get(url);
    }

    async update(id, data) {
        let url = this.prefix + id;
        return await this.put(url, data);
    }

    async destroy(id, email) {
        let url = this.prefix + id;
        return await this.delete(url, {}, {
            'api-action': 'AccountUserRequest-delete',
            'api-action-meta': email
        });
    }

    async destroyMulti(data) {
        let url = this.prefix;
        return await this.delete(url, data);
    }
}

export default AccountUserRequest.getInstance()
