<template>
  <div>
    <template>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ name: 'ProviderUnit' }">Quản lý đơn vị cung cấp dữ liệu</el-breadcrumb-item>
        <el-breadcrumb-item>{{ providerUnitId ? 'Chỉnh sửa' : 'Thêm mới ' }}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-row>
      <el-form
        label-width="170px"
        class="create-edit-form"
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
      >
        <p class="title-form">{{ providerUnitId ? 'Chỉnh sửa' : 'Thêm mới đơn vị cung cấp' }}</p>
        <el-row>
          <el-col :span="12" class="provider-unit-search_id">
            <el-form-item label="Mã" prop="code">
              <el-input maxlength="8" v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="provider-unit-search_name">
            <el-form-item label="Tên đơn vị cung cấp" prop="unit">
              <el-input v-model="form.unit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="btn-group-form">
          <el-button
            class="btn"
            type="danger"
            v-if="this.providerUnitId"
            @click="deleteProviderUnit(form)"
            :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)"
          >Xóa</el-button>
          <el-button
            class="btn"
            type="primary"
            plain
            @click="resetForm()"
            v-if="this.providerUnitId"
            :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
          >Đặt Lại</el-button>
          <el-button
            class="btn"
            type="primary"
            @click="submitForm()"
            v-if="providerUnitId"
            :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
          >Lưu</el-button>
          <el-button
            class="btn"
            type="primary"
            @click="submitForm()"
            v-else
            :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)"
          >Lưu</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import ProviderUnitRequest from "@/requests/ProviderUnitRequest";

export default {
  name: "ProviderUnitEdit",
  props: {
    msg: String,
  },
  data() {
    let checkCode = (rule, value, callback) => {
      ProviderUnitRequest.checkCode({ code: value, id: this.form.id }).then(
        (res) => {
          if (res.data.length === 1) {
            callback(new Error(this.$t("common.message.exists_error")));
          } else {
            callback();
          }
        }
      );
    };
    return {
      form: {
        code: "",
        unit: "",
        id: null,
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t("common.message.required"),
            trigger: "blur",
          },
          {
            max: 8,
            message: "Mã đơn vị cung cấp nhỏ hơn 8 kí tự",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9]*$/,
            message: this.$t("common.message.format_pattern"),
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        unit: [
          {
            required: true,
            message: "Vui lòng nhập tên đơn vị cung cấp.",
            trigger: "blur",
          },
          {
            max: 250,
            message: "Đơn vị cung cấp nhỏ hơn 250 kí tự",
            trigger: "blur",
          },
        ],
      },
      providerUnitId: null,
      originalProviderUnit: {},
    };
  },
  methods: {
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.providerUnitId) {
          this.updateProviderUnit();
          return;
        }
        this.createProviderUnit();
      });
    },
    async deleteProviderUnit(row) {
      let checkConfirm = await this.showConfirmMixin(
        `Bạn có chắc chắn muốn xóa không?`
      );
      if (checkConfirm) {
        ProviderUnitRequest.deleteProviderUnit(row.id)
          .then(() => {
            this.showSuccess("Xóa thành công.");
            this.$router.push({ name: "ProviderUnit" });
          })
          .catch(() => {
            this.showError("Lỗi khi xóa.");
          });
      }
    },
    createProviderUnit() {
      console.log(this.form);
      ProviderUnitRequest.createProviderUnit(this.form)
        .then(() => {
          const messsage = "Thêm mới đơn vị cung cấp thành công.";
          this.showSuccess(messsage);
          this.$router.push({ name: "ProviderUnit" });
        })
        .catch(() => {
          const error = "Có lỗi khi thêm đơn vị cung cấp";
          this.showError(error);
        });
    },
    updateProviderUnit() {
      ProviderUnitRequest.updateProviderUnit(this.form, this.providerUnitId)
        .then(() => {
          this.$router.push({ name: "ProviderUnit" });
        })
        .catch(() => {
          const error = "Có lỗi khi sửa đơn vị cung cấp";
          this.showError(error);
        });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      if (this.providerUnitId) {
        this.form.unit = this.originalProviderUnit.unit;
        this.form.code = this.originalProviderUnit.code;
        this.form.status = this.originalProviderUnit.status;
        return;
      }
    },
  },
  created() {
    this.providerUnitId = this.$route.params.id;
    if (this.providerUnitId) {
      ProviderUnitRequest.showProviderUnit(this.providerUnitId).then((res) => {
        this.form.code = res.data.code;
        this.form.id = res.data.id;
        this.form.unit = res.data.unit;
        this.form.status = !!res.data.status;
        this.originalProviderUnit = _.clone(this.form);
      });
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
.status-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #909399;
  margin-bottom: 24px;
}
.breadcrumb-provider-unit {
  .el-breadcrumb__item {
    .el-breadcrumb__inner.is-link,
    .el-breadcrumb__inner {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #2c2c2c;
    }
  }
}
.certificate-list {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}
.provider-unit-status {
  .el-form-item__label {
    width: 200px !important;
  }
  .el-form-item__content {
    margin-left: 200px !important;
  }
}
.btn-provider-unit-action {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.provider-unit-form {
  padding: 20px;
}
.title-form {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #2c2c2c;
  margin-bottom: 35px;
}
.certificate-title,
.provider-unit-status-title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #2c2c2c;
}
.certificate-list {
  .el-checkbox {
    display: block;
  }
}
.el-form-item__label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #2c2c2c;
}
.btn-provider-unit-action {
  margin-top: 16px;
}
</style>
