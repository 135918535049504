<template>
  <el-row>
    <el-col :span="24" class="group-search">
      <el-form label-position="left">
        <p class="title-search">Quản lý xếp loại</p>
        <el-row>
          <el-col :span="11">
            <el-form-item label="Mã">
              <el-input maxlength='8' @keyup.enter.native="searchRanking()" v-model="params.code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="Loại xếp loại">
              <el-input @keyup.enter.native="searchRanking()" v-model="params.type"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="16" class="button-search">
            <el-form-item>
              <el-button type="primary" class="btn" @click="searchRanking">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <el-col :span="8" :offset="16" class="ranking-list-btn">
      <div class="paginate-page">
        <p>Số bản ghi trên trang:</p>
        <el-dropdown @command="ChangePageSize">
          <span class="el-dropdown-link">
          {{ paramsSearch.limit }}
          <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in listPaginate"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button type="success" class="btn" @click="$router.push({name: 'RankingCreateUpdate'})" :disabled="hasPermissionOnScreen(PERMISSION_CREATE)">
          Thêm Mới
      </el-button>
      <el-button type="danger" class="btn" disabled v-show="multipleSelection.length === 0"
                  @click="deleteMultiRanking()">Xóa</el-button>
        <el-button type="danger" class="btn" v-show="multipleSelection.length !== 0"
                   @click="deleteMultiRanking()" :disabled="hasPermissionOnScreen(PERMISSION_DELETE)">Xóa</el-button>
    </el-col>

    <el-card shadow="never">
      <el-table
          :data="getData"
          style="width: 100%"
          empty-text="Không có dữ liệu"
          @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              prop="code"
              label="Mã">
          </el-table-column>
          <el-table-column
              prop="type"
              label="Loại xếp loại">
          </el-table-column>
          <el-table-column
              prop="status"
              label="Tình trạng">
              <template scope="scope">
                  <el-switch
                      @change="updateRanking(scope.row)"
                      :value="filterStatus(scope.row.status)"
                      :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)">
                  </el-switch>
              </template>
          </el-table-column>
          <el-table-column
              label=""
              align="right">
              <template class="action-ranking" scope="scope">
                  <el-button type="info" icon="el-icon-edit" circle size="mini"
                  @click="$router.push({name: 'RankingCreateUpdate', query: { ranking: scope.row.id }})"
                    :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"></el-button>
                  <el-button type="danger" icon="el-icon-delete" circle size="mini"
                  @click="deleteRanking(scope.row)" :disabled="hasPermissionOnScreen(PERMISSION_DELETE)"></el-button>
              </template>
          </el-table-column>
      </el-table>
      <custom-el-pagination
          :length="total"
          :paginateFrom="paginateFrom"
          :paginateTo="paginateTo"
          :total="total"
          :pageSize="pageSize"
          :page="paramsSearch.page"
          @handleCurrentChange="handleCurrentChange"
      ></custom-el-pagination>
    </el-card>
  </el-row>
</template>

<script>
    import RankingRequest from '@/requests/RankingRequest';
    import CONST from '@/config/const';

    export default {
        name: 'Ranking',
        props: {
            msg: String
        },
        data() {
            return {
                getData: [],
                params: {
                    limit: CONST.PAGINATION[0].value
                },
                paramsSearch: {
                  page: 1,
                  limit: CONST.PAGINATION[0].value
                },
                multipleSelection: [],
                page: 1,
                pageSize: 25,
                total: 0,
                paginateFrom: 1,
                paginateTo: 25,
                listPaginate: CONST.PAGINATION
            }
        },
        methods: {
            async getList() {
               const data = await RankingRequest.getListRanking(this.paramsSearch);
               if (data.data.data.length === 0 && this.page != 1) {
                  this.params.page = this.page - 1;
                  this.getList();
               }
               this.getData = data.data.data;
               this.page = data.data.current_page;
               this.pageSize = parseInt(data.data.per_page);
               this.total = data.data.total;
               this.paginateFrom = data.data.from;
               this.paginateTo = data.data.to;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            filterStatus(value) {
                return value !== 1 ? false : true;
            },
            searchRanking() {
              this.params.page = 1;
              this.paramsSearch = {...this.params}
              this.getList();
            },
            async updateRanking(data) {
                await RankingRequest.updateStatusRanking({id:data.id, status:data.status});
                this.showSuccess('Thành công.');
                this.getList();
            },
            handleCurrentChange(val) {
                this.page = val;
                const param = Object.assign(this.paramsSearch, {
                    page: this.page
                });
                this.getList(param);
            },
            async deleteRanking(row) {
							if (await this.showConfirmMixin()) {
									RankingRequest.deleteRanking(row.id, row.code).then(() => {
									const message = this.$t('Xoá thành công.');
									this.showSuccess(message);
									this.getList(this.params);
									}).catch(() => {
									const message = 'Lỗi khi xóa xếp loại.';
									this.showError(message);
									this.getList(this.params);
									});
							}
            },
            async deleteMultiRanking() {
							if (await this.showConfirmMixin()) {
								const listId = [];
								this.multipleSelection.map(function (value) {
									listId.push(value.id);
								});

								RankingRequest.deleteMultiRanking({list: listId}).then(() => {
									const message = this.$t('Xoá thành công.');
									this.showSuccess(message);
									this.getList(this.params);
								}).catch(() => {
									const message = 'Lỗi khi xóa xếp loại.';
									this.showError(message);
									this.getList(this.params);
								});
							}
            },
            ChangePageSize(e) {
              this.paramsSearch.limit = e;
              this.paramsSearch.page = 1;
              this.getList(this.paramsSearch);
            }
        },
        created() {
            this.getList();
        }
    }
</script>
<style lang="scss">
    @import "@/assets/sass/_variables";
    .ranking-pagination {
        ul.el-pager {
            list-style-type: none;
            padding: 0;
            li {
                display: inline-block;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                color: #606266;
                padding: 0px 15px;
            }
            li.active {
                color: #1989FA;
            }
        }
    }

    .ranking-search {
        .el-form {
            padding: 15px;
        }
    }
    .ranking-search_button {
        display: flex;
        float: right;
        width: auto;
        .el-form-item__content {
            margin-left: 0px !important;
            float: right;
            width: 100%;
        }
    }
    .ranking-search_name .el-form-item__content,
    .ranking-search_id .el-form-item__content,
    .ranking-search_name_en .el-form-item__content,
    .ranking-status .el-form-item__content{
        float: none;
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/_variables";
    .paginate-page {
        position: absolute;
        left: 0px;
        width: 220px;
        display: inline-flex;
        .el-dropdown {
            height: 20px;
            border: revert;
            border: 1px solid var(--select-border);
            background: #F7F8FC;
            color: #4B506D;
            margin-top: 17px;
            width: 45px;
        }
        p {
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #9FA2B4;
            width: 150px;
        }
    }
    .ranking-pagination {
        position: relative;
        .title-paginate {
            position: absolute;
            top: 25px;
            left: 35px;
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #9FA2B4;
        }
    }
    h3 {
        margin: 40px 0 0;
    }
    a {
        color: #42b983;
    }
    .link-back {
        background: #FFF;
        .link-back-list {
            display: table-cell;
            vertical-align: middle;
            height: 37px;
            a {
                font-family: Helvetica;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #303133;
                padding: 0px 20px;
                height: 37px;
            }
            a.active {
                border-bottom: 2px solid #409EFF;
                padding-bottom: 6px;
            }
        }
    }
    .ranking-search {
        margin-top: 20px;
        .title-search {
            font-family: Helvetica;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #2C2C2C;
            margin-bottom: 24px;
        }
    }
    .btn-search {
        background: #409EFF;
        border-radius: 4px;
        border-color: #409EFF;
        width: 95px;
        height: 40px;
    }
    .ranking-list-btn {
        justify-content: flex-end;
        display: flex;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .btn-add {
        background: #67C23A;
        border-radius: 4px;
        border-color: #67C23A;
        font-weight: bold;
    }
    .btn-delete {
        background: #F56C6C;
        border-radius: 4px;
        border-color: #F56C6C;
        font-weight: bold;
    }
    .btn-delete:disabled {
        background: $color_disable_btn;
        border-color: $color_disable_btn;
    }
    .btn-import {
        background: #409EFF;
        border-radius: 4px;
        border-color: #409EFF;
        font-weight: bold;
    }
    .upload-btn-wrapper {
        position: relative;
        display: inline-block;
        input[type=file] {
            width: 95px;
            height: 40px;
            margin-left:12px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }
        .btn-import {
            font-family: Helvetica;
            font-style: normal;
            font-size: 14px;
            line-height: 16px;
            color: #FFF;
            letter-spacing: 0.01em;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 95px;
            height: 40px;
            margin: 0px 12px;
        }
    }
    .btn-export {
        font-weight: bold;
        background: #E6A23C;
        border-radius: 4px;
        border-color: #E6A23C;
    }
    // .el-table {
    //     padding: 0px 20px;
    // }
    .ranking-pagination {
        .el-pagination {
            padding: 20px 0px 10px 0px;
        }
    }
</style>
