<template>
  <div>
    <router-view/>
    <keep-alive >
      <router-view name="wrap_keep_alive" />
    </keep-alive>
  </div>
</template>

<script>
  export default {
    name: 'WrapPage'
  }
</script>

<style scoped>

</style>
