import StatisticByOrganization from "./views/StatisticByOrganization";
import StatisticByCertificate from "./views/StatisticByCertificate";
import StatisticExtractRequest from "./views/StatisticExtractRequest";
import StatisticByGraduationYear from "./views/StatisticByGraduationYear";

const routes = [
    {
        path: "/admin/statistical/organization",
        component: StatisticByOrganization,
        name: 'StatisticByOrganization',
    },
    {
      path: "/admin/statistical/graduation",
      component: StatisticByGraduationYear,
      name: 'StatisticByGraduationYear',
    },
    {
        path: "/admin/statistical/certificate",
        component: StatisticByCertificate,
        name: 'StatisticByCertificate',
    },
    {
        path: "/admin/statistical/extract-request",
        component: StatisticExtractRequest,
        name: 'StatisticViewTracker',
    },
];

export default routes;
