import UserRequest from "@/requests/UserRequest";

const state = {
  user: null,
};

const getters = {
  user(state) {
    return state.user;
  },
};

const actions = {
  async changeUser({commit}, user) {
      commit("SET_USER", user);
  },
  async getUser({commit}) {
    const res = await UserRequest.getAuthInfo();
    commit("SET_USER", res);
  },
  async user({commit}) {
    return state.user
  },
}

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
