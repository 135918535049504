import Request from '@/requests/Request';

class StatisticalRequest extends Request {
    prefix = '/admin/';

    async organizationIndex(data) {
        let url = this.prefix + 'stat-organization';
        return await super.get(url, data);
    }

    async organizationChart(data) {
        let url = this.prefix + 'stat-organization/chart';
        return await super.get(url, data);
    }

    async organizationExport(data) {
        let url = this.prefix + 'export/statistical-origanization';
        return await super.get(url, data, {
          'api-action' : 'ExStatisticalRequest-organizationExport'
        });
    }

  async graduationYearExport(data) {
    let url = this.prefix + 'export/statistical-graduation-year';
    return await super.get(url, data, {
      'api-action' : 'ExStatisticalRequest-graduationYearExport'
    });
  }

  async graduationIndex(data) {
    let url = this.prefix + 'stat-graduation-year';
    return await super.get(url, data);
  }

  async graduationChart(data) {
    let url = this.prefix + 'stat-graduation-year/chart';
    return await super.get(url, data);
  }

  async graduationExport(data) {
    let url = this.prefix + 'export/statistical-graduation';
    return await super.get(url, data, {
      'api-action' : 'ExStatisticalRequest-organizationExport'
    });
  }


    async viewTrackerIndex(data) {
        let url = this.prefix + 'stat-view-tracker';
        return await super.get(url, data);
    }

    async viewTrackerChart(data) {
        let url = this.prefix + 'stat-view-tracker/chart';
        return await super.get(url, data);
    }

    async viewTrackerExport(data) {
        let url = this.prefix + 'export/statistical-view-tracker';
        return await super.get(url, data, {
          'api-action' : 'ExStatisticalRequest-viewTrackerExport'
        });
    }

    async certificateIndex(data) {
        let url = this.prefix + 'stat-qualification';
        return await super.get(url, data);
    }

    async certificateChart(data) {
        let url = this.prefix + 'stat-qualification/chart';
        return await super.get(url, data);
    }

    async certificateExport(data) {
        let url = this.prefix + 'export/statistical-certificate';
        return await super.get(url, data, {
          'api-action' : 'ExStatisticalRequest-certificateExport'
        });
    }

  async getTotalSearch(data) {
    let url = '/search-count';
    return await super.get(url, data);
  }
}

export default StatisticalRequest.getInstance()
