<script>
  import { Switch } from 'element-ui';
  import CONST from '@/config/const';

  export default {
    name: 'CustomElementSwitch',
    extends: Switch,
    props: {
      activeColor: {
        type: String,
        default: '#67C23A'
      },
      inactiveColor: {
        type: String,
        default: '#C0C4CC'
      },
    },
  }
</script>
