<template>
  <div>
    <el-card shadow="never" class="mt3">
      <el-form label-position="left" label-width="120px" :model="search" style="border:none">
        <h4 class="mt0 mb1">Thống kê văn bằng theo năm tốt nghiệp</h4>
      </el-form>
      <h4 class="mt5 mb1">Dữ liệu gốc</h4>
      <el-row type="flex" align="bottom" justify="space-between">
        <div class="el-pagination-size">
          <span>Số bản ghi trên trang:&nbsp;</span>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ searchTable.pageSize }}
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in listPaginate"
                :key="item.key"
                :command="item.value"
              >{{ item.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-button type="warning" @click="handleExport()">Kết Xuất</el-button>
      </el-row>
      <el-row :gutter="40" style="border-top: 1px solid #eee" class="mt2"></el-row>
      <el-table :data="dataTable.data" style="width: 100%" v-loading="loading">
        <el-table-column prop="name" label="Đơn vị cấp bằng"></el-table-column>
        <el-table-column :label="sub_five_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.sub_five_graduation_year_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="sub_four_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.sub_four_graduation_year_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="sub_three_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.sub_three_graduation_year_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="sub_two_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.sub_two_graduation_year_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="sub_one_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.sub_one_graduation_year_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="current_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.current_graduation_year_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="add_one_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.add_one_graduation_year_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="add_two_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.add_two_graduation_year_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="null_year_count" align="right">
          <template slot-scope="scope">{{ scope.row.graduation_year_null_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column label="Tổng" align="right">
          <template slot-scope="scope">
            {{ (scope.row.current_graduation_year_count +
            scope.row.sub_one_graduation_year_count +
            scope.row.sub_two_graduation_year_count +
            scope.row.sub_three_graduation_year_count +
            scope.row.sub_four_graduation_year_count +
            scope.row.sub_five_graduation_year_count +
            scope.row.add_one_graduation_year_count +
            scope.row.graduation_year_null_count +
            scope.row.add_two_graduation_year_count) | formatNumber
            }}
          </template>
        </el-table-column>
      </el-table>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="mt3"
        v-show="dataTable.total"
      >
        <div class="el-pagination-info">
          <span>Hiển thị {{ dataTable.from }}-{{ dataTable.to }} của {{ dataTable.total }} bản ghi</span>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="dataTable.total"
          :current-page="dataTable.current_page"
          :page-size="dataTable.per_page"
          @current-change="handleChangePage"
        ></el-pagination>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import StatisticalRequest from "../api/statistical";
import OrganizationRequest from "../api/organization";
import Highcharts from "highcharts";
import CONST from "@/config/const";
var chart;
export default {
  name: "StatisticByGraduation",
  data() {
    return {
      CONST: CONST,
      loading: false,
      search: {
        organization_id: "",
        organization_name: "",
        time: "month",
        placeholder: "Tất cả",
        degree_authority_unit_type: "",
      },
      dataColumnChart: [],
      dataTable: [],
      listPaginate: CONST.PAGINATION,
      searchTable: {
        pageSize: CONST.PAGINATION[0].value,
        page: 1,
      },
    };
  },
  computed: {
    current_year_count: function () {
      return "Năm " + new Date().getFullYear();
    },
    sub_one_year_count: function () {
      return "Năm " + (new Date().getFullYear() - 1);
    },
    sub_two_year_count: function () {
      return "Năm " + (new Date().getFullYear() - 2);
    },
    sub_three_year_count: function () {
      return "Năm " + (new Date().getFullYear() - 3);
    },
    sub_four_year_count: function () {
      return "Năm " + (new Date().getFullYear() - 4);
    },
    sub_five_year_count: function () {
      return "Năm " + (new Date().getFullYear() - 5);
    },
    add_one_year_count: function () {
      return "Năm " + (new Date().getFullYear() + 1);
    },
    add_two_year_count: function () {
      return "Năm " + (new Date().getFullYear() + 2);
    },
    null_year_count: function () {
      return "Null";
    },
    sub_rest_years_count: function () {
      return "Trước " + (new Date().getFullYear() - 3);
    },
  },
  watch: {
    'search.degree_authority_unit_type': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.placeholder = 'Tất cả';
        this.search.organization_name = '';
      }
    }
  },
  methods: {
    async handleChangePage(page) {
      this.loading = true;
      this.searchTable.page = page;
      try {
        await this.handleDataTable();
      } catch (error) {}
      this.loading = false;
    },
    async autoSelectSearch(name, cb) {
      try {
        let res = await OrganizationRequest.index({
          name: this.search.organization_name,
        });
        let results = res.data.map((e) => {
          return { value: e.name, id: e.id, degree_authority_unit_type: e.degree_authority_unit_type };
        });
        if (this.search.degree_authority_unit_type) {
          results = results.filter((item) => {
            return item.degree_authority_unit_type == this.search.degree_authority_unit_type;
          })
        }
        cb(results);
      } catch (error) {}
    },
    autoSelectFocus() {
      this.search.organization_name = "";
      this.$refs.popup.suggestions = [];
    },
    autoSelectBlur() {
      if (this.search.organization_id) {
        this.search.organization_name = this.search.placeholder;
      }
    },
    autoSelectChoice(item) {
      this.search.organization_id = item.id;
      this.search.placeholder = item.value;
      this.search.organization_name = item.value;
    },
    autoSelectClear() {
      this.search.organization_id = "";
      this.search.placeholder = "Tất cả";
    },
    async handleDataTable() {
      let res = await StatisticalRequest.graduationIndex(this.searchTable);
      this.dataTable = res.data;
    },
    renderChart() {
      chart.series[0].setData(this.dataColumnChart.map((item) => item.count));
      chart.xAxis[0].setCategories(
        this.dataColumnChart.map((item) => item.name)
      );
    },
    async handleCommand(command) {
      this.loading = true;
      try {
        this.searchTable.page = 1;
        this.searchTable.pageSize = command;
        await this.handleDataTable();
      } catch (error) {}
      this.loading = false;
    },
    async handleExport() {
      if (!this.checkLimitExport(this.dataTable.total)) {
        this.loading = true;
        try {
          let res = await StatisticalRequest.graduationYearExport();
          window.location.assign(res);
        } catch (error) {}
        this.loading = false;
      }
    },
  },
  async created() {
    this.loading = true;
    try {
      await Promise.all([this.handleDataTable()]);
    } catch (error) {}
    this.loading = false;
  },
  mounted() {
  },
};
</script>
