import Request from './Request';
import CONST from "@/config/const";

class DegreeCooperatorRequest extends Request {
  async importDegree(formData) {
    let url = '/co-op/degree/upload-draft';
    window.source = window.axios.CancelToken.source();
    return await this.post(url, formData, {
      'Content-Type': 'multipart/form-data',
      'api-action': 'ImDegreeCooperatorRequest-import'
    }, {
      cancelToken: window.source.token
    });
  }

  async getDegree(params) {
    let url = '/co-op/degree/get-draft';
    const result = await this.get(url, params);
    return result.data;
  }

  async getUploadStatus(params) {
    let url = '/co-op/degree/upload-status';
    const result = await this.get(url, params);
    return result.data;
  }

  async exportDegree(params) {
    let url = '/co-op/degree/export';
    return await this.get(url, params, {'api-action': 'ExDegreeCooperatorRequest-export'}, {
      responseType: 'blob'
    });
  }

  async sendDegree(params, total) {
    let url = '/co-op/degree/send-to-ministry';
    return await this.post(url, params, {
      'api-action': 'DegreeCooperatorRequest-send',
      'api-action-meta': total,
    });
  }

  async deleteDegree(params, total) {
    let url = '/co-op/degree/delete';
    return await this.delete(url, params, {
      'api-action': 'DegreeCooperatorRequest-delete',
      'api-action-meta': total,
    });
  }

  async getDegreeResult(params) {
    let url = '/co-op/degree/get-degree';
    const result = await this.get(url, params);
    return result.data;
  }

  async getDetailDegree(id) {
    let url = '/co-op/degree/get-degree/detail?id=' + encodeURIComponent(id);
    const result = await this.get(url);
    return result.data;
  }

  async getDraftDetailDegree(id) {
    let url = '/co-op/degree/get-draft-detail-degree/' + id;
    const result = await this.get(url);
    return result.data;
  }

  async getOrganizations(params) {
    let url = '/degree/organization-list';
    return await this.get(url, params);
  }
  async getByCode(params) {
    let url = '/degree/get-by-code/';
    const result = await this.get(url, params);
    return result.data;
  }
  async getByUnit(params) {
    let url = '/degree/get-by-unit/';
    const result = await this.get(url, params);
    return result.data;
  }
  async getDetailByCode(code) {
    let url = 'degree/get-detail-by-code?code=' + encodeURIComponent(code);
    const result = await this.get(url, {}, {
      'api-action': 'StatisticRequest-tracker',
      'api-action-meta': code
    });
    return result.data;
  }

  async getDegreeError(params) {
    let url = '/co-op/degree/get-error';
    const result = await this.get(url, params);
    return result.data;
  }

  async exportDegreeError(params) {
    let url = '/co-op/degree/export-error';
    return await this.get(url, params, {'api-action': 'ExDegreeCooperatorRequest-exportError'}, {
      responseType: 'blob'
    });
  }

  async deleteDegreeError(params, total) {
    let url = '/co-op/degree/delete-error';
    return await this.delete(url, params, {
      'api-action': 'DegreeErrorCooperatorRequest-delete',
      'api-action-meta': total,
    });
  }

  async exportDegreeResult(params, type) {
    let url = '/co-op/degree/export-result';
    let action = '';
    switch (type) {
      case CONST.DEGREE_TYPE_PENDING:
        action = 'ExDegreeCooperatorRequest-exportPending';
        break;
      case CONST.DEGREE_TYPE_APPROVED:
        action = 'ExDegreeCooperatorRequest-exportApprove';
        break;
      default:
        action = 'ExDegreeCooperatorRequest-exportReject';
    }
    return await this.get(url, params, {'api-action': action}, {
      responseType: 'blob'
    });
  }

  async updateUploadingStatus() {
    let url = '/co-op/degree/create-uploading-status';
    return await this.post(url);
  }

  async updateErrorStatus(id) {
    let url = '/co-op/degree/update-error-status';
    return await this.put(url);
  }

  async downloadTemplate(params) {
    let url = '/co-op/degree/download-template';
    return await this.get(url, params, {}, {
      responseType: 'blob'
    });
  }

  async exportError(params) {
    let url = '/co-op/degree/download-template-error';
    return await this.get(url, params, {}, {
      responseType: 'blob'
    });
  }

  async getOrganizationCertificatesForUser() {
    let url = '/co-op/degree/organization-certificates-for-user';
    return await this.get(url);
  }

}

export default DegreeCooperatorRequest.getInstance()
