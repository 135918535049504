<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'RoleManagement' }">Quản lý vai trò</el-breadcrumb-item>
      <el-breadcrumb-item>{{ roleId ? 'Chỉnh sửa' : 'Thêm mới'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="role" :ref="formName" :rules="rules" class="demo-ruleForm">
      <el-row class="new-role-name">
        <el-form-item label="Vai trò" prop="name">
          <el-input type="text" v-model="role.name" :disabled="isRoleSuperAdminId" maxlength="250"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-table :data="allPermissions" style="width: 100%">
          <el-table-column label="Mô đun" width="250px">
            <template slot-scope="scope">
              <span :class="{'padding-20': scope.row.parent_id}">{{scope.row.unicode_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150px"
            align="center"
            :label="item.name"
            :key="item.id"
            v-for="item in tableHead"
          >
            <template slot-scope="scope">
              <el-checkbox-group
                v-model="role.selectedPermissionIds"
                v-if="!isPermissionParent(scope.row.id)"
              >
                <template
                  v-for="grantedPermission in scope.row.permissions"
                >
                  <template v-if="grantedPermission.is_removed == 1">
                    <el-checkbox
                      :key="grantedPermission.id"
                      :label="grantedPermission.id"
                      :value="grantedPermission.id"
                      style="display: none"
                      v-if="item.id === grantedPermission.permission"
                    ></el-checkbox>
                  </template>
                  <template
                    v-else-if="!isRoleSuperAdminId && !isDisableReadPermission(grantedPermission.permission, grantedPermission.id)"
                  >
                    <el-checkbox
                      :key="grantedPermission.id"
                      :label="grantedPermission.id"
                      :value="grantedPermission.id"
                      v-if="item.id === grantedPermission.permission"
                      @change="triggerReadPermission(grantedPermission.permission, grantedPermission.id)"
                    ></el-checkbox>
                  </template>
                  <template v-else>
                    <el-checkbox
                      :key="grantedPermission.id"
                      :label="grantedPermission.id"
                      :value="grantedPermission.id"
                      disabled
                      v-if="item.id === grantedPermission.permission"
                    ></el-checkbox>
                  </template>
                </template>
              </el-checkbox-group>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-form>
    <div class="button-area">
      <el-button
        class="btn"
        type="danger"
        @click="deleteRole"
        v-if="!isRoleSuperAdminId && roleId"
        :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)"
      >Xóa</el-button>
      <el-button
        class="btn"
        type="danger"
        @click="deleteRole"
        v-else-if="isRoleSuperAdminId && roleId"
        disabled
      >Xóa</el-button>
      <el-button
        type="primary"
        @click="resetRole"
        plain
        :disabled="isRoleSuperAdminId || hasPermissionOnScreen(PERMISSION_UPDATE, this)"
        v-if="roleId"
      >Đặt lại</el-button>
      <el-button
        type="primary"
        @click="saveRole"
        v-if="!isRoleSuperAdminId && roleId"
        :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
      >Lưu</el-button>
      <el-button
        type="primary"
        @click="saveRole"
        v-else-if="!isRoleSuperAdminId"
        :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)"
      >Lưu</el-button>
      <el-button type="primary" @click="saveRole" disabled v-else>Lưu</el-button>
    </div>
  </div>
</template>

<script>
import RoleManagementRequest from "@/requests/RoleManagementRequest";
import _ from "lodash";
import CONST from "@/config/const";
// import AdminMenuRequest from '@/requests/AdminMenuRequest';

export default {
  name: "RoleManagement",
  props: {
    msg: String,
  },
  data: function () {
    return {
      role: { name: "", selectedPermissionIds: [] },
      roleId: null,
      allPermissions: [],
      roleOrigin: [],
      groupChildPermissionIds: [],
      tableHead: [
        { id: "r", name: "Đọc" },
        { id: "c", name: "Ghi" },
        { id: "u", name: "Sửa" },
        { id: "d", name: "Xóa" },
      ],
      formName: "roleValidateForm",
      isRoleSuperAdminId: false,
      rules: {
        name: [
          {
            required: true,
            message: "Vui lòng nhập vai trò.",
          },
          {
            required: true,
            validator: this.checkExistName,
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  methods: {
    async getSelectedPermission() {
      const role = await RoleManagementRequest.getPermissionsByRoleId(
        this.roleId
      );
      this.roleOrigin = _.clone(role);
      this.role.name = role.role;
      this.role.selectedPermissionIds = role.permission_ids;
    },
    async deleteRole() {
      if (!this.roleId) {
        return;
      }
      this.$confirm("Bạn có chắc chắn xóa vai trò này không?", "Xóa vai trò", {
        confirmButtonText: "Có",
        cancelButtonText: "Không",
        type: "warning",
      })
        .then(() => {
          RoleManagementRequest.deleteRole(this.roleId).then((response) => {
            if (response.success) {
              this.backToRoleManagement();
              this.showSuccess("Xóa thành công.");
            }
          });
        })
        .catch(() => {});
    },
    backToRoleManagement() {
      this.$router.push({ name: "RoleManagement" });
    },
    resetRole(formName) {
      this.$refs[this.formName].resetFields();
      if (this.roleId) {
        this.role.selectedPermissionIds = this.roleOrigin.permission_ids;
        this.role.name = this.roleOrigin.role;
        return;
      }
      this.initRole();
    },
    initRole() {
      this.role.name = "";
      this.role.selectedPermissionIds = [];
    },
    async saveRole() {
      let valid = await this.$refs[this.formName].validate();
      if (!valid) {
        return;
      }
      let params = {
        role: this.role.name,
        permission_ids: this.role.selectedPermissionIds,
        guard_name: "api",
      };
      let response;
      let message;
      try {
        if (this.roleId) {
          response = await RoleManagementRequest.updateRole({
            ...params,
            ...{ id: this.roleId },
          });
          message = "Cập nhật vai trò thành công.";
        } else {
          response = await RoleManagementRequest.createRole(params);
          message = "Thêm mới vai trò thành công.";
        }
        if (response.success) {
          this.showSuccess(message);
          this.backToRoleManagement();
        }
      } catch (error) {
        const messageError = error.response.data.message;
        if (messageError === "role_exists") {
          this.showError("Vai trò đã tồn tại.");
        } else if (messageError === "empty_permission") {
          this.showError("Vui lòng chọn ít nhất một chức năng cho vai trò.");
        }
      }
    },
    async getPermissions() {
      this.allPermissions = await RoleManagementRequest.getPermissions();
      this.allPermissionsOrigin = _.clone(this.allPermissions);
      _.forEach(this.allPermissions, (permission) => {
        const readChildPermission = _.first(
          _.filter(permission.permissions, (subPermission) => {
            return subPermission.permission === "r";
          })
        );
        const groupChildPermissionIds = _.map(
          _.filter(permission.permissions, (subPermission) => {
            return subPermission.permission !== "r";
          }),
          "id"
        );
        this.groupChildPermissionIds[
          readChildPermission.id
        ] = groupChildPermissionIds;
      });
    },
    getPermissionsfromSubMenu(permissions) {
      let list = [];
      _.forEach(permissions, (permission) => {
        list[permission.id] = permission.permission;
      });
      return list;
    },
    isPermissionParent(id) {
      return _.map(this.allPermissions, "parent_id").indexOf(id) !== -1;
    },
    triggerReadPermission(permissionName, permissionId) {
      if (["c", "u", "d"].indexOf(permissionName) !== -1) {
        _.forEach(
          this.groupChildPermissionIds,
          (groupPermissionIds, readPermissionId) => {
            if (
              !_.isEmpty(groupPermissionIds) &&
              groupPermissionIds !== undefined &&
              groupPermissionIds.indexOf(permissionId) !== -1 &&
              this.role.selectedPermissionIds.indexOf(readPermissionId) === -1
            ) {
              this.role.selectedPermissionIds.push(readPermissionId);
              return;
            }
          }
        );
      }
    },
    isDisableReadPermission(permissionName, permissionId) {
      let isDisable = false;
      if (
        permissionName === this.PERMISSION_READ &&
        this.role.selectedPermissionIds.indexOf(permissionId) !== -1
      ) {
        _.forEach(
          this.groupChildPermissionIds,
          (groupPermissionIds, readPermissionId) => {
            if (permissionId === readPermissionId) {
              isDisable = !_.isEmpty(
                _.intersection(
                  this.role.selectedPermissionIds,
                  groupPermissionIds
                )
              );
              return;
            }
          }
        );
      }
      return isDisable;
    },
    checkExistName(rule, value, callback) {
      RoleManagementRequest.checkExist({
        role: this.role.name,
        id: this.roleId,
      })
        .then((res) => {
          if (res.data) {
            callback(new Error("Vai trò đã tồn tại."));
            return;
          }
          callback();
        })
        .catch(() => {
          callback();
        });
    },
  },
  created() {
    this.getPermissions();
    this.roleId = this.$route.params.id || 0;
    if (this.roleId) {
      this.isRoleSuperAdminId = this.roleId == CONST.SUPER_ADMIN_ID;
      this.getSelectedPermission();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.box {
  margin-top: 32px;
  margin-right: 30px;
  border: 1px solid #ebeef5;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.button-area {
  margin-top: 20px;
  .el-button {
    width: 95px;
    height: 40px;
  }
}
.padding-20 {
  padding-left: 20px;
}
.new-role-name {
  background-color: #ffffff;
  padding: 20px 20px 20px;
}
/deep/.el-input {
  width: 35%;
  margin-left: 70px;
}

.demo-ruleForm {
  border: 0px;
}
/deep/.el-form-item__error {
  padding-left: 110px !important;
}

/deep/.el-table {
  .el-form-item__error {
    float: right;
    font-size: 15px;
  }
}

.error-permission-item {
  float: right;
}
.clearfix {
  float: clear;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #2c2c2c;
}

/deep/.el-form-item {
  margin-bottom: 10px;
}

/deep/.el-checkbox__label {
  display: none !important;
}

.new-role-name /deep/.el-form-item__content {
  margin-left: 0px !important;
}
.demo-ruleForm /deep/.el-form-item__label{
  text-align: left;
  width: 110px !important;
}
.demo-ruleForm /deep/.el-input{
  margin-left: 0;
}
</style>
