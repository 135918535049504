import DegreeRequest from "../api/DegreeRequest";

const Rule = {
    name: [
        {
            required: true,
            trigger: "blur",
            message: "Vui lòng nhập trình độ.",
        },
    ],
    code: [
        {
            required: true,
            trigger: "blur",
            message: "Vui lòng nhập mã.",
        },
        {
            pattern: /^[a-zA-Z0-9]+$/,
            trigger: "blur",
            message:
                "Mã chỉ chứa chữ cái tiếng Anh và chữ số.",
        },
        {
            min: 1,
            max: 8,
            trigger: "blur",
            message: "Vui lòng nhập tối đa 8 ký tự.",
        },
        { validator: checkExitCode, trigger: 'blur' },
    ],
};


async function checkExitCode(rule, value, callback) {
    if (value) {
        let res = await DegreeRequest.checkExitCode({ code: value, id: window.app.$route.params.id });
        if (res.data) {
            callback(new Error('Mã đã tồn tại.'));
        }
    }
    callback();
}

async function checkExitName(rule, value, callback) {
    if (value) {
        let res = await DegreeRequest.checkExitName({ name: value, id: window.app.$route.params.id });
        if (res.data) {
            callback(new Error('Tên trình độ đã tồn tại.'));
        }
    }
    callback();
}

export default Rule;
