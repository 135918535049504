<template>
  <el-row class="national-management">
    <!-- Form Search -->
    <el-col :span="24" class="group-search">
      <el-form :model="search" label-position="left">
        <p class="title-search">Quản lý Quốc tịch</p>
        <el-row>
          <el-col :span="11">
            <el-form-item label="Mã" class="label-custom">
              <el-input
                  maxlength='8'
                  @keyup.enter.native="searchData()"
                  v-model="search.code"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="Quốc tịch">
              <el-input
                  @keyup.enter.native="searchData()"
                  v-model="search.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="16" class="button-search">
            <el-form-item>
              <el-button @click="searchData()" type="primary" class="btn">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <!-- Button Action -->
    <el-col class="list-button">
      <div class="pagination-size">
        <p>Số bản ghi trên trang: </p>
        <el-dropdown @command="handleSelected">
					<span class="el-dropdown-link">
						{{ search.limit }}
						<i class="el-icon-caret-bottom"></i>
					</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="item in listPaginate"
                :key="item.key"
                :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div>
        <el-button type="success" class="btn" @click="gotoLink({name: 'nation.create'})" :disabled="hasPermissionOnScreen(PERMISSION_CREATE)">Thêm Mới</el-button>
        <el-button type="danger" class="btn" @click="deleteAll()" disabled v-show="!this.multipleSelection.length">Xóa</el-button>
        <el-button type="danger" class="btn" @click="deleteAll()" v-show="this.multipleSelection.length" :disabled="hasPermissionOnScreen(PERMISSION_DELETE)">Xóa</el-button>
      </div>
    </el-col>

    <el-card shadow="never">
      <el-table
            v-loading="loading"
            :data="dataTable"
            style="width: 100%"
            ref="datatable"
            @selection-change="handleSelectionChange">
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column property="code" label="Mã"></el-table-column>
          <el-table-column property="name" label="Quốc tịch"></el-table-column>
          <el-table-column label="Tình trạng">
            <template slot-scope="scope">
              <el-switch
                  @change="handleStatus(scope.row, $event)"
                  v-model="scope.row.status"
                  :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template slot-scope="scope">
              <el-button
                  @click="handleEdit(scope.row)"
                  type="info"
                  icon="el-icon-edit"
                  size="mini"
                  circle
                  :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
              ></el-button>
              <el-button
                  @click="delData(scope.row)"
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  circle
                  :disabled="hasPermissionOnScreen(PERMISSION_DELETE)"
              ></el-button>
            </template>
          </el-table-column>
      </el-table>
      <custom-el-pagination
        :length="data.total || 0"
        :paginateFrom="data.from || 1"
        :paginateTo="data.to || 25"
        :total="data.total || 0"
        :pageSize="data.per_page || 25"
        :page="data.current_page || 1"
        @handleCurrentChange="handleChangePage"
      ></custom-el-pagination>
    </el-card>
  </el-row>
</template>

<script>
import NationalRequest from "@/requests/NationalRequest";
import CONST from "@/config/const";
export default {
  name: "NationalManager",
  data() {
    return {
      loading: false,
      search: {
        name: "",
        code: "",
        limit: CONST.PAGINATION[0].value,
        page: 1
      },
      paramsSearch: {},
      data: [],
      dataTable: [],
      sortParams: [],
      multipleSelection: [],
      listPaginate: CONST.PAGINATION,
    };
  },

  methods: {
    getData(params = '') {
      NationalRequest.getList(params)
          .then((res) => {
            if (res.data.data.length === 0 && this.search.page != 1) {
              params.page = this.search.page - 1;
              this.getList(params);
            }
            this.data = res.data;
            this.dataTable = this.formatDatatable(res.data.data)
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    searchData() {
      this.loading = true;
      this.paramsSearch = {...this.search}
      this.getData(this.paramsSearch)
    },

    handleSelected(limit) {
      this.search.limit = limit
      this.search.page = 1
      this.searchData();
    },

    handleEdit(row) {
      this.$router.push({ name: "nation.edit", params: { id: row.id } });
    },

    handleChangePage(page) {
      this.loading = true;
      this.search.page = page;
      let param = { ...this.paramsSearch, page: page };
      this.getData(param);
    },

    async delData(params) {
      let checkConfirm = await this.showConfirmMixin();
      if (checkConfirm) {
        NationalRequest.destroy(params.id, params.code).then(() => {
          this.showSuccess('Xóa thành công.')
          this.getData(this.search);
        }).catch(() => {
          this.showError('Lỗi khi xóa.');
        });
      }
    },

    async deleteAll() {
      let checkConfirm = await this.showConfirmMixin();
      if (checkConfirm) {
        NationalRequest.destroyAll(this.multipleSelection).then(() => {
          this.showSuccess('Xóa thành công.')
          this.getData(this.search);
        }).catch(() => {
          this.showError('Lỗi khi xóa.');
        });
      }
    },

    handleSelectionChange() {
      const multiData = this.$refs.datatable ? this.$refs.datatable.selection : '';
      this.multipleSelection = _.map(multiData, (data) => {
        return data.id;
      })
    },

    handleStatus(row, status) {
      NationalRequest.edit(row.id, row).then(() => {
        this.showSuccess('Thành công.');
      }).catch(() => {
        this.showError("Thất bại.");
        row.status = !status
      });
    },

    formatDatatable(data)
    {
      return _.map(data, (item) => {
        item.status = item.status ? true : false;
        return item;
      })
    }
  },
  created() {
    this.loading = true;
    this.paramsSearch = {...this.search}
    this.getData(this.search);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/style";
.button-group {
  margin-top: 30px;
  margin-bottom: 20px;
}
.national-management {
  .btn-custom {
    width: 95px;
    height: 40px;
    &:first-child{
      margin-right: 10px;
    }
    &:last-child{
      margin-left: 10px;
    }
  }
  .form-custom {
    margin-top: 24px;
    padding: 17px;
    .custom-input {
      width: 382px;
      height: 39px;
    }
    .fl-right {
      float: right;
    }
  }
  .clear-both {
    clear: both;
  }
  .label-custom {
    /deep/.el-form-item__label {
      text-align: left !important;
    }
  }
}
</style>
