import MasterdataUtils from '../common/MasterdataUtils';
import Request from './Request';

class MasterdataRequest extends Request {

  async getAll() {
    let data = MasterdataUtils.getCachedMasterdata();
    if (!data) {
      const url = '/masterdata';
      const res = await this.get(url);
      MasterdataUtils.saveMasterdata(res.dataVersion, res.data);
      MasterdataUtils.getCachedMasterdata();
      return res.data;
    }
    return data;
  }

  find(table, id) {
    return new Promise((resolve) => {
      this.getAll()
        .then((data) => {
          const record = data[table].find((row) => parseInt(row.id) === parseInt(id));
          return resolve(record);
        });
    });
  }

  getTable(api, params) {
    const url = '/masterdata';
    if (!params) {
      params = {};
    }
    params._table = api;
    return this.get(url, params);
  }
}

export default MasterdataRequest.getInstance()
