<template>
  <div>
    <el-card class="box-card">
      <div class="card">
        <p><i class="el-icon-circle-check"></i></p>
        <p class="text-1">THAY ĐỔI MẬT KHẨU</p>
        <p class="text-2">Mật khẩu của bạn đã được thay đổi thành công</p>
        <p>
          <el-button type="primary" class="btn-custom" @click="$router.push({name: 'login'})">Xác Nhận</el-button>
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ConfirmPassword"
}
</script>

<style lang="scss" scoped>
.card {
  padding: 68px;
  width: 483px;
  height: 289px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(68, 68, 68, 0.1);
  /deep/ .el-icon-circle-check {
    color: #67C23A;
    font-size: 45px;
  }
  .text-1 {
    font-size: 18px;
    line-height: 21px;
    color: #363740;
    margin-top: 33px;
  }
  .text-2 {
    font-size: 14px;
    line-height: 16px;
    color: #909399;
  }
  .btn-custom {
    margin-top: 30px;
    width: 480px;
    height: 50px;
    background: #409EFF;
    border: 1px solid #409EFF;
    border-radius: 5px;
    font-size: 17px;
  }
}
.box-card {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../assets/background.jpg") no-repeat center fixed;
  background-position: bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
p {
  text-align: center;
}
</style>