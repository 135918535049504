<template>
  <el-row type="flex" justify="space-between" align="middle" class="custom-pagination mt2" v-if="length > 0">
    <div class="title-paginate">
      Hiển thị {{paginateFrom || 1}}-{{paginateTo || 25}} của {{total || 0}} bản ghi
    </div>
    <el-pagination
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :page-size="pageSize || 25"
      :total="total || 0"
      :current-page="page || 0">
    </el-pagination>
  </el-row>

</template>

<script>
  import { Pagination } from 'element-ui';
  import CONST from '@/config/const';

  export default {
    name: 'CustomElementPagination',
    extends: Pagination,
    props: { 
      length: { required: true, default: 0 },
      paginateFrom: { required: true, default: 1 },
      paginateTo: { required: true, default: CONST.PAGINATION[0].value },
      total: { required: true, default: 0 },
      pageSize: { required: true, default: CONST.PAGINATION[0].value },
      page: { required: true, default: 0 },
    },
    methods: {
      handleCurrentChange (val) {
        this.$emit('handleCurrentChange', val)
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/_variables";
  .custom-pagination {
    ul.el-pager {
      list-style-type: none;
      padding: 0;
      li {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #606266;
        padding: 0px 15px;
      }
      li.active {
        color: #1989FA;
      }
    }
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .custom-pagination {
    position: relative;
    .title-paginate {
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.3px;
      color: #9FA2B4;
    }
  }
</style>
