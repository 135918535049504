import Organization from "@/pages/Admin/Organizations/OrganizationList";
import OrganizationCreateUpdate from "@/pages/Admin/Organizations/OrganizationCreateUpdate";
import OrganizationImport from "@/pages/Admin/Organizations/OrganizationImport";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteOrganization = [
    {
      path: '/admin/organizations',
      component: WrapPage,
      name: 'Organization',
      children: [
        {
          path: '',
          name: 'Organization',
          component: Organization,
        },
        {
          path: 'create-update',
          name: 'OrganizationCreateUpdate',
          component: OrganizationCreateUpdate,
        },
        {
          path: 'import',
          name: 'OrganizationImport',
          component: OrganizationImport,
        }
      ],
    },
];

export default RouteOrganization;
