import Request from './Request';

class LanguageRequest extends Request {
    async getListLanguage(params) {
        let url = '/admin/languages/list';
        return await this.get(url, params);
    }
    async updateStatusLanguage(params) {
        let url = '/admin/languages/update-status';
        return await this.post(url, params);
    }
    async deleteLanguage(id) {
        let url = '/admin/languages/'+ id;
        return await this.delete(url);
    }
    async deleteMultiLanguage(params) {
        let url = '/admin/languages/delete';
        return await this.post(url, params);
    }
    async createLanguage(params) {
        let url = '/admin/languages/create';
        return await this.post(url, params);
    }
    async showLanguage(id) {
        let url = '/admin/languages/'+id;
        return await this.get(url);
    }
    async updateLanguage(params, id) {
        let url = '/admin/languages/'+id;
        return await this.put(url, params);
    }
    async checkCode(params) {
        let url = '/admin/languages/check-code';
        return await this.post(url, params);
    }
}
export default LanguageRequest.getInstance()
