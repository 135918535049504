<template>
  <div>
    <div v-if="linkExpired">
      <el-card class="box-card">
        <div class="card">
          <p>
            <i class="el-icon-expired"></i>
          </p>
          <p class="text-1">ĐỊA CHỈ TRUY CẬP ĐÃ HẾT HẠN</p>
          <p>
            <el-button
              type="primary"
              class="btn-custom"
              @click="$router.push({name: 'login'})"
            >Xác Nhận</el-button>
          </p>
        </div>
      </el-card>
    </div>
    <div class="changePass" v-else>
      <el-card>
        <router-link to="/login">
          <el-button class="back" icon="el-icon-back"></el-button>
        </router-link>

        <h3>NHẬP MẬT KHẨU</h3>
        <h5>Nhập mật khẩu mới</h5>
        <el-form
          class="forgot"
          :model="model"
          :rules="rules"
          ref="form"
          label-width="0px"
          @submit.native.prevent="updatePassword"
        >
          <el-form-item class="newpass" label="Mật khẩu mới" prop="newPassword" label-width="10">
            <el-input v-model="model.newPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item prop="reNewPassword" label="Xác nhận mật khẩu" label-width="10">
            <el-input v-model="model.reNewPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              class="login-button"
              type="primary"
              native-type="submit"
              block
            >Xác Nhận</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import UserRequest from "@/requests/UserRequest";
import AuthenticationStore from "@/requests/AuthenticationStore";

const INVALID_TOKEN = "invalid_token";
export default {
  name: "changepass",
  data() {
    var checkSamePassword = (rule, value, callback) => {
      if (this.model.newPassword && value !== this.model.newPassword) {
        callback(new Error("Mật khẩu xác nhận phải trùng với mật khẩu mới."));
      } else {
        callback();
      }
    };
    return {
      model: {
        key: this.$route.query.key,
        newPassword: "",
      },
      loading: false,
      rules: {
        newPassword: [
          {
            message: "Vui lòng nhập mật khẩu mới.",
            trigger: "blur",
          },
          {
            required: true,
            message: "Vui lòng nhập mật khẩu mới.",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*(),.?":{}|<>\d]{8,50}$/,
            message:
              "Mật khẩu mới phải từ 8-50 ký tự, phải có ít nhất 1 chữ cái viết hoa, 1 chữ cái thường và 1 chữ số.",
            trigger: "blur",
          },
        ],
        reNewPassword: [
          {
            required: true,
            message: "Vui lòng nhập xác nhận mật khẩu mới.",
            trigger: "blur",
          },
          // {
          //   required: true,
          //   pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,50}$/,
          //   message:
          //     "Mật khẩu mới phải từ 8-50 ký tự, bao gồm chữ hoa, chữ thường và số.",
          //   trigger: "blur",
          // },
          {
            required: true,
            validator: checkSamePassword,
            trigger: "blur",
          },
        ],
      },
      linkExpired: false,
    };
  },
  created() {
    const key = this.$route.query.key || "";
    UserRequest.validExpired({ key: key })
      .then((res) => {
        this.linkExpired = !res;
      })
      .catch((err) => {
        if (err.response.data.message === INVALID_TOKEN) {
          this.linkExpired = true;
        }
      });
  },
  methods: {
    async updatePassword() {
      let valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      try {
        await UserRequest.updatePassword(this.model);
        this.showSuccess("Mật khẩu của bạn đã được thay đổi thành công.");
        this.$router.push({ name: "ConfirmPassword" });
      } catch (err) {
        if (err.response.data.message === INVALID_TOKEN) {
          this.linkExpired = true;
        }
      }
    },
  },
  mounted() {
    if (AuthenticationStore.isAuthenticated()) {
      this.$router.push({ name: "UserInfo" });
    }
  },
};
</script>

<style lang="scss">
.changePass .el-card {
  .el-card__body {
    position: relative;
    padding: 48px 69px 65px;
    .back {
      position: absolute;
      top: 16px;
      left: 18px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 26px;
      color: #909399;
      padding: 0px;
    }
    .back:hover {
      background: #fff;
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.newpass {
  margin-bottom: 30px;
}
.card {
  padding: 68px;
  width: 483px;
  height: 289px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(68, 68, 68, 0.1);
  /deep/ .el-icon-circle-check {
    color: #67c23a;
    font-size: 45px;
  }
  .text-1 {
    font-size: 18px;
    line-height: 21px;
    color: #363740;
    margin-top: 33px;
  }
  .text-2 {
    font-size: 14px;
    line-height: 16px;
    color: #909399;
  }
  .btn-custom {
    margin-top: 30px;
    width: 480px;
    height: 50px;
    background: #409eff;
    border: 1px solid #409eff;
    border-radius: 5px;
    font-size: 17px;
  }
}
.box-card {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../assets/background.jpg") no-repeat center fixed;
  background-position: bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
p {
  text-align: center;
}
.el-icon-expired {
  &:before {
    content: url("../assets/images/icons/expired.svg");
  }
}
form.forgot {
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #363740;
    margin-bottom: 16px;
  }
}
.changePass {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../assets/background.jpg") no-repeat center fixed;
  background-position: bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-button {
  width: 100%;
  margin-top: 23px;
  background: #409eff;
  height: 50px;
  border: 1px solid #409eff;
  border-radius: 5px;
}

.forgot-password {
  margin-top: 10px;
  float: left;
  color: #409eff;
  text-align: right;
  width: 526px;
}

$teal: rgb(0, 124, 137);

.back {
  border: none;
  &:hover {
    background: none;
    cursor: pointer;
  }
  padding-bottom: 0px;
}
.changePass .el-input__inner:hover {
  border-color: $teal;
}
.changePass .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;
  .el-input__icon {
    width: 30px;
  }
}
// .login .el-input input {
//   padding-left: 35px;
// }
.el-form {
  border: none;
}
.changePass .el-card {
  padding-top: 0;
}
h3 {
  align-items: center;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20.7px;
  color: #363740;
  display: flex;
  justify-content: center;
  padding-bottom: 0px;
  margin: 0px;
}
h5 {
  margin: 15px 0px 56px;
  align-items: center;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16.1px;
  color: #909399;
  display: flex;
  justify-content: center;
}
.changePass .el-card {
  width: 618px;
  height: 502px;
  justify-content: center;
}
.el-form-item__content {
  width: 526px;
  float: left;
}
</style>

