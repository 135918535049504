import Search from "./views/Search";
import Create from "./views/Create";
import Edit from "./views/Edit";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteCertificate = [
  {
    path: "/admin/certificates",
    component: WrapPage,
    name: 'CertificateMangement',
    children: [
      {
        path: '',
        component: Search,
        name: 'CertificateMangement'
      },
      {
        path: "create",
        component: Create,
        name: 'CertificateMangementCreate'
      },
      {
        path: ":id/edit",
        component: Edit,
        name: 'CertificateMangementEdit'
      }
    ],
  },
];

export default RouteCertificate;

