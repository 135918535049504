<template>
  <div id="search-degree">
    <app-header></app-header>
      <el-row class="row">
        <el-col :span="18" :offset="3" :class="className">
          <degree-detail class="scroll-custom degree-detail-scroll" user="anonymous"></degree-detail>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import Header from "@/pages/SearchHeader";
import moment from 'moment';

export default {
  components: {
    "app-header": Header,
  },
  data(){
    return {
    }
  },
  computed: {
    className() {
      let result = 'detail-degree-content';
      if(this.$route.name === 'SearchDegreeDetail'){
        return result + ' mobile_device'
      }
      return result;
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/_variables";
  .row {
    margin-top: 120px;
  }
  .scroll-custom {
    /*margin-bottom: 200px;*/
  }
  .degree-detail-scroll.scroll-custom {
    max-height: 100%;
  }
  /*@media only screen and (max-width:1366px) {*/
  /*  .scroll-custom {*/
  /*    margin-bottom: 300px;*/
  /*  }*/
  /*}*/
</style>

<style lang="scss">
@import "@/assets/sass/_variables";
  #search-degree {
    .detail-degree-content {
      margin-bottom: 35px;
    }
    overflow-y: scroll;
  }
  @media only screen and (max-width:768px) {
    .mobile_device{
      width: 100%;
      margin-left: 0;
    }
  }
</style>
