import Request from './Request';

class DepartmentRequest extends Request {
    prefix = '/admin/department-management/';

    async getList(params) {
        let url = this.prefix + 'index';
        return await this.get(url, params);
    }

    async create(params) {
        let url = this.prefix + 'create';
        return await this.post(url, params, {
            'api-action': 'DepartmentRequest-create',
            'api-action-meta': params.code
        });
    }

    async show(id) {
        let url = this.prefix + id;
        return await this.get(url);
    }

    async edit(id, params) {
        let url = this.prefix + id;
        return await this.put(url, params, {
            'api-action': 'DepartmentRequest-edit',
            'api-action-meta': params.code
        });
    }

    async destroy(id, code) {
        let url = this.prefix + id;
        return await this.delete(url, {}, {
            'api-action': 'DepartmentRequest-delete'
        });
    }

    async destroyAll(params) {
        let url = this.prefix + 'delete-all';
        return await this.post(url, params, {
            'api-action': 'DepartmentRequest-deleteAll',
            'api-action-meta': params.length
        });
    }

    async import(params) {
        let url = this.prefix + 'export';
        return await this.get(url, params);
    }

    async checkExist(params) {
      let url = this.prefix + 'check/code';
      return await this.get(url, params);
    }
}

export default DepartmentRequest.getInstance()
