<template>
  <div id="email-settings">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>Quản lý hệ thống</el-breadcrumb-item>
      <el-breadcrumb-item>Email</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form class="box" ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item prop="email" label="Cấu hình email">
        <el-input class="input-form" v-model="form.email"></el-input>
        <div class="el-form-item__error"></div>
      </el-form-item>
      <el-form-item class="button-form">
        <el-button class="button-style cancel-color" @click="onCancel">Hủy</el-button>
        <el-button type="primary" class="btn" @click="onSubmit" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Lưu</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SettingRequest from "@/requests/SettingRequest";
import CONST from "@/config/const";
export default {
  name: "userInfo",
  data() {
    var formatValiator = (rule, value, callback) => {
      if (!value.includes('@moet.edu.vn')) {
        callback(new Error('Email phải có định dạng @moet.edu.vn'));
      } else {
        callback();
      }
    };
    return {
      email: '',
      form: {
        email: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: "Vui lòng nhập địa chỉ email.",
            trigger: "blur",
          },
          {
            max: 250,
            message: "Địa chỉ email không vượt quá 250 ký tự.",
            trigger: "blur",
          },
          {
            type: 'email',
            message: "Địa chỉ email không hợp lệ.",
            trigger: "blur",
          },
          {
            pattern: CONST.EMAIL_REGEX,
            message: "Địa chỉ email không hợp lệ.",
            trigger: "blur",
          },
          {
            validator: formatValiator,
            trigger: "blur",
          }
        ],
      },
    };
  },
  methods: {
    async onCancel () {
      this.clearValidate();
      this.form.email = this.email;
    },
    async onSubmit () {
      let valid = await this.$refs['form'].validate();
      if (!valid) {
        return;
      }
      try {
        const res = await SettingRequest.changeEmail({ email: this.form.email});
        if (res && res.data) {
          this.email = res.data;
          this.form.email = res.data;
        }
        this.showSuccess("Thay đổi email thành công");
      } catch (e) {
        this.showError("Thay đổi email không thành công");
      }
    },
  },
  created: async function () {
    try {
      const res = await SettingRequest.getEmail();
      this.email = res.data;
      this.form.email = res.data;
    } catch (e) {
      this.showError();
    }
  },
};
</script>

<style lang="scss" scoped>
#email-settings {
  /deep/ .el-form-item__error {

  }
}
.el-form.box .el-form-item:not(.button-form) {
  padding-left: 15px;
  margin-bottom: 60px;
}
.box {
  margin-top: 32px;
  margin-left: 10px;
  padding-top: 30px;
  padding-left: 0px;
  background-color:#FFFFFF;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
}
.email-form {
  margin-bottom: 60px;
}
.text-form {
  margin-left: -100px;
  margin-right: 25px;
  font-size: 14px;
  line-height: 16px;
  color: #2C2C2C;
  vertical-align: middle;
}
.button-form {
  margin-bottom: 0px;
  margin-left: -100px;
  padding-bottom: 20px;
}
.input-form {
  width: 50%;
}
.button-style {
  width: 95px;
  border-radius: 4px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  border-radius: 4px;
}
.cancel-color {
  background: #C0C4CC;
}
.save-color {
  background: #409EFF;
}
</style>
