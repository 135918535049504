<template>
  <div class="login">
    <el-card>
      <h2 style="text-align: center">HỆ THỐNG TRA CỨU <br>VĂN BẰNG, CHỨNG CHỈ</h2>
      <el-form
        class="login-form"
        :model="model"
        :rules="rules"
        ref="form"
        label-width="0px"
        @submit.native.prevent="login"
      >
        <label style="float: left; margin-bottom: 1em"><span style="color: #F56C6C">* </span>Tên đăng nhập</label>
        <el-form-item prop="email">
          <el-input v-model="model.email"></el-input>
        </el-form-item>
        <label style="float: left; margin-bottom: 1em"><span style="color: #F56C6C">* </span>Mật khẩu</label>
        <el-form-item prop="password">
          <el-input v-model="model.password" type="password"></el-input>
        </el-form-item>
        <div class="forgot-password">
          <router-link to="/forgot-password">Quên mật khẩu ?</router-link>
        </div>
        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button"
            type="primary"
            native-type="submit"
            block
          >Đăng Nhập</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import UserRequest from "@/requests/UserRequest";
import AuthenticationStore from "@/requests/AuthenticationStore";
import { mapState, mapActions } from 'vuex';
import CONST from '@/config/const';
import _ from 'lodash';

const PERMISSION_READ = 'r';

export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      loading: false,
      rules: {
        email: [
          {
            required: true,
            message: "Vui lòng nhập tên đăng nhập.",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Vui lòng nhập mật khẩu.",
            trigger: "blur",
          },
        ],
      },
      defaultComponent: 'DegreeImport',
    };
  },
  computed: {
    ...mapState(['admin_role_permissions', 'user']),
  },
  methods: {
    ...mapActions(['getPermissionsByUser']),
    simulateLogin(data) {
      return UserRequest.login(data);
    },
    async redirectToDefaultComponent() {
      if (!this.user) {
        const res = await UserRequest.getAuthInfo();
        window.app.$store.dispatch('changeUser', res);
      }
      if (this.user.type_user != 1)  {
        this.$router.push({ name: this.defaultComponent }).catch(()=>{});
        return;
      } else {
        this.defaultComponent = 'UserInfo';
      }
      if (!this.admin_role_permissions) await this.getPermissionsByUser();
      _.forEach(this.admin_role_permissions, (permission) => {
        if (permission.admin_menu.name === CONST.COMPONENT_DEFAULT && permission.permission === PERMISSION_READ) {
          this.defaultComponent = CONST.COMPONENT_DEFAULT;
          return false;
        }
      })
      this.$router.push({ name: this.defaultComponent }).catch(()=>{});
    },
    async login() {
      let valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      if (!this.model.email.match(/^[a-zA-Z0-9.\-_]+$/)) {
        this.showError("Tên đăng nhập hoặc mật khẩu sai.");
        return;
      }
      this.loading = true;
      try {
        await this.simulateLogin(this.model);
        this.redirectToDefaultComponent();
      } catch (e) {
        console.log(">>>>>>>>", e.response);
        if (e.response && e.response.status == 404) {
          this.showError("Tài khoản của bạn đã bị tạm khóa.");
          this.loading = false;
        }
        if (e.response && e.response.status == 401) {
          this.showError("Tên đăng nhập hoặc mật khẩu sai.");
          this.loading = false;
        }
        if (e.response && e.response.status == 403) {
          this.showError(
            "Nhập sai quá nhiều lần. Vui lòng thử lại sau 30 phút."
          );
          this.loading = false;
        }
        if (e.response && e.response.status === 402) {
          let msgError = 'Đơn vị cấp bằng của bạn đã bị xóa.';
          if (e.response.data && e.response.data.error === 'organization_inactive') {
            msgError = 'Đơn vị cấp bằng của bạn đã bị tạm khóa.';
          }
          this.showError(msgError);
          this.loading = false;
        }
      }
    },
  },
  mounted() {
    if (AuthenticationStore.isAuthenticated()) {
      this.redirectToDefaultComponent()
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.login .el-card {
  .el-card__body {
    padding: 65px 69px;
  }
}
</style>
<style scoped lang="scss">
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../assets/background.jpg") no-repeat center fixed;
  background-position: bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-button {
  width: 480px;
  height: 50px;
  margin-top: 37px;
  background: #409eff;
  border: 1px solid #409eff;
  border-radius: 5px;
}
.login-form {
  label {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #363740;
  }
}
.forgot-password {
  margin-top: -4px;
  color: #409eff;
  text-align: right;
  text-decoration: none;
}
$teal: rgb(0, 124, 137);

.login .el-input__inner:hover {
  border-color: $teal;
}
.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;
  .el-input__icon {
    width: 30px;
  }
}
.el-form {
  border: none;
}
.login .el-card {
  padding-top: 0;
}
h2 {
  align-items: center;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 31px;
  color: #363740;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 58px;
}
a {
  color: #409eff;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}
.login .el-card {
  width: 618px;
  justify-content: center;
}
.el-form-item__content {
  width: 526px;
  float: left;
  height: 70px;
}
</style>

