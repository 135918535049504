<template>
  <el-row>
    <el-card class="detail-degree" v-loading="loading" v-if="!loading">
      <el-col :span="15" class="info-degree">
        <p class="info-number-degree" v-if="user != 'anonymous'">
          Mã định danh (ID): {{ (data && data.number && (user != 'draft')) ? data.number : 'Không có' }}
        </p>
        <p class="info-number-degree" v-else>
          THÔNG TIN CHI TIẾT VĂN BẰNG
        </p>
        <p class="status-vb" v-if="user == 'draft'">
          <span class="vb-not-avaiable">Không hiệu lực</span>
        </p>
        <p class="status-vb" v-if="user != 'draft'">
          <span class="vb-avaiable" v-if="!isDestroy  && data.status == 1 && data.request_type != 'Hủy bằng' || (data.status == 2 && data.request_type == 'Hủy bằng')">Còn hiệu lực</span>
          <span class="vb-not-avaiable" v-if="!isDestroy && data.status != 1 && (!(data.status == 2 && data.request_type == 'Hủy bằng'))">Không hiệu lực</span>
          <span class="vb-destroy" v-if="isDestroy">Hết hiệu lực</span>
        </p>
<!--        <p class="destroy_degree" v-if="isDestroy">-->
<!--          Văn bằng hết hiệu lực-->
<!--        </p>-->
        <p class="info-degree-tittle">THÔNG TIN QUẢN LÝ VĂN BẰNG</p>
        <p class="info-degree-item" v-if="data.organization">
          <span class="info-degree-item-name">Đơn vị cấp</span>
          <span class="info-degree-item-value">
            {{ data.organization.name }}
          </span>
        </p>
        <p class="info-degree-item" v-if="data.certificate">
          <span class="info-degree-item-name">Trình độ</span>
          <span class="info-degree-item-value">
            {{ data.certificate.name === 'Cử nhân' ? 'Đại học' :  data.certificate.name}}
          </span>
        </p>
        <p class="info-degree-item" v-if="data.vb_name">
          <span class="info-degree-item-name">Tên văn bằng</span>
          <span class="info-degree-item-value">
            {{ data.vb_name }}
          </span>
        </p>
        <p class="info-degree-item" v-if="data.department">
          <span class="info-degree-item-name">Ngành</span>
          <span class="info-degree-item-value">
            {{  data.department.name }}
          </span>
        </p>
        <p class="info-degree-tittle">THÔNG TIN NGƯỜI ĐƯỢC CẤP</p>
        <p class="info-degree-item" v-if="data.code_student && user != 'anonymous'">
          <span class="info-degree-item-name">Mã học viên</span>
          <span class="info-degree-item-value">{{ data.code_student }}</span>
        </p>
        <p class="info-degree-item" v-if="data.name">
          <span class="info-degree-item-name">Họ và tên</span>
          <span class="info-degree-item-value">{{ data.name }}</span>
        </p>
        <p class="info-degree-item" v-if="data.birthday">
          <span class="info-degree-item-name">Ngày sinh</span>
          <span class="info-degree-item-value">{{ data.birthday }}</span>
        </p>
        <p class="info-degree-item" v-if="data.birth_place">
          <span class="info-degree-item-name">Nơi sinh</span>
          <span class="info-degree-item-value">{{ data.birth_place }}</span>
        </p>
        <p class="info-degree-item" v-if="data.sex != null">
          <span class="info-degree-item-name">Giới tính</span>
          <span class="info-degree-item-value"
                v-if="(data.sex == 0 || data.sex == 1)">{{ data.sex ? 'Nam' : 'Nữ' }}</span>
          <span class="info-degree-item-value" v-else>{{ data.sex }}</span>
        </p>
        <p class="info-degree-item" v-if="data.ethnicity">
          <span class="info-degree-item-name">Dân tộc</span>
          <span class="info-degree-item-value">
            {{ data.ethnicity.name }}
          </span>
        </p>
        <p class="info-degree-item" v-if="data.nationality">
          <span class="info-degree-item-name">Quốc tịch</span>
          <span class="info-degree-item-value">
            {{ data.nationality.name }}
          </span>
        </p>
        <p class="info-degree-item" v-if="data.graduation_year">
          <span class="info-degree-item-name">Năm tốt nghiệp</span>
          <span class="info-degree-item-value">{{ data.graduation_year }}</span>
        </p>
        <p class="info-degree-item" v-if="data.ranking && user != 'anonymous'">
          <span class="info-degree-item-name">Xếp loại tốt nghiệp</span>
          <span class="info-degree-item-value">{{ data.ranking.type }}</span>
        </p>
        <p class="info-degree-item" v-if="data.training_form && user != 'anonymous'">
          <span class="info-degree-item-name">Hình thức đào tạo</span>
          <span class="info-degree-item-value">{{ data.training_form }}</span>
        </p>
        <p class="info-degree-tittle">THÔNG TIN VĂN BẰNG</p>
        <p class="info-degree-item" v-if="data.school">
          <span class="info-degree-item-name">Tên trường THPT</span>
          <span class="info-degree-item-value">{{ data.school }}</span>
        </p>
        <p class="info-degree-item" v-if="data.base_number">
          <span class="info-degree-item-name">Số hiệu bằng (cơ sở)</span>
          <span class="info-degree-item-value">{{ data.base_number }}</span>
        </p>
        <p class="info-degree-item" v-if="data.log_number">
          <span class="info-degree-item-name">Số vào sổ cấp bằng</span>
          <span class="info-degree-item-value">{{ data.log_number }}</span>
        </p>
        <p class="info-degree-item" v-if="data.signer && user != 'anonymous'">
          <span class="info-degree-item-name">Người ký</span>
          <span class="info-degree-item-value">{{ data.signer }}</span>
        </p>
        <p class="info-degree-item" v-if="data.title_signer && user != 'anonymous'">
          <span class="info-degree-item-name">Chức danh người ký</span>
          <span class="info-degree-item-value">{{ data.title_signer }}</span>
        </p>

        <div v-if="data.language && user != 'anonymous'">
          <p class="info-degree-item">
            <span class="info-degree-item-name">Ngoại ngữ</span>
            <span class="info-degree-item-value">{{ data.language }}</span>
          </p>
          <p class="info-degree-item" v-if="data.language_level">
            <span class="info-degree-item-name">Trình độ ngoại ngữ</span>
            <span class="info-degree-item-value">{{ data.language_level }}</span>
          </p>
          <p class="info-degree-item" v-if="data.start_course">
            <span class="info-degree-item-name">Khóa học bắt đầu</span>
            <span class="info-degree-item-value">{{ data.start_course | formatDate }}</span>
          </p>
          <p class="info-degree-item" v-if="data.end_course">
            <span class="info-degree-item-name">Khóa học kết thúc</span>
            <span class="info-degree-item-value">{{ data.end_course | formatDate }}</span>
          </p>
        </div>

        <div v-if="data.certificate && user != 'anonymous'">
          <p class="info-degree-item"
             v-if="(data.certificate.name == certiList[0] || data.certificate.name == certiList[1] || data.certificate.name == certiList[2]) && data.course">
            <span class="info-degree-item-name">Khóa học</span>
            <span class="info-degree-item-value">{{ data.course }}</span>
          </p>
          <p class="info-degree-item"
             v-if="data.certificate.name == certiList[2] || data.certificate.name == certiList[3]">
            <span class="info-degree-item-name">Ngày bảo vệ</span>
            <span class="info-degree-item-value">{{ data.exam_date | formatDate }}</span>
          </p>
          <p class="info-degree-item" v-if="(data.certificate.name == certiList[0] || data.language) && data.exam_board">
            <span class="info-degree-item-name">Hội đồng thi</span>
            <span class="info-degree-item-value">{{ data.exam_board }}</span>
          </p>
          <p class="info-degree-item"
             v-if="(data.certificate.name == certiList[0] || data.certificate.name == certiList[1] || data.certificate.name == certiList[2] || data.certificate.name == certiList[3]) && data.decisions_recognize_number">
            <span class="info-degree-item-name">Quyết định chấp nhận số</span>
            <span class="info-degree-item-value">{{ data.decisions_recognize_number }}</span>
          </p>
          <p class="info-degree-item" v-if="data.certificate.name == certiList[3] && data.decisions_establish_council">
            <span class="info-degree-item-name">Quyết định thành lập hội đồng</span>
            <span class="info-degree-item-value">{{ data.decisions_establish_council }}</span>
          </p>
        </div>

        <!-- <p class="info-degree-item" v-if="(user == 'anonymous') && data.approval_date">
          <span class="info-degree-item-name info-degree-item-name1">Ngày chấp nhận tại cục lưu trữ văn bằng</span>
          <span class="info-degree-item-value info-degree-item-value1">{{ data.approval_date }}</span>
        </p> -->

        <div v-if="user == 'admin'">
          <p class="info-degree-item custom-width" v-if="data.submission_date">
            <span class="info-degree-item-name">Ngày nộp</span>
            <span class="info-degree-item-value">{{ data.submission_date }}</span>
          </p>
          <p class="info-degree-item" v-if="data.status != null">
            <span class="info-degree-item-name">Trạng thái</span>
            <span class="info-degree-item-value">{{ data.status == 0 ? 'Chờ chấp nhận' : data.status == 1 ? 'Đã chấp nhận' : 'Từ chối'  }}</span>
          </p>
          <p class="info-degree-item custom-width" v-if="data.approval_date">
            <span class="info-degree-item-name">Ngày chấp nhận</span>
            <span class="info-degree-item-value">{{ data.approval_date }}</span>
          </p>
          <p class="info-degree-item custom-width" v-if="data.approval_date">
            <span class="info-degree-item-name">Người chấp nhận</span>
            <span class="info-degree-item-value">{{ data.approve_by && data.approve_by.name }}</span>
          </p>
          <p class="info-degree-item custom-width" v-if="data.reject_date">
            <span class="info-degree-item-name">Ngày từ chối</span>
            <span class="info-degree-item-value">{{ data.reject_date }}</span>
          </p>
          <p class="info-degree-item custom-width" v-if="data.reject_date">
            <span class="info-degree-item-name">Người từ chối</span>
            <span class="info-degree-item-value">{{ data.approve_by && data.approve_by.name }}</span>
          </p>
          <p class="info-degree-item custom-width" v-if="data.reason_reject">
            <span class="info-degree-item-name">Lý do từ chối</span>
            <span class="info-degree-item-value">{{ data.reason_reject }}</span>
          </p>
        </div>

        <p class="info-degree-item" v-if="data.note && this.user != 'anonymous'">
          <span class="info-degree-item-name">Ghi chú</span>
          <span class="info-degree-item-value">{{ data.note }}</span>
        </p>

        <div v-if="user == 'co-op' || user == 'draft'">
          <p class="info-degree-tittle">TRẠNG THÁI VĂN BẰNG</p>
          <p class="info-degree-item" v-if="data.status != null && user == 'co-op'">
            <span class="info-degree-item-name item-namee">Trạng thái</span>
            <span class="info-degree-item-value">{{ data.status == 0 ? 'Chờ chấp nhận' : data.status == 1 ? 'Đã chấp nhận' : 'Từ chối'  }}</span>
          </p>
          <p class="info-degree-item" v-if="user == 'draft'">
            <span class="info-degree-item-name item-namee">Trạng thái</span>
            <span class="info-degree-item-value">Tải lên thành công</span>
          </p>
          <p class="info-degree-item custom-width" v-if="user != 'draft'">
            <span class="info-degree-item-name item-namee">Ngày tải lên</span>
            <span class="info-degree-item-value">{{ data.upload_date | formatDate }}</span>
            <span class="info-degree-item-name item-namee">Người tải lên</span>
            <span class="info-degree-item-value value_1" v-if="data.upload_by">{{ data.upload_by.name }}</span>
          </p>
          <p class="info-degree-item custom-width" v-else>
            <span class="info-degree-item-name item-namee">Ngày tải lên</span>
            <span class="info-degree-item-value">{{ data.created_at | formatDate }}</span>
            <span class="info-degree-item-name item-namee">Người tải lên</span>
            <span class="info-degree-item-value value_1" v-if="data.user">{{ data.user.name }}</span>
          </p>
          <p class="info-degree-item custom-width" v-if="data.submit_by">
            <span class="info-degree-item-name item-namee">Ngày nộp</span>
            <span class="info-degree-item-value">{{ data.submission_date }}</span>
            <span class="info-degree-item-name item-namee">Người nộp</span>
            <span class="info-degree-item-value value_1">{{ data.submit_by.name }}</span>
          </p>
          <p class="info-degree-item custom-width" v-if="data.reject_date">
            <span class="info-degree-item-name item-namee">Ngày từ chối</span>
            <span class="info-degree-item-value">{{ data.reject_date }}</span>
            <span class="info-degree-item-name item-namee">Người từ chối</span>
            <span class="info-degree-item-value value_1">{{ data.approve_by && data.approve_by.name }}</span>
          </p>
          <p class="info-degree-item custom-width" v-if="data.approval_date">
            <span class="info-degree-item-name item-namee">Ngày chấp nhận</span>
            <span class="info-degree-item-value">{{ data.approval_date }}</span>
            <span class="info-degree-item-name item-namee">Người chấp nhận</span>
            <span class="info-degree-item-value value_1">{{ data.approve_by && data.approve_by.name }}</span>
          </p>
        </div>

        <div v-if="window_history.length > 1 && this.user != 'anonymous' && this.user != 'co-op'">
          <p class="info-degree-tittle" v-if="historyDate.length > 0">LỊCH SỬ</p>
          <div v-for="item in historyDate" :key="item" class="history">
            Ngày {{ item.upload_date | formatDatetime }}, văn bằng đã được <strong>{{ item.request_type == "Hủy bằng" ? "hủy" : item.request_type.toLowerCase() }}</strong>. Nội dung: <i style="opacity: 0.7">{{item.note}}</i>
            <a  class="el-link--primary" target="_blank"
          :href="`/admin/cooperator-approved/detail?id=${item.id}`"> Xem chi tiết</a>
          </div>
        </div>

        <div v-if="isShowBlockchain">
          <p class="info-degree-tittle">THÔNG TIN LƯU TRỮ BLOCKCHAIN</p>
          <p class="info-degree-item">
            <span class="info-degree-item-name">Mã giao dịch</span>
            <span class="info-degree-item-value">{{ data.code_transaction ? data.code_transaction : "Không có" }}</span>
          </p>
          <p class="info-degree-item">
            <span class="info-degree-item-name">Link truy xuất</span>
            <span class="info-degree-item-value"><a class="el-link--primary" target="_blank"
                                                    :href="`${this.url}/degree-detail?id=${data.number}`"> {{ this.url + '/degree-detail?id=' + data.number}}</a></span>
          </p>
        </div>

        <div v-if="window_history.length > 1 && this.user != 'anonymous' && this.user != 'co-op'">
          <el-button v-if="checkBtnBack && window_history.length > 1" type="info" class="btn btn-back" @click="$router.back()">Quay Lại</el-button>
        </div>
      </el-col>
      <el-col :span="9" class="bar-code" v-if="data.number && (user != 'draft')">
        <p class="info-degree-tittle bar-code-title">MÃ VẠCH</p>
        <span class="font-16">Mã vạch để truy xuất văn bằng tại Trung Tâm Công Nhận Văn Bằng - Cục Quản Lý Chất Lượng - Bộ Giáo Dục Đào Tạo</span>
        <barcode id="barcode" class="bar-code" :value="data.number" displayValue="false" height="70"
                 width="1"></barcode>
        <el-button type="primary" class="btn" @click="downloadBarcode('barcode')" style="width: 165px">
          Tải Xuống Mã Vạch
        </el-button>
        <div v-if="data.approval_date">
          <p class="info-degree-tittle qr-code-title">MÃ QR</p>
        <span class="font-16">Mã QR để truy xuất văn bằng tại Trung Tâm Công Nhận Văn Bằng - Cục Quản Lý Chất Lượng - Bộ Giáo Dục Đào Tạo</span>
          <qrcode id="qrcode" class="qr-code" width="95px"
                  :value="`${this.url}/degree-detail?id=${data.number}`"></qrcode>
          <el-button type="primary" class="btn" @click="downloadQrcode('qrcode')" style="width: 165px">
            Tải Xuống Mã QR
          </el-button>
        </div>
      </el-col>
    </el-card>
  </el-row>
</template>

<script>
  import CooperatorRequest from "../../packages/cooperator/api/cooperator";
  import DegreeRequest from "@/requests/DegreeCooperatorRequest";
  import CONST from '@/config/const'
  import moment from 'moment';

  export default {
    name: 'DegreeDetail',
    props: ['user'],
    data() {
      return {
        status_vb: 'Còn hiệu lực',
        loading: false,
        data: undefined,
        historyDate: [],
        isDestroy: false,
        isShowBlockchain: false,
        certiList: ['Trung học phổ thông', 'Cử nhân', 'Thạc sĩ', 'Tiến sĩ', '	Chứng chỉ ngoại ngữ'],
        url: CONST.APP_URL,
        window_history: null,
      }
    },
    methods: {
      downloadBarcode(code) {
        const svg = document.getElementById(code)
        var svgData = svg.children[0].outerHTML;
        var preface = '<?xml version="1.0" standalone="no"?>\r\n';
        var svgBlob = new Blob([preface, svgData], {type: "image/svg+xml;charset=utf-8"});
        var url = URL.createObjectURL(svgBlob);
        this.download(url, code);
      },
      downloadQrcode(code) {
        const img = document.getElementById(code)
        var url = img.src.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
        this.download(url, code + '.png');
      },
      download(url, name) {
        var a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
      },
      async fetchData() {
        this.loading = true;
        try {
          console.log(this.user)
          if (this.user == 'admin') {
            let res = await CooperatorRequest.show(this.$route.query.id);
            this.data = res.data.degree;
            this.historyDate = res.data.degrees ? res.data.degrees : [];
            this.isDestroy = res.data.is_destroy;
            if (this.data.status == 1) {
              this.isShowBlockchain = true;
            }
          } else if (this.user == 'co-op') {
            let res = await DegreeRequest.getDetailDegree(this.$route.query.id);
            this.data = res.degree;
            this.historyDate = res.degrees ? res.degrees : [];
            this.isDestroy = res.is_destroy;
            if (this.data.status == 1) {
              this.isShowBlockchain = true;
            }
          } else if (this.user == 'anonymous') {
            let res = await DegreeRequest.getDetailByCode(this.$route.query.id);
            this.data = res.degree;
            this.historyDate = res.degrees ? res.degrees : [];
            this.isDestroy = res.is_destroy;
          } else if (this.user == 'draft') {
            let res = await DegreeRequest.getDraftDetailDegree(this.$route.query.id);
            this.data = res;
          }
        } catch (error) {
        }
        this.loading = false;
      }
    },
    created() {
      this.fetchData()
    },
    computed: {
      checkBtnBack() {
        if (this.$route.name == 'SearchDegreeDetail') return false
        else return true;
      }
    },
    mounted() {
      this.window_history = window.history;
      console.log(">>>>>>>>>>> :" , this.window_history)
      // this.status_vb = 'Còn hiệu lực';
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "@/assets/sass/_variables";

  .history {
    word-break: break-word;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .status-vb {
    span {
      padding: 5px 30px 5px 30px;
      border-radius: 80%;
      opacity: 0.7;
    }
    .vb-avaiable {
      background-color: lightgreen;
    }
    .vb-not-avaiable {
      background-color: darkgray;
    }
    .vb-destroy {
      background-color: orangered;
    }
  }

  .clear {
    clear: both;
  }

  .el-link--primary {
    color: #409EFF;
  }

  .btn-back {
    margin-bottom: 25px;
  }

  .detail-degree {
    margin: 25px 5px 5px;
  }

  .info-number-degree, .info-degree-tittle {
    font-style: normal;
    font-weight: bold;
    color: $color_mine_shaft;
  }

  .destroy_degree {
    color: $color_froly;
  }

  .info-number-degree {
    font-size: 16px;
    line-height: 18px;
  }

  .info-degree-tittle {
    font-size: 14px;
    line-height: 16px;
    margin: 30px 0px 30px;
  }

  .info-degree-item {
    margin: 22px 0px;

    .name_1 {
      padding-left: 30px;
    }

    .info-degree-item-name, .info-degree-item-value {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $color_mine_shaft;
      display: inline-block;
    }

    .info-degree-item-name {
      padding-right: 20px;
      width: 190px;
    }

    .item-namee {
      width: 120px;
      vertical-align: top;
    }

    .info-degree-item-name1 {
      padding-right: 20px;
      width: 240px;
    }

    .info-degree-item-value {
      vertical-align: top;
      width: calc(100% - 210px);
      word-break: break-word;
    }

    .info-degree-item-value1 {
      width: 150px;
    }
  }

  .bar-code-title {
    margin-top: 65px;
    margin-bottom: 20px;
  }

  .bar-code {
    margin-bottom: 20px;
  }

  .qr-code-title {
    margin-top: 60px;
    margin-bottom: 15px;
  }

  .qr-code {
    display: block;
    margin-bottom: 15px;
  }

  .custom-width {
    .info-degree-item-value {
      width: 150px;
    }

    .info-degree-item-value.value_1 {
      width: calc(100% - 140px - 170px - 150px);
      word-break: break-word;
    }
  }

  .font-16 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $color_mine_shaft;
    display: inline-block;
  }

  @media only screen and (max-width: 560px) {
    .mobile_device .info-degree, .mobile_device .bar-code {
      width: 100%;
    }
    .mobile_device .info-degree-item .info-degree-item-name {
      width: 160px;
    }
    .mobile_device .info-degree-item .info-degree-item-value {
      width: calc(100% - 180px);
    }
  }
</style>
