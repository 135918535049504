const user_menu = [
  {
    created_at: null,
    icon_class: "",
    id: 1,
    name: "HistoryCooperator",
    parent_id: null,
    unicode_name: 'Lịch sử hoạt động',
    updated_at: '',
  },
  {
    created_at: null,
    icon_class: "",
    id: 2,
    name: "M2",
    parent_id: null,
    sub_menus: [
      {
        created_at: null,
        icon_class: "",
        id: 3,
        name: "DegreeImport",
        parent_id: 2,
        unicode_name: "Tải lên tệp danh sách",
        updated_at: null,
      },
      {
        created_at: null,
        icon_class: "",
        id: 4,
        name: "DegreeSuccess",
        parent_id: 2,
        unicode_name: "Tải lên thành công",
        updated_at: null,
      },
      {
        created_at: null,
        icon_class: "",
        id: 5,
        name: "DegreeError",
        parent_id: 2,
        unicode_name: "Tải lên thất bại",
        updated_at: null,
      },
    ],
    unicode_name: 'Tải lên văn bằng',
    updated_at: '',
  },
  {
    created_at: null,
    icon_class: "",
    id: 6,
    name: "M6",
    parent_id: null,
    sub_menus: [
      {
        created_at: null,
        icon_class: "",
        id: 7,
        name: "DegreeResultPending",
        parent_id: 6,
        unicode_name: "Chờ chấp nhận",
        updated_at: null,
        type: 'pending',
      },
      {
        created_at: null,
        icon_class: "",
        id: 8,
        name: "DegreeResultApproved",
        parent_id: 6,
        unicode_name: "Đã chấp nhận",
        updated_at: null,
        type: 'approved',
      },
      {
        created_at: null,
        icon_class: "",
        id: 9,
        name: "DegreeResultRejected",
        parent_id: 6,
        unicode_name: "Từ chối",
        updated_at: null,
        type: 'rejected',
      },
    ],
    unicode_name: 'Kết quả chấp nhận từ bộ GDĐT',
    updated_at: '',
  },
];

export default user_menu;
