<template>
  <el-aside class="menu">
    <el-menu :default-openeds="openedMenuId">
      <div class="menu-item"
        v-for="(menu, index) in userMenus" :key="menu.id"
        :class="{'length-max': menu.unicode_name === 'Quản lý đơn vị cung cấp dịch vụ'}">
        <el-submenu
          :index="index.toString()"
          v-if="menu.sub_menus ? menu.sub_menus.length : ''"
          :class="{'is-active-menu': menu.active}">
          <template slot="title">
            <i :class="menu.icon_class + ' icon-menu-custom'"></i>
            <span class="menu-item-name">{{ menu.unicode_name }}</span>
          </template>
          <el-menu-item-group class="submenu-group">
            <div v-for="(subMenu, index) in menu.sub_menus" :key="subMenu.name">
              <el-menu-item
                :index="index.toString()"
                class="submenu-item"
                @click="gotoLink(subMenu, menu)">
                <span
                  :class="{'is-active-menu-item': subMenu.active}"
                  class="submenu-item-name">
                  {{ subMenu.unicode_name }}
                </span>
              </el-menu-item>
            </div>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item
          v-else
          @click="gotoLink(menu)"
          :class="{'is-active-menu': menu.active}">
          <i :class="menu.icon_class + ' icon-menu-custom'"></i>
          <span
            class="submenu-item-name"
            :class="{'is-active-menu-item': menu.active}">{{ menu.unicode_name }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
  import user_menu from '@/config/user_menu'
  import { mapState } from 'vuex';

  export default {
    name: 'Header',
    props: {
      msg: String,
    },
    data() {
      return {
        openedMenuId: [],
        userMenus: [],
      }
    },
    computed: {
      ...mapState(['admin_menu'])
    },
    watch: {
      admin_menu() {
        this.initMenu();
      }
    },
    methods: {
      initMenu() {
        this.userMenus = JSON.parse(JSON.stringify(user_menu));
        this.userMenus.push({name: 'CoopUserInfo', route: 'user-info', sub_menus: [], unicode_name: 'Tài khoản'})
        this.resetActiveMenu();
        const rootComponentName = this.$router.currentRoute.matched[1]
                              ? this.$router.currentRoute.matched[1].name
                              : this.$router.currentRoute.name;
        _.forEach(this.userMenus, (menu, index) => {
          if (menu.name == rootComponentName) {
            this.$set(menu, 'active', true);
            this.openedMenuId.push(index.toString())
            return;
          } else {
            const subMenu = menu.sub_menus;
            if (subMenu) {
              for (let i = 0; i < subMenu.length; i++) {
                if (subMenu[i].name == rootComponentName) {
                  this.$set(subMenu[i], 'active', true);
                  this.$set(menu, 'active', true);
                  this.openedMenuId.push(index.toString())
                  return;
                }
              }
            }
          }
        })
      },
      checkActive(menu) {
        this.resetActiveMenu();
        if (menu.name == this.$router.currentRoute.name) {
          this.$set(menu, 'active', true);
          return;
        }
        const subMenu = menu.sub_menus
        if (subMenu) {
          for (let i = 0; i < subMenu.length; i++) {
            if (subMenu[i].name == this.$router.currentRoute.name) {
              this.$set(subMenu[i], 'active', true);
              this.$set(menu, 'active', true);
              return;
            }
          }
        }
        return false;
      },
      gotoLink(menu, parentMenu = undefined) {
        if (menu.type)
          this.$router.push({name: menu.name, query: {type: menu.type}}).catch(()=>{});
        else
          this.$router.push({name: menu.name}).catch(()=>{});
        this.checkActive(parentMenu || menu);
      },
      resetActiveMenu() {
        _.forEach(this.userMenus, (menuItem) => {
          this.$set(menuItem, 'active', false);
          _.forEach(menuItem.sub_menus, (subMenuItem) => {
            this.$set(subMenuItem, 'active', false);
          })
        });
      }
    },
    mounted() {
      this.initMenu();
    }
  }
</script>
<style lang="scss">
  .menu {
    .menu-item.length-max {
      padding-top: 5px;

      .el-menu-item {
        padding-left: 20px;
        height: 55px;
        line-height: 20px;
        padding-top: 7px;
      }
    }

    .el-submenu__title {
      position: relative;

      .el-submenu__icon-arrow {
        position: absolute;
        left: 5px;
        right: revert;
        margin-top: -9px;
        font-size: 14px;
      }
    }

    .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
      transform: rotateZ(0deg);
    }

    .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
      transform: rotateZ(-90deg);
    }

    .el-submenu__title:hover {
      background: #374253;
      .el-submenu__icon-arrow, .menu-item-name {
        color: #409EFF;
      }
    }

    .el-submenu.is-active-menu {
      &:focus {
        background-color: unset !important;
      }
      .el-submenu__title {
        background: #374253;

        .el-submenu__icon-arrow, .menu-item-name {
          color: #409EFF;
        }

      }
    }

    .el-menu-item.is-active-menu {
      &:focus {
        background-color: unset !important;
      }
      .el-menu-item__icon-arrow, .menu-item-name {
        color: #409EFF;
      }

    }

    .el-menu-item:hover {
      background: #374253;

      .submenu-item-name {
        color: #409EFF;
      }

    }
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .menu {
    width: 303px !important;
    background-color: #363740;
    overflow-x: hidden;
    height: auto !important;
  }

  .menu-item {
    background-color: #363740;
  }

  .submenu-item {
    background-color: #363740;
  }

  .submenu-group {
    background-color: #363740;
  }

  .menu-item-name {
    word-break: break-word;
    color: #A4A6B3;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.2px;
    font-style: normal;
    font-weight: normal;
  }

  .submenu-item-name {
    color: #A4A6B3;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.2px;
    font-style: normal;
    font-weight: normal;
    white-space: normal;
    word-break: break-word;
  }
  .is-active-menu-item {
    color: #409EFF
  }
</style>
