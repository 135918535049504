import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/vi';
import './assets/sass/style.scss';
import "tachyons-spacing";
import router from "./router";
import mixin from '@/utils/mixin'
import store from "./store";
import lodash from 'lodash';
import i18n from "./utils/i18n";
import VeeValidate, { Validator } from 'vee-validate';
import moment from 'moment';
import CustomElement from './utils/custom_element';
import './utils/filter';
import './utils/custom_directive';

if (process.env.VUE_APP_ENV != 'PRODUCTION') {
  window.env = process.env
  Vue.config.devtools = true
}

Vue.config.productionTip = false
Vue.use(lodash);
Vue.mixin(mixin);
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
});
locale.el.datepicker.year = '';
locale.el.pagination.goto = 'Đi đến trang';
Vue.use(ElementUI, { locale });
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});
Vue.filter('formatHour', function (value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss')
  }
});

window.app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    this.$store.dispatch('init');
  },
}).$mount('#app')

export default window.app
