<template>
  <el-row>
    <el-col :span="24" class="group-search">
      <el-form label-width="140px" label-position="left">
        <el-row>
          <el-col :span="11">
            <el-form-item label="Mô đun">
              <el-select style="width: 100%;" v-model="params.action" clearable placeholder="Tất cả">
                <el-option v-for="(item, key) in listAction" :label="$t(`${item}`)" :value="item" :key="key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="Người dùng">
              <el-input @keyup.enter.native="searchHistoryImport()" v-model="params.email" maxlength="250"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Thời gian">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="Ngày/tháng/năm" format="dd/MM/yyyy" value-format="yyyy-MM-dd"
                                style="width: 100%;" v-model="params.start_date">
                </el-date-picker>
              </el-col>
              <el-col class="line" :span="2">~</el-col>
              <el-col :span="11">
                <el-date-picker type="date" placeholder="Ngày/tháng/năm" format="dd/MM/yyyy" value-format="yyyy-MM-dd"
                                style="width: 100%;" v-model="params.end_date">
                </el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2" class="button-search">
            <el-form-item>
              <el-button class="btn-search" @click="searchHistoryImport()">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <el-col class="list-button">
      <div class="pagination-size">
        <p>Số bản ghi trên trang: </p>
        <el-dropdown @command="ChangePageSize">
              <span class="el-dropdown-link">
              {{ paramSearch.limit }}
              <i class="el-icon-caret-bottom"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in listPaginate"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="check-all">
        Kết quả bộ lọc có {{ total | formatNumber}}
      </div>
      <el-button type="warning" class="btn-export" @click="exportHistoryImport()">Kết Xuất</el-button>
    </el-col>

    <el-card shadow="never">
      <el-table
        :data="getData"
        style="width: 100%"
        empty-text="Không có dữ liệu">
        <el-table-column
          prop="time_iso"
          label="Thời gian">
          <template scope="scope">
            {{ scope.row.time_iso | formatDatetimeES}}
          </template>
        </el-table-column>
        <el-table-column
          prop="api_action"
          label="Mô đun">
          <template scope="scope">
            {{ $t(`${scope.row.api_action}`)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="auth_user_email"
          label="Người dùng">
        </el-table-column>
      </el-table>
      <custom-el-pagination
        :length="total"
        :paginateFrom="paginateFrom"
        :paginateTo="paginateTo"
        :total="total"
        :pageSize="pageSize"
        :page="paramSearch.page"
        @handleCurrentChange="handleCurrentChange"
      ></custom-el-pagination>
    </el-card>
  </el-row>
</template>
<script>
import CONST from "@/config/const";
import HistoryRequest from "@/requests/HistoryRequest";

export default {
  data() {
    return {
      getData: [],
      params: {
        limit: CONST.PAGINATION[0].value,
        page: 1,
      },
      paramsGet: {},
      paramSearch: {
        limit: CONST.PAGINATION[0].value,
        page: 1,
      },
      pageSize: CONST.PAGINATION[0].value,
      total: 0,
      paginateFrom: 1,
      paginateTo: CONST.PAGINATION[0].value,
      listPaginate: CONST.PAGINATION,
      lastPage:2,
      listAction: ['ImOrganizationRequest-import', 'ImUserRequest-import'],
    }
  },
  props: {
    show: Boolean,
  },
  watch : {
    show: function (value) {
      if (value === true) {
        this.resetParams();
        this.getList();
      }
    }
  },
  methods: {
    async getList() {
      this.paramsGet = {...this.paramSearch}
      const data = await HistoryRequest.getLogImport(this.paramSearch);
      this.getData = data.data.data;
      this.total = data.data.total;
      this.paginateFrom = data.data.from;
      this.paginateTo = data.data.to;
      this.page = data.data.current_page;
      this.pageSize = parseInt(data.data.per_page);
      this.lastPage = data.data.last_page;
    },
    searchHistoryImport() {
      this.params.page = 1;
      this.paramSearch = Object.assign(this.paramSearch, this.params);
      this.getList();
    },
    ChangePageSize(e) {
      this.paramSearch.limit = e;
      this.paramSearch.page = 1;
      this.getList(this.params);
    },
    handleCurrentChange(val) {
      this.page = val;
      const param = Object.assign(this.paramSearch, {
        page: this.page
      });
      this.getList(param);
    },
    exportHistoryImport () {
      HistoryRequest.exportHistoryImport(this.paramsGet).then((res) => {
        this.downloadFile(res, "Nhat_ky_nhap_du_lieu");
      });
    },
    resetParams() {
      this.params = {};
      this.paramSearch = {};
      this.params.page = 1;
      this.params.limit = CONST.PAGINATION[0].value;
      this.paramSearch.page = 1;
      this.paramSearch.limit = CONST.PAGINATION[0].value;
    }
  },
  created() {
    this.getList();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/style";
.line {
  text-align: center;
}
.list-button {
  position: relative;
  .pagination-size {
    top: -30px ;
  }
  .check-all {
    position: absolute;
    left: 0px;
    top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $color_mine_shaft;
  }
}
</style>
