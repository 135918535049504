import Numeral from 'numeral';
import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatNumber', function(amount, zeroValue) {
  let numberOfDecimalDigits = 10;
  let format = numberOfDecimalDigits == 0 ?
    '0,0' :
    '0,0.[' + Array(numberOfDecimalDigits + 1).join('0') + ']';
  if (window._.isNil(zeroValue)) {
    zeroValue = '0';
  }

  return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : zeroValue;
});


Vue.filter('formatDatetime', (date, format = 'DD/MM/YYYY HH:mm:ss') => {
  if (!date) {
    return '';
  }

  const offset = moment().utcOffset();
  return moment(date).add(offset, 'm').format(format);
});

Vue.filter('formatDatetimeES', (date, format = 'DD/MM/YYYY HH:mm:ss') => {
  if (!date) {
    return '';
  }
  return moment(date).format(format);
});

Vue.filter('formatDate', (date, format = 'DD/MM/YYYY') => {
  if (!date) {
    return '';
  }

  const offset = moment().utcOffset();
  return moment(date).add(offset, 'm').format(format);
});

Vue.filter('upperFirstCharacter', (value) => {
  if (typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
});
