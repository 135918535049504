<template>
	<div>
		<!-- Form Search -->
		<el-card shadow="never" class="mt3">
			<el-form label-position="left" :model="search" style="border:none">
				<h3 class="mt0 title-search-account">Người dùng đơn vị cấp bằng</h3>
				<el-row :gutter="20">
					<el-col :sm="24" :md="11">
						<el-form-item label="Tên đăng nhập">
							<el-input @keyup.enter.native="handleSearch()" v-model="search.username" maxlength="50"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24" :md="{span: 11, push: 2}">
						<el-form-item label="Tên đơn vị cấp bằng" label-width="150px">
							<el-input @keyup.enter.native="handleSearch()" v-model="search.organizationName"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :sm="24">
						<el-row type="flex" align="middle" justify="end">
							<el-button type="primary" class="btn" @click="handleSearch()">Tìm</el-button>
						</el-row>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<!-- Button Action -->
		<el-row class="mt3" :gutter="20">
			<el-col :sm="24" :md="5" class="mt3">
				<div class="el-pagination-size">
					<span>Số bản ghi trên trang:&nbsp;</span>
					<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link">
							{{ search.pageSize }}
							<i class="el-icon-caret-bottom"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item
								v-for="item in listPaginate"
								:key="item.key"
								:command="item.value"
							>{{ item.value }}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</el-col>
			<el-col :sm="24" :md="19" style="text-align:right">
				<div>
					<el-button
						type="success"
						class="btn"
						@click="$router.push({name: 'UserComponentCreate'})"
						:disabled="hasPermissionOnScreen(PERMISSION_CREATE)"
					>Thêm Mới</el-button>
					<el-button
						type="danger"
						class="btn"
						v-show="multipleSelection.length === 0"
						@click="handleConfirm()"
						disabled
					>Xoá</el-button>
					<el-button
						type="danger"
						class="btn"
						v-show="multipleSelection.length !== 0"
						@click="handleConfirm()"
						:disabled="hasPermissionOnScreen(PERMISSION_DELETE)"
					>Xoá</el-button>
					<el-button
						type="primary"
						class="btn"
						@click="$router.push({name: 'UserComponentUploadData'})"
						:disabled="hasPermissionOnScreen(PERMISSION_CREATE)"
					>Tải Dữ Liệu</el-button>
					<el-button
						type="warning"
						class="btn"
						@click="handleExport()"
						:disabled="hasPermissionOnScreen(PERMISSION_READ)"
					>Kết Xuất</el-button>
				</div>
			</el-col>
		</el-row>
		<el-card shadow="never" class="mt3" v-loading="loading">
			<!-- Table Data -->
			<el-table ref="table" @selection-change="handleSelectionChange" :data="data.data" style="width: 100%">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column property="username" label="Tên đăng nhập"></el-table-column>
				<el-table-column property="organization.name" label="Tên đơn vị cấp bằng"></el-table-column>
				<el-table-column label="Tình trạng">
					<template slot-scope="scope">
						<el-switch
							@change="handleStatus(scope.$index, scope.row, $event)"
							v-model="scope.row.status"
							active-color="#67C23A"
							inactive-color="#C0C4CC"
							:disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column property="last_login" label="Lần đăng nhập cuối" align="right">
          <template slot-scope="scope">
            {{ scope.row.last_login | formatDatetime }}
          </template>
        </el-table-column>
				<el-table-column align="right" label>
					<template slot-scope="scope">
						<el-button
							@click="$router.push({name: 'UserComponentEdit', query: {id: scope.row.id}})"
							type="info"
							icon="el-icon-edit"
							size="mini"
							circle
							:disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
						></el-button>
						<el-button
							@click="multipleSelection2 = [scope.row.id]; handleConfirm2(scope.row.email)"
							type="danger"
							icon="el-icon-delete"
							size="mini"
							circle
							:disabled="hasPermissionOnScreen(PERMISSION_DELETE)"
						></el-button>
					</template>
				</el-table-column>
			</el-table>

			<custom-el-pagination
				:length="data.total"
				:paginateFrom="data.from"
				:paginateTo="data.to"
				:total="data.total"
				:pageSize="data.per_page"
				:page="data.current_page"
				@handleCurrentChange="handleChangePage"
			></custom-el-pagination>
		</el-card>
	</div>
</template>

<script>
	import AccountUserRequest from "../api/AccountUserRequest";
	import ExportRequest from "../api/ExportRequest";
	import CONST from "@/config/const";

	export default {
		name: "AccountUser",
		data() {
			return {
				loading: false,
				search: {
					username: "",
					status: "",
					organizationName: "",
					pageSize: CONST.PAGINATION[0].value,
				},
        paramsSearch: {},
				data: [],
				sortParams: [],
				multipleSelection: [],
				multipleSelection2: [],
				listPaginate: CONST.PAGINATION,
			};
		},
		methods: {
			async handleSearch() {
				this.loading = true;
				try {
          this.paramsSearch = {...this.search}
          let res = await AccountUserRequest.search(this.search);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			async handleConfirm() {
				if (this.multipleSelection.length) {
					if (await this.showConfirmMixin()) {
						this.handleDelete();
					}
				}
			},
			async handleConfirm2(email) {
				if (this.multipleSelection2.length) {
					if (await this.showConfirmMixin()) {
						this.handleDelete2(email);
					}
				}
			},
			async handleDelete() {
				this.loading = true;
				try {
					let res = await AccountUserRequest.destroyMulti(
						this.multipleSelection
					);
					let param = {
						...this.paramsSearch,
						page:
							this.data.current_page < this.data.last_page
								? this.data.current_page
								: this.data.data.length !=
								  this.multipleSelection.length
								? this.data.last_page
								: this.data.last_page - 1,
					};
					res = await AccountUserRequest.search(param);
					this.data = res.data;
					this.showSuccess("Xóa thành công.");
				} catch (error) {}
				this.loading = false;
        this.multipleSelection = [];
			},
			async handleDelete2(email) {
				this.loading = true;
				try {
					let res = await AccountUserRequest.destroyMulti(
						this.multipleSelection2,
						email
					);
					let param = {
						...this.paramsSearch,
						page:
							this.data.current_page < this.data.last_page
								? this.data.current_page
								: this.data.data.length !=
								  this.multipleSelection2.length
								? this.data.last_page
								: this.data.last_page - 1,
					};
					res = await AccountUserRequest.search(param);
					this.data = res.data;
					this.showSuccess("Xóa thành công.");
				} catch (error) {}
				this.loading = false;
				this.multipleSelection2 = [];
			},
			async handleStatus(index, row, status) {
				try {
					let res = await AccountUserRequest.patch(row.id, {
						status: row.status,
					});
					this.showSuccess("Thành công.");
				} catch (error) {
          this.showError("Thất bại.");
          row.status = !status
        }
			},
			async handleChangePage(page) {
				this.loading = true;
				let param = { ...this.paramsSearch, page: page };
				try {
					let res = await AccountUserRequest.search(param);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			handleSelectionChange(val) {
				let ids = val.map(function (i) {
					return i.id;
				});
				this.multipleSelection = ids;
			},
			async handleExport() {
				if (!this.checkLimitExport(this.data.total)) {
					this.loading = true;
					try {
						let res = await ExportRequest.user(this.paramsSearch);
						window.location.assign(res);
					} catch (error) {}
					this.loading = false;
				}
			},
			async handleCommand(command) {
				this.search.pageSize = command;
        this.paramsSearch.pageSize = command;
				this.loading = true;
				try {
					let res = await AccountUserRequest.search(this.paramsSearch);
					this.data = res.data;
				} catch (e) {}
				this.loading = false;
			},
		},
		async created() {
			this.loading = true;
			try {
        this.paramsSearch = {...this.search}
        let res = await AccountUserRequest.search(this.search);
				this.data = res.data;
			} catch (error) {}
			this.loading = false;
		},
	};
</script>

<style scoped>
	.btn-delete {
		background: #f56c6c;
		border-radius: 4px;
		border-color: #f56c6c;
		font-weight: bold;
	}
	.btn-delete:disabled {
		background: #fab6b6;
		border-color: #fab6b6;
	}
</style>
