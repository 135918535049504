import History from "@/pages/Admin/History/History";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteHistory = [
  {
    path: '/admin/history',
    component: WrapPage,
    name: 'History',
    children: [
      {
        path: '',
        name: 'History',
        component: History,
      }
    ],
  },
];

export default RouteHistory;