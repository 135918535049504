import ProviderUnitEdit from "@/pages/Admin/ProviderUnit/ProviderUnitEdit";
import ProviderUnitList from "@/pages/Admin/ProviderUnit/ProviderUnitList";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteProviderUnit = [
  {
    path: '/admin/provider-unit',
    component: WrapPage,
    name: 'ProviderUnit',
    children: [
      {
        path: '',
        name: 'ProviderUnit',
        component: ProviderUnitList,
      }, {
        path: 'create',
        name: 'ProviderUnitCreate',
        component: ProviderUnitEdit,
      }, {
        path: ':id/edit',
        name: 'ProviderUnitEdit',
        component: ProviderUnitEdit,
      },
    ],
  },
];

export default RouteProviderUnit;