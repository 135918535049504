<template>
  <el-row>
    <el-col :span="24" class="group-search">
      <el-form label-width="140px" label-position="left">
        <p class="title-search">{{ title }}</p>
        <p class="title-total-degree">Hiện tại có {{ totalDegree }} văn bằng {{ titleTotal }}</p>
        <el-row>
          <el-col :span="11">
            <el-form-item label="Số vào sổ">
              <el-input v-model="params.number" @keyup.enter.native="searchDegreeResult" placeholder="Bỏ các ký tự đặc biệt ! @ # $ % ^ & * ( ) - _"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="Họ và tên">
              <el-input v-model="params.name" @keyup.enter.native="searchDegreeResult"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Mã học viên">
              <el-input v-model="params.code_student" @keyup.enter.native="searchDegreeResult"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="Loại yêu cầu">
              <el-select
                style="width: 100%;"
                v-model="params.request_type"
                clearable
                placeholder="Tất cả"
              >
                <el-option
                  v-for="(item, key) in listRequestTypes"
                  :label="item"
                  :value="key"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="degree-search_td">
            <el-form-item label="Trình độ">
              <el-select
                style="width: 100%;"
                v-model="params.certificate_id"
                clearable
                placeholder="Tất cả"
              >
                <el-option
                  :label="certificate.name"
                  :value="certificate.id"
                  v-for="certificate in getMasterData.certificaties"
                  :key="certificate.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2" class="degree-search_td">
            <el-form-item :label="titleDay" prop="end_date">
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Ngày/tháng/năm"
                  format="dd/MM/yyyy"
                  style="width: 100%;"
                  v-model="params.start_date"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">~</el-col>
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Ngày/tháng/năm"
                  format="dd/MM/yyyy"
                  style="width: 100%;"
                  v-model="params.end_date"
                  value-format="yyyy/MM/dd"
                ></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="16" class="button-search">
            <el-form-item>
              <el-button class="btn-search" @click="searchDegreeResult()">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <el-col class="list-button">
      <div class="pagination-size">
        <p>Số bản ghi trên trang:</p>
        <el-dropdown @command="ChangePageSize">
          <span class="el-dropdown-link">
            {{ params.limit }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in listPaginate"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="check-all">Kết quả bộ lọc có {{ total }} văn bằng</div>
      <el-button type="warning" class="btn-export" @click="exportDegreeResult()">Kết Xuất</el-button>
    </el-col>

    <el-card>
      <el-table :data="getData" style="width: 100%" empty-text="Không có dữ liệu">
        <el-table-column label="Mã định danh (ID)" :min-width="90">
          <template slot-scope="scope">
            <el-link v-if="$route.query.type  == type_approved"
              type="primary"
              @click="$router.push({name: 'DegreeDetail',
							query: {id: scope.row.number}})"
            >{{ scope.row.number }}</el-link>
              <el-link v-if="$route.query.type  != type_approved"
              type="primary"
              @click="$router.push({name: 'DegreeDetail',
							query: {id: scope.row.id}})"
            >{{ scope.row.number }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="code_student" label="Mã học viên"></el-table-column>
        <el-table-column prop="name" label="Họ và tên"></el-table-column>
        <el-table-column prop="graduation_year" label="Năm tốt nghiệp"></el-table-column>
        <el-table-column prop="ranking.type" label="Xếp loại tốt nghiệp" :min-width="90" :class-name="$route.query.type != type_reject ? '':'hidden-cell'"></el-table-column>
        <el-table-column prop="certificate.name" label="Trình độ"></el-table-column>
        <el-table-column prop="request_type" label="Loại yêu cầu">
          <template slot-scope="scope">{{ listRequestTypes[scope.row.request_type] }}</template>
        </el-table-column>
        <el-table-column :label="titleDate">
          <template slot-scope="scope">{{ getDay(scope.row) }}</template>
        </el-table-column>
        <el-table-column :label="titleDay">
          <template slot-scope="scope">{{ getDate(scope.row) }}</template>
        </el-table-column>
        <el-table-column prop="reason_reject" label="Lý do từ chối" :min-width="90" :class-name="$route.query.type == type_reject ? '':'hidden-cell'"></el-table-column>
      </el-table>
      <custom-el-pagination
        :length="total"
        :paginateFrom="paginateFrom"
        :paginateTo="paginateTo"
        :total="total"
        :pageSize="pageSize"
        :page="page"
        @handleCurrentChange="handleCurrentChange"
      ></custom-el-pagination>
    </el-card>
  </el-row>
</template>

<script>
import DegreeCooperatorRequest from "@/requests/DegreeCooperatorRequest";
import { mapGetters } from "vuex";
import CONST from "@/config/const";
import moment from "moment";

export default {
  data() {
    return {
      getData: [],
      params: {
        limit: CONST.PAGINATION[0].value,
      },
      multipleSelection: [],
      page: 1,
      pageSize: CONST.PAGINATION[0].value,
      total: 0,
      paginateFrom: 1,
      paginateTo: CONST.PAGINATION[0].value,
      listPaginate: CONST.PAGINATION,
      lastPage: 2,
      listRequestTypes: CONST.DEGREE_REQUEST_TYPES,
      title: "",
      titleTotal: "",
      titleDate: "",
      titleDay: "",
      totalDegree: 0,
      paramsSearch: {},
      type_approved : CONST.DEGREE_TYPE_APPROVED,
      type_reject : CONST.DEGREE_TYPE_REJECTED,
    };
  },
  computed: {
    ...mapGetters(["getMasterData"]),
  },
  watch: {
    $route(to, from) {
      this.params = {
        limit: CONST.PAGINATION[0].value,
      };
      const param = Object.assign(this.params, to.query);
      this.getList(param);
      this.getTitle();
      this.getTotal();
    },
  },
  methods: {
    setEndDate() {
      this.params.end_date = this.params.end_date
        ? moment(String(this.params.end_date)).format("YYYY/MM/DD")
        : "";
    },
    async getList(params) {
      this.paramsSearch = {...params}
      const data = await DegreeCooperatorRequest.getDegreeResult(params);
      this.getData = data.data;
      this.page = data.current_page;
      this.pageSize = parseInt(data.per_page);
      this.total = data.total;
      this.paginateFrom = data.from;
      this.paginateTo = data.to;
      this.lastPage = parseInt(data.last_page);
    },
    searchDegreeResult() {
      this.params.page = 1;
      this.paramsSearch = {...this.params}
      this.getList(this.params);
    },
    handleCurrentChange(val) {
      this.page = val;
      const param = Object.assign(this.paramsSearch, {
        page: this.page,
      });
      this.getList(param);
    },
    exportOrganization() {},
    ChangePageSize(e) {
      this.params.limit = e;
      this.params.page = 1;
      this.getList(this.params);
    },
    getDay(row) {
      switch (this.$route.query.type) {
        case CONST.DEGREE_TYPE_PENDING:
          return this.$options.filters.formatHour(row.submission_date);
        case CONST.DEGREE_TYPE_APPROVED:
          return this.$options.filters.formatHour(row.approval_date);
        default:
          return this.$options.filters.formatHour(row.reject_date);
      }
    },
    getDate(row) {
      switch (this.$route.query.type) {
        case CONST.DEGREE_TYPE_PENDING:
          return this.$options.filters.formatDate(row.submission_date);
        case CONST.DEGREE_TYPE_APPROVED:
          return this.$options.filters.formatDate(row.approval_date);
        default:
          return this.$options.filters.formatDate(row.reject_date);
      }
    },
    exportDegreeResult() {
      DegreeCooperatorRequest.exportDegreeResult(
        this.paramsSearch,
        this.$route.query.type
      ).then((res) => {
        switch (this.$route.query.type) {
          case CONST.DEGREE_TYPE_PENDING:
            this.downloadFile(res, "Van_bang_cho_duyet");
            break;
          case CONST.DEGREE_TYPE_APPROVED:
            this.downloadFile(res, "Van_bang_da_duyet");
            break;
          default:
            this.downloadFile(res, "Van_bang_tu_choi");
        }
      });
    },
    getTotal() {
      DegreeCooperatorRequest.getDegreeResult({
        limit: CONST.PAGINATION[0].value,
        type: this.$route.query.type,
      }).then((res) => {
        this.totalDegree = res.total;
      });
    },
    getTitle() {
      switch (this.$route.query.type) {
        case CONST.DEGREE_TYPE_PENDING:
          this.titleTotal = "đang chờ chấp nhận";
          this.title = "Danh sách văn bằng đang chờ chấp nhận từ bộ GDĐT";
          this.titleDate = "Thời gian nộp";
          this.titleDay = "Ngày nộp";
          break;
        case CONST.DEGREE_TYPE_APPROVED:
          this.titleTotal = "đã được chấp nhận";
          this.title = "Danh sách văn bằng đã được chấp nhận bởi bộ GDĐT";
          this.titleDate = "Thời gian chấp nhận";
          this.titleDay = "Ngày chấp nhận";
          break;
        default:
          this.titleTotal = "đã bị từ chối";
          this.title = "Danh sách văn bằng đã bị từ chối";
          this.titleDate = "Thời gian từ chối";
          this.titleDay = "Ngày từ chối";
          break;
      }
    },
  },
  mounted() {
  },
  created() {
    const param = Object.assign(this.params, this.$route.query);
    this.getList(param);
    this.getTitle();
    this.getTotal();
  },
};
</script>
<style lang="scss">
  @import "@/assets/sass/style";
  .hidden-cell {
    display: none;
  }
.organization-table {
  thead {
    .el-table_1_column_1 {
      .el-checkbox__input {
        display: none;
      }
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/style";
.line {
  text-align: center;
}
.list-button {
  position: relative;
  .pagination-size {
    top: -30px;
  }
  .check-all {
    position: absolute;
    left: 0px;
    top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $color_mine_shaft;
  }
}
.group-search {
  .title-search {
    margin-bottom: 7px;
  }
  .title-total-degree {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $color_froly;
    margin: 0px 0px 25px;
  }
}
h3 {
  margin: 40px 0 0;
}
a {
  color: #42b983;
}
.link-back {
  background: #fff;
  .link-back-list {
    display: table-cell;
    vertical-align: middle;
    height: 37px;
    a {
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #303133;
      padding: 0px 20px;
      height: 37px;
    }
    a.active {
      border-bottom: 2px solid #409eff;
      padding-bottom: 6px;
    }
  }
}
.btn-import {
  margin: 0px 12px;
}
</style>
