<template>
  <div>
    <el-card shadow="never" class="mt3">
      <el-form label-position="left" label-width="120px" :model="search" style="border:none">
        <h4 class="mt0 mb1">Thống kê theo trình độ</h4>
        <el-row :gutter="20" class="mt4">
          <el-col :sm="24" :md="12" >
            <el-form-item label="Loại đơn vị cấp bằng" label-width="180px">
              <el-select v-model="search.degree_authority_unit_type" placeholder="Tất cả" style="width: 100%" clearable>
                <el-option v-for="unit in CONST.DEGREE_AUTHORITY_UNITS" :label="unit.value" :value="unit.key"
                           :key="unit.key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12">
            <el-form-item label="Chọn đơn vị cấp bằng" label-width="180px">
              <el-autocomplete
                :placeholder="search.placeholder"
                style="width: 100%"
                v-model="search.organization_name"
                :fetch-suggestions="autoSelectSearch"
                :trigger-on-focus="true"
                ref="popup"
                @select="autoSelectChoice"
                @focus="autoSelectFocus"
                @blur="autoSelectBlur"
                @clear="autoSelectClear"
                clearable
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12">
            <el-form-item label="Chọn ngành" label-width="180px">
              <el-autocomplete
                :placeholder="search.department_placeholder"
                style="width: 100%"
                v-model="search.department_name"
                :fetch-suggestions="autoSelectSearchDepartment"
                :trigger-on-focus="true"
                ref="popup"
                @select="autoSelectChoiceDepartment"
                @focus="autoSelectFocusDepartment"
                @blur="autoSelectBlurDepartment"
                @clear="autoSelectClearDepartment"
                clearable
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :lg="12" >
            <el-form-item label-width="180px" label="Chọn thời gian chấp nhận">
              <el-row :gutter="0">
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="Ngày/tháng/năm"
                    v-model="search.from_approval_date"
                    style="width: 100%;"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-col>
                <el-col class="line" :span="2" style="text-align:center">~</el-col>
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="Ngày/tháng/năm"
                    v-model="search.to_approval_date"
                    style="width: 100%;"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :lg="12" >
            <el-form-item label-width="180px" label="Chọn năm tốt nghiệp">
              <el-input v-model="search.graduation_year"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24">
            <el-row type="flex" align="middle" justify="end">
              <el-button type="primary" @click="handleSearchChart()">Cập Nhật</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :sm="24" :md="13" :lg="8">
          <el-table
            v-show="dataPie.length"
            class="moet-table-1 mt3"
            :data="dataPie"
            :border="false"
            style="width: 100%"
          >
            <el-table-column label="Trình độ">
              <template slot-scope="scope">
                <i class="icon-color" :style="{'background-color': scope.row.colour}"></i>
                <span style="margin-left: 10px">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Số lượng văn bằng" align="right">
              <template slot-scope="scope">{{ scope.row.count | formatNumber }}</template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :sm="24" :md="11" :lg="16">
          <div id="chart" style="width:100%; height:400px;"></div>
        </el-col>
      </el-row>
      <h4 class="mt5 mb1">Dữ liệu gốc</h4>
      <el-row type="flex" justify="space-between" align="middle" class="mb2">
        <div class="el-pagination-size">
          <span>Số bản ghi trên trang:&nbsp;</span>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ searchTable.pageSize }}
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in listPaginate"
                :key="item.key"
                :command="item.value"
              >{{ item.value }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-form
          :inline="true"
          label-position="left"
          label-width="150px"
          :model="searchTable"
          style="border:none"
        >
          <el-form-item label="Chọn thời gian chấp nhận" class="mb0">
            <el-row :gutter="0">
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Ngày/tháng/năm"
                  v-model="searchTable.from_approval_date"
                  style="width: 100%;"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2" style="text-align:center">~</el-col>
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Ngày/tháng/năm"
                  v-model="searchTable.to_approval_date"
                  style="width: 100%;"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="margin-right:0" class="mb0">
            <el-button type="success" @click="handleSearchTable()">Áp Dụng</el-button>
            <el-button type="warning" @click="handleExport()">Kết Xuất</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row :gutter="40" style="border-top: 1px solid #eee;"></el-row>
      <el-table :data="dataTable.data" style="width: 100%" v-loading="loading">
        <el-table-column prop="name" label="Đơn vị cấp bằng"></el-table-column>
        <el-table-column :label="cpLabel(1)" align="right">
          <template slot-scope="scope">{{ scope.row.post_education_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="cpLabel(2)" align="right">
          <template slot-scope="scope">{{ scope.row.bachelor_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="cpLabel(3)" align="right">
          <template slot-scope="scope">{{ scope.row.master_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="cpLabel(4)" align="right">
          <template slot-scope="scope">{{ scope.row.doctorate_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="cpLabel(5)" align="right">
          <template slot-scope="scope">{{ scope.row.english_certificate_count | formatNumber }}</template>
        </el-table-column>
        <el-table-column label="Tổng" align="right">
          <template slot-scope="scope">
            {{
              (scope.row.english_certificate_count +
                scope.row.post_education_count +
                scope.row.doctorate_count +
                scope.row.bachelor_count +
                scope.row.master_count) | formatNumber
            }}
          </template>
        </el-table-column>
      </el-table>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="mt3"
        v-show="dataTable.total"
      >
        <div class="el-pagination-info">
          <span>Hiển thị {{ dataTable.from }}-{{ dataTable.to }} của {{ dataTable.total }} bản ghi</span>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="dataTable.total"
          :current-page="dataTable.current_page"
          :page-size="dataTable.per_page"
          @current-change="handleChangePage"
        ></el-pagination>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import StatisticalRequest from "../api/statistical";
import OrganizationRequest from "../api/organization";
import DepartmentRequest from "../../../requests/DepartmentRequest";
import Highcharts from "highcharts";
import CONST from "@/config/const";

var chart;
export default {
  name: "StatisticByCertificate",
  data() {
    return {
      CONST: CONST,
      loading: false,
      search: {
        organization_id: "",
        organization_name: "",
        department_id: "",
        department_name: "",
        department_placeholder: "Tất cả",
        from_approval_date: "",
        to_approval_date: "",
        degree_authority_unit_type: "",
        placeholder: "Tất cả",
        graduation_year: ""
      },
      searchTable: {
        from_approval_date: "",
        to_approval_date: "",
        pageSize: CONST.PAGINATION[0].value,
        page: 1,
      },
      dataSearch: {},
      dataPie: [],
      dataTable: [],
      listPaginate: CONST.PAGINATION,
    };
  },
  watch: {
    'search.degree_authority_unit_type': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.placeholder = 'Tất cả';
        this.search.organization_name = '';
      }
    }
  },
  methods: {
    async handleSearchChart() {
      try {
        await this.handleDataChart();
      } catch (error) {
      }
    },
    async handleSearchTable() {
      this.loading = true;
      try {
        this.searchTable.page = 1;
        await this.handleDataTable();
      } catch (error) {
      }
      this.loading = false;
    },
    async handleChangePage(page) {
      this.loading = true;
      try {
        this.searchTable.page = page;
        await this.handleDataTable();
      } catch (error) {
      }
      this.loading = false;
    },
    async autoSelectSearch(name, cb) {
      try {
        let res = await OrganizationRequest.index({
          name: this.search.organization_name,
          degree_authority_unit_type: this.search.degree_authority_unit_type || ''
        });
        let results = res.data.map((e) => {
          return { value: e.name, id: e.id, degree_authority_unit_type: e.degree_authority_unit_type };
        });
        if (this.search.degree_authority_unit_type) {
          results = results.filter((item) => {
            return item.degree_authority_unit_type == this.search.degree_authority_unit_type;
          })
        }
        console.log(results)
        cb(results);
      } catch (error) {
      }
    },
    autoSelectFocus() {
      this.search.organization_name = "";
      this.$refs.popup.suggestions = [];
    },
    autoSelectBlur() {
      if (this.search.organization_id) {
        this.search.organization_name = this.search.placeholder;
      }
    },
    autoSelectChoice(item) {
      this.search.organization_id = item.id;
      this.search.placeholder = item.value;
      this.search.organization_name = item.value;
    },
    autoSelectClear() {
      this.search.organization_id = "";
      this.search.placeholder = "Tất cả";
    },
    async autoSelectSearchDepartment(name, cb) {
      try {
        let res = await DepartmentRequest.getList({
          name: this.search.department_name,
        });
        let results = res.data.data.map((e) => {
          return { value: e.name, id: e.id };
        });
        cb(results);
      } catch (error) {
      }
    },
    autoSelectFocusDepartment() {
      this.search.department_name = "";
      this.search.department_id = "";
      this.$refs.popup.suggestions = [];
    },
    autoSelectBlurDepartment() {
      if (this.search.department_id) {
        this.search.department_name = this.search.placeholder;
      }
    },
    autoSelectChoiceDepartment(item) {
      this.search.department_id = item.id;
      this.search.department_placeholder = item.value;
      this.search.department_name = item.value;
    },
    autoSelectClearDepartment() {
      this.search.department_id = "";
      this.search.department_placeholder = "Tất cả";
    },
    async handleDataTable() {
      this.dataSearch = {...this.searchTable}
      let res = await StatisticalRequest.certificateIndex(this.searchTable);
      this.dataTable = res.data;
    },
    async handleDataChart() {
      let res = await StatisticalRequest.certificateChart(this.search);
      this.dataPie = res.data.map((item) => {
        return {
          ...item,
          count: item.degree_stat_qualification_count,
          y: item.degree_stat_qualification_count,
          color: item.colour,
        };
      });
      this.renderChart();
    },
    renderChart() {
      chart.series[0].setData(
        this.dataPie.filter(function (item) {
          return item.y;
        })
      );
    },
    async handleCommand(command) {
      this.loading = true;
      try {
        this.searchTable.page = 1;
        this.searchTable.pageSize = command;
        await this.handleDataTable();
      } catch (e) {
      }
      this.loading = false;
    },
    async handleExport() {
      if (!this.checkLimitExport(this.dataTable.total)) {
        this.loading = true;
        try {
          let res = await StatisticalRequest.certificateExport({
            from_approval_date: this.dataSearch.from_approval_date,
            to_approval_date: this.dataSearch.to_approval_date,
          });
          window.location.assign(res);
        } catch (error) {
        }
        this.loading = false;
      }
    },
    cpLabel(id) {
      let item = this.dataPie.find(function (item) {
        return item.id == id;
      });
      return item ? item.name : "";
    },
  },
  async created() {
    this.loading = true;
    try {
      await Promise.all([this.handleDataTable(), this.handleDataChart()]);
    } catch (error) {
    }
    this.loading = false;
  },
  mounted() {
    chart = new Highcharts.Chart({
      chart: {
        renderTo: "chart",
        type: "pie",
        spacingLeft: 0,
        spacingRight: 0,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: "default",
          dataLabels: {
            enabled: true,
            format: "{point.name}: {point.percentage:.1f} %",
            style: {
              fontFamily: 'Helvetica, Sans-Serif',
            }
          },
        },
      },
      series: [
        {
          name: "Đã chấp nhận",
          data: this.dataPie.filter(function (item) {
            return item.y;
          }),
          dataLabels: {
            style: {
              textOutline: "none",
              fontWeight: "normal",
            },
            padding: 0,
          },
          showInLegend: false,
          borderWidth: 0,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              enabled: false,
            },
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
    });
  },
};
</script>
<style lang="scss">
.moet-table-1.el-table {
  td,
  th.is-leaf {
    border: none;
  }

  .el-table__body tr:hover > td {
    background: transparent;
  }

  &::before {
    background-color: transparent;
  }

  thead th > .cell {
    color: #2c2c2c;
    font-weight: bold;
    font-size: 15px;
  }

  .cell {
    padding-left: 0;
  }
}

.icon-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}
</style>
