<template>
  <el-row>
    <el-col :span="24" class="role-search">
      <p class="role-text">Quản lý vai trò</p>
      <span class="el-form-item__label">Vai trò</span>
      <el-input @keyup.enter.native="filterRole()" v-model="search.role" class="input-search" maxlength="50"></el-input>
      <el-button type="primary" @click="filterRole" class="ml-20 btn">
        <span class="text-button-color">Tìm</span>
      </el-button>
    </el-col>
    <el-col :span="24">
      <div class="paginate-page">
        <p>Số bản ghi trên trang:</p>
        <el-dropdown @command="changePageSize">
          <span class="el-dropdown-link">
            {{ paramsSearch.limit }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in limitOptions"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button class="right-align mb btn" type="success" :disabled="hasPermissionOnScreen(PERMISSION_CREATE)" @click="gotoLink({'name': 'RoleManagementCreate'})">
        <span class="text-button-color">Thêm Mới</span>
      </el-button>
    </el-col>
    <el-card shadow="never">
      <el-table
        ref="multipleTable"
        :data="roles"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          prop="name"
          label="Vai Trò"
          class="padding-50">
        </el-table-column>
        <el-table-column
          prop="users_count"
          label="Số người dùng">
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="scope">
            <el-button size="small" type="primary" icon="el-icon-edit" circle class="edit-button"
            :disabled="hasPermissionOnScreen(PERMISSION_UPDATE)" @click="gotoEdit(scope.row.id)">
            </el-button>
            <el-button
              v-if="scope.row.id === superAdminId"
              size="small"
              type="danger"
              icon="el-icon-delete"
              circle
              class="delete-button"
              @click="deleteRole(scope.row.id, scope.row.name)"
              disabled></el-button>
            <el-button
              v-else
              size="small"
              type="danger"
              icon="el-icon-delete"
              circle
              class="delete-button"
              :disabled="scope.row.id === superAdminId || hasPermissionOnScreen(PERMISSION_DELETE)"
              @click="deleteRole(scope.row.id, scope.row.name)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <custom-el-pagination
        :length="total"
        :paginateFrom="paginateFrom"
        :paginateTo="paginateTo"
        :total="total"
        :pageSize="pageSize"
        :page="search.page"
        @handleCurrentChange="handleCurrentChange"
      ></custom-el-pagination>
    </el-card>
  </el-row>
</template>

<script>
import RoleManagementRequest from '../../requests/RoleManagementRequest';
import _ from 'lodash';
import CONST from '@/config/const'

export default {
  name: 'RoleManagement',
  props: {
    msg: String
  },
  data: function () {
    return {
      value: 1,
      search: {
        limit: CONST.PAGINATION[0].value || 25,
        page: 1,
      },
      roles: [],
      pageSize: CONST.PAGINATION[0].value,
      total: 0,
      paginateFrom: 1,
      paginateTo: CONST.PAGINATION[0].value,
      limitOptions: CONST.PAGINATION,
      superAdminId: CONST.SUPER_ADMIN_ID,
      paramsSearch: {},
    }
  },
  methods: {
    gotoEdit(roleId) {
      this.$router.push({name: 'RoleManagementEdit', params: {id: roleId}});
    },
    async getData() {

      this.getListWithFullParams(this.paramsSearch);
    },
    handleSelectionChange(value) {
      this.multipleSelection = value;
    },
    async deleteRole(roleId, roleName) {
      if (await this.showConfirmMixin()) {
        RoleManagementRequest.deleteRole(roleId, roleName).then((response) => {
          if (response.success) {
            this.getData();
            this.showSuccess('Xóa thành công.');
          }
        }).catch(() => {
        });
      }
    },
    async filterRole() {
      this.paramsSearch = {...this.search};
      this.paramsSearch.page = 1;
      this.getListWithFullParams(this.paramsSearch);
    },
    handleCurrentChange(val) {
      this.paramsSearch.page = val;
      this.getListWithFullParams(this.paramsSearch);
    },
    async getListWithFullParams(params = this.search) {
      const result = await RoleManagementRequest.getRoles(params);
      this.roles = _.filter(result.data, (item) => {
        return item.guard_name !== CONST.GUARD_NAME_PROVIDER_UNIT
      });
      this.search.page = result.current_page;
      this.pageSize = parseInt(result.per_page);
      this.total = result.total;
      this.paginateFrom = result.from;
      this.paginateTo = result.to;
    },
    changePageSize(e) {
      this.paramsSearch.limit = e;
      this.paramsSearch.page = 1;
      this.getListWithFullParams( this.paramsSearch);
    }
  },
  created() {
    this.paramsSearch = {...this.search}
    this.getListWithFullParams();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.box {
  margin-top: 32px;
  margin-right: 30px;
  border: 1px solid #EBEEF5;
}
h3 {
  margin: 40px 0 0;
}
.right-align {
  float: right;
}
.text-button-color {
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.small-size {
  width: 24px;
  height: 24px;
}
.padding-50 {
  padding-left: 50px;
}
.ml-20 {
  margin-left: 20px;
}
.mb {
  margin-bottom: 20px;
  margin-top: 20px;
}
.role-search {
  background-color: #FFFFFF;
  padding: 20px 20px 20px;
  margin-top: 20px;
}
.role-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #2C2C2C;
}
.input-search {
  width: 35%;
  margin-left: 30px;
}

/deep/ .el-select-dropdown {
  top: 290px !important;
}
.organization-pagination {
  background: #FFFFFF;
  .title-paginate {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    height: 55px;
    line-height: 3;
    letter-spacing: 0.3px;
    color: #9FA2B4;
    margin-left: 11px;
  }
}
/deep/.el-pagination {
  float: right;
  margin-top: 6px;
}
.clearfix {
  clear: both;
}
.paginate-page {
  display: inline-flex;
  margin-top: 15px;
  .el-dropdown {
    height: 20px;
    border: revert;
    border: 1px solid var(--select-border);
    background: #F7F8FC;
    color: #4B506D;
    margin-top: 17px;
    width: 45px;
  }
  p {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #9FA2B4;
    width: 150px;
  }
}
.action-column {
  float: right;
  margin-right: 90px;
}
/deep/th:first-child {
  padding-left: 30px;
}
/deep/tr td:first-child {
  padding-left: 30px;
}
.edit-button {
  padding: 7px;
  background-color: #909399;
  border-color: #909399;
}
.edit-button {
  &.is-disabled {
    background-color: #a4adbf;
    border-color: #a4adbf;
  }

}
.delete-button {
  padding: 7px;
}

/deep/ .el-button > span {
  margin-left: 0px !important;
}
</style>
