<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>Quản lý người dùng</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'AccountUser' }">Người dùng đơn vị cấp bằng</el-breadcrumb-item>
      <el-breadcrumb-item>{{ userId ? $t('Chỉnh sửa') : $t('Thêm mới')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="20" class="create-user">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <el-form ref="form" :rules="rules" :model="params">
            <p class="title">{{ userId ? temp.name : "" }}</p>
            <p class="sub-title">THÔNG TIN ĐĂNG NHẬP</p>
            <div class="item">
              <el-form-item label="Tên đăng nhập" prop="username">
                <el-input
                  class="custom-input"
                  v-model="params.username"
                  name="username"
                  maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item label="Mật Khẩu" prop="password">
                <el-input
                  class="custom-input"
                  type="password"
                  v-model="params.password"
                  :disabled="userId === ''"
                ></el-input>
              </el-form-item>
            </div>
            <p class="sub-title">THÔNG TIN CÁ NHÂN</p>
            <div class="item">
              <el-form-item label="Họ và tên" prop="name">
                <el-input class="custom-input" v-model="params.name" name="name"></el-input>
              </el-form-item>
              <el-form-item label="Email cá nhân" prop="email">
                <el-input class="custom-input" v-model="params.email" name="email"></el-input>
              </el-form-item>
            </div>
            <p class="sub-title">ĐƠN VỊ CẤP BẰNG</p>
            <div class="item">
              <el-form-item label="Tên đơn vị" prop="organization_id">
                <el-autocomplete
                  class="custom-input"
                  :placeholder="search.placeholder"
                  v-model="search.organizationName"
                  :fetch-suggestions="autoSelectSearch"
                  :trigger-on-focus="true"
                  @focus="autoSelectFocus"
                  @blur="autoSelectBlur"
                  @select="autoSelectChoice"
                  @clear="autoSelectClear"
                  clearable
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="Trạng thái">
                <el-switch active-color="#13ce66" v-model="params.status"></el-switch>
              </el-form-item>
            </div>
            <el-form-item class="custom-button">
              <el-button
                type="danger"
                class="btn"
                @click="deleteData()"
                v-if="userId"
                :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)"
              >Xóa</el-button>
              <el-button
                type="primary"
                class="btn"
                plain
                @click="resetData()"
                v-if="userId"
                :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
              >Đặt Lại</el-button>
              <el-button
                type="primary"
                class="btn"
                @click="onSubmit(params.id)"
                v-if="userId"
                :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)"
              >Lưu</el-button>
              <el-button
                type="primary"
                class="btn"
                @click="onSubmit(params.id)"
                v-else
                :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)"
              >Lưu</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UserRequest from "@/requests/UserRequest";
import { mapState } from "vuex";
import OrganizationRequest from "@/requests/OrganizationRequest";
import AccountUserRequest from "@/requests/AccountUserRequest";
import CONST from "@/config/const";

export default {
  name: "UserComponent",
  data() {
    let checkEmail = (rule, value, callback) => {
      UserRequest.checkEmail({
        email: value,
        id: this.userId,
      }).then((res) => {
        if (res.data.length === 1) {
          callback(new Error("Địa chỉ email đã tồn tại."));
        } else {
          callback();
        }
      });
    };
    let checkUsername = (rule, value, callback) => {
      UserRequest.checkUsername({
        username: value,
        id: this.userId,
      }).then((res) => {
        if (res.data.length === 1) {
          callback(new Error("Tên đăng nhập đã tồn tại."));
        } else {
          callback();
        }
      });
    };
    return {
      params: {
        organization_id: "",
        status: true,
      },
      temp: {},
      userId: this.$route.query.id || "",
      rules: {
        username: [
          {
            required: true,
            message: "Vui lòng nhập tên đăng nhập.",
            trigger: "blur",
          },
          {
            max: 50,
            message: "Tên đăng nhập không vượt quá 50 ký tự.",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9.\-_]+$/,
            message:
              "Tên đăng nhập chỉ chứa chữ cái tiếng Anh, chữ số và các kí tự '.','-', '_' .",
            trigger: "blur",
          },
          {
            validator: checkUsername,
            trigger: "blur",
          },
        ],
        password: [
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*(),.?":{}|<>\d]{8,50}$/,
            message:
              "Mật khẩu mới phải từ 8-50 ký tự, phải có ít nhất 1 chữ cái viết hoa, 1 chữ cái thường và 1 chữ số.",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "Vui lòng nhập họ và tên.",
            trigger: "blur",
          },
          {
            max: 250,
            message: "Họ và tên không vượt quá 250 ký tự.",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Vui lòng nhập địa chỉ email.",
            trigger: "blur",
          },
          {
            max: 250,
            message: "địa chỉ email không vượt quá 50 ký tự",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Địa chỉ email không hợp lệ.",
            trigger: "blur",
          },
          {
            pattern: CONST.EMAIL_REGEX,
            message: "Địa chỉ email không hợp lệ.",
            trigger: "blur",
          },
          {
            validator: checkEmail,
            trigger: "blur",
          },
        ],
        organization_id: [
          {
            required: true,
            message: "Vui lòng lựa chọn tên đơn vị.",
            trigger: "input",
          },
        ],
      },
      search: {
        organizationName: "",
        placeholder: "",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    if (this.userId) {
      UserRequest.getUserInfo(this.userId).then((res) => {
        this.params = res.data;
        this.temp = _.clone(res.data);
        this.params.status = !!res.data.status;
        this.params.organization_id = res.data.organization_id;
        this.search.organizationName = res.data.organization.name;
        this.search.placeholder = res.data.organization.name;
      });
    }
  },
  methods: {
    async onSubmit(id = "") {
      let valid = await this.$refs["form"].validate();
      if (!valid) {
        return;
      }
      const params = {
        ...this.params,
      };
      if (id) {
        return UserRequest.editUser(id, params).then(() => {
          this.showSuccess("Cập nhật đơn vị cấp bằng thành công.");
          this.$router.push({
            name: "AccountUser",
          });
        });
      }
      return UserRequest.createUser(params).then(() => {
        this.showSuccess("Thêm mới đơn vị cấp bằng thành công.");
        this.$router.push({
          name: "AccountUser",
        });
      });
    },

    async deleteData() {
      if (await this.showConfirmMixin()) {
        AccountUserRequest.destroy(this.params.id, this.params.email).then(
          () => {
            this.$router.push({
              name: "AccountAdmin",
            });
          }
        );
      }
    },

    resetData() {
      this.clearValidate();
      this.params = _.clone(this.temp);
      this.params.status = !!this.temp.status;
    },
    async autoSelectSearch(name, cb) {
      try {
        let res = await OrganizationRequest.getActiveOrganizationList({
          name: this.search.organizationName,
          status: true,
        });
        let results = res.data.data.map((e) => {
          return {
            value: e.name,
            id: e.id,
          };
        });
        cb(results);
      } catch (error) {}
    },
    autoSelectFocus() {
      this.search.organizationName = "";
    },
    autoSelectBlur() {
      if (!this.search.organization_id) {
        this.search.organizationName = this.search.placeholder;
      }
    },
    autoSelectChoice(item) {
      this.search.placeholder = item.value;
      this.params.organization_id = item.id;
      this.$refs.form.clearValidate("organization_id");
    },
    autoSelectClear() {
      this.params.organization_id = "";
      this.search.placeholder = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.create-user {
  /deep/ .el-form-item__error {
    margin-left: 140px;
    font-size: 13px;
    line-height: 20px;
  }

  /deep/ .el-form-item.is-error {
    margin-bottom: 40px;
  }

  /dee/ .el-form-item__content {
    line-height: 35px;
  }

  /deep/ .el-form {
    padding-left: 15px;
  }

  /deep/ .el-form-item__label {
    text-align: left !important;
    color: #2c2c2c !important;
  }

  .custom-input {
    width: 465px !important;
    height: 39px !important;
  }

  .item {
    margin-bottom: 30px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    color: #2c2c2c;
    text-align: left;
    margin-bottom: 28px;
    white-space: pre-line;
    word-break: break-all;
  }

  .sub-title {
    font-size: 14px;
    color: #909399;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
  }

  .custom-button {
    margin-top: 40px;
  }

  .el-button {
    width: 95px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
  }

  /deep/.el-switch__core {
    width: 52px !important;
    height: 24px;

    &:after {
      width: 20px;
      height: 20px;
    }
  }

  /deep/.el-switch.is-checked .el-switch__core::after {
    margin-left: -20px;
  }

  /deep/.el-form-item__content {
    margin-left: unset !important;
  }
}
</style>
