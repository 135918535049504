export default class AuthenticationStore {

  static isAuthenticated() {
    AuthenticationStore.loadDataIfNeed();
    return !!AuthenticationStore.accessToken;
  }

  static saveAuthenticationData(data) {
    window.localStorage.setItem('access_token', data.access_token || '');
    window.localStorage.setItem('user', JSON.stringify(data.user) || '');
    AuthenticationStore.accessToken = data.access_token || '';
    AuthenticationStore.user = data.user;
  }

  static removeAuthenticationData() {
    AuthenticationStore.saveAuthenticationData({});
    window.app.$store.dispatch('changeUser', null)
    window.app.$store.dispatch('changeAdminMenu', null)
    window.app.$store.dispatch('changePermissionsByUser', null)
  }

  static getAccessToken() {
    AuthenticationStore.loadData();
    return AuthenticationStore.accessToken;
  }

  static getUserId() {
    AuthenticationStore.loadDataIfNeed();
    return AuthenticationStore.user;
  }

  static loadData() {
    AuthenticationStore.accessToken = window.localStorage.getItem('access_token') || '';
    AuthenticationStore.user = JSON.parse(window.localStorage.getItem('user') || "{}");
    AuthenticationStore.dataLoaded = true;
  }

  static loadDataIfNeed() {
    if (AuthenticationStore.dataLoaded === undefined || !AuthenticationStore.dataLoaded) {
      AuthenticationStore.loadData();
    }
  }
}
