import LanguageLevel from "@/pages/Admin/LanguageLevels/LanguageLevel";
import LanguageLevelCreateEdit from "@/pages/Admin/LanguageLevels/LanguageLevelCreateEdit";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteLanguageLevel = [
    {
      path: '/admin/language-levels',
      component: WrapPage,
      name: 'LanguageLevel',
      children: [
        {
          path: '',
          name: 'LanguageLevel',
          component: LanguageLevel,
        },
        {
          path: 'create',
          name: 'LanguageLevelCreate',
          component: LanguageLevelCreateEdit,
        },
        {
          path: ':id/edit',
          name: 'LanguageLevelEdit',
          component: LanguageLevelCreateEdit,
        },
      ],
    },
];

export default RouteLanguageLevel;