<template>
  <div id="search-degree">
    <app-header></app-header>
    <el-row class="row">
      <el-col :span="18" :offset="3">
        <p class="title">Truy xuất dữ liệu văn bằng</p>
        <el-tabs v-model="activeTab" type="border-card">
          <el-form label-position="left" class="search-box" ref="form" :model="form" :rules="rules">
              <el-row>
                <el-col :span="11">
                  <el-form-item label="Đơn vị cấp bằng" required prop="organizationName">
                    <el-autocomplete
                      class="custom-input"
                      :placeholder="form.placeholder"
                      v-model="form.organizationName"
                      :fetch-suggestions="autoSelectSearch"
                      :trigger-on-focus="true"
                      @focus="autoSelectFocus"
                      @blur="autoSelectBlur"
                      @select="autoSelectChoice"
                      @clear="autoSelectClear"
                      clearable
                    ></el-autocomplete>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="custom-row" style="margin-top: 20px">
                <el-col :span="11">
                  <el-form-item label="Số hiệu bằng">
                    <el-input
                      class="custom-input"
                      maxlength="250"
                      v-model="code"
                      @keypress.enter.native.prevent="searchDegree"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="custom-row" style="margin-top: 20px">
                <el-col :span="11">
                  <el-form-item label="Số vào sổ cấp bằng">
                    <el-input
                      class="custom-input"
                      maxlength="250"
                      v-model="logNumber"
                      @keyup.enter.native.prevent="searchDegree"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="custom-row" style="margin-top: 20px">
                <el-col>
                  <el-form-item  label="Ngày sinh"  class="custom-form"  prop="birthday">
                    <el-input
                      class="custom-input-1"
                      v-model="form.birthday"
                      @keyup.enter.native.prevent="searchDegree"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Năm tốt nghiệp" class="custom-form custom-form-2"  prop="year">
                    <el-input
                      class="custom-input-1"
                      v-model="form.year"
                      @keyup.enter.native.prevent="searchDegree"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="custom-row" style="margin-top: 20px">
                <el-col>
                  <el-form-item label="Họ và tên"  prop="name">
                    <el-input
                      class="custom-input"
                      maxlength="250"
                      v-model="form.name"
                      @keyup.enter.native.prevent="searchDegree"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="custom-row" style="margin-top: 20px">
                <el-col :span="8">
                  <VueRecaptcha ref="recaptcha" :sitekey="this.sitekey" :loadRecaptchaScript="true" @verify="verifyCaptcha"/>
                </el-col>
              </el-row>
              <el-row class="custom-row" style="margin-top: 20px">
                <el-col :span="6">
                  <el-button :disabled="isDisabled" type="primary" class="btn" @click="searchDegree()">Tìm</el-button>
                </el-col>
              </el-row>
          </el-form>
        </el-tabs>
        <p class="title">Kết quả</p>
        <el-card shadow="never">
          <el-table
            :data="data"
            style="width: 100%"
            empty-text="Không có dữ liệu"
          >
            <el-table-column prop="base_number" label="Số hiệu bằng"></el-table-column>
            <el-table-column prop="log_number" label="Số vào sổ cấp bằng"></el-table-column>
            <el-table-column prop="name" label="Họ và tên"></el-table-column>
            <el-table-column prop="organization.name" label="Đơn vị cấp bằng"></el-table-column>
            <el-table-column label="Trình độ" :prop="['certificate', 'name']">
              <template slot-scope="scope">
                {{ scope.row.certificate.name === 'Cử nhân' ? 'Đại học' :  scope.row.certificate.name }}
              </template>
            </el-table-column>

            <el-table-column prop="graduation_year" label="Năm tốt nghiệp"></el-table-column>
            <el-table-column label="">
              <template slot-scope="scope">
                <a class="el-link--primary" target="_blank" :href="`${url}/degree-detail?id=${scope.row.number}`">
                  Xem chi tiết
                </a>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <img id='img' style="display: none;"/>
  </div>
</template>

<script>
import Header from "@/pages/SearchHeader";
import {mapState} from 'vuex';
import CONST from '@/config/const';
import DegreeRequest from "@/requests/DegreeCooperatorRequest";
import {BrowserQRCodeReader, BrowserBarcodeReader} from '@zxing/library';
import {readAsDataURL} from 'promise-file-reader';
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'Main',
  components: {
    "app-header": Header,
    VueRecaptcha,
  },
  data() {
    let checkLength= (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error('Vui lòng nhập đầy đủ họ và tên.'));
      } else {
        callback();
      }
    };

    return {
      code: null,
      unit: null,
      student_code: null,
      logNumber: null,
      rules: {
        organizationName: [
          {required: true, message: 'Vui lòng nhập đơn vị cấp bằng', trigger: ['blur', 'change']},
        ],
        // year: [
        //   {required: true, message: 'Vui lòng nhập năm tốt nghiệp', trigger: ['blur', 'change']},
        // ],
        birthday: [
          {required: true, message: 'Vui lòng nhập ngày sinh.', trigger: ['blur', 'change']},
        ],
        name: [
          {required: true, message: 'Vui lòng nhập họ và tên.', trigger: ['blur', 'change']},
          {validator: checkLength, trigger: 'blur'}
        ],
      },
      data: [],
      CONST: CONST,
      file: {},
      form: {
        organizationName: "",
        placeholder: "",
        birthday: "",
        year: "",
        name: ""
      },
      params: {
        organization_id: "",
        status: true,
      },
      activeTab: 'searchCode',
      url: CONST.APP_URL,
      recaptcha: {},
      isDisabled: true,
      sitekey: process.env.VUE_APP_RECAPTCHA_KEY
    }
  },
  methods: {
    async autoSelectSearch(name, cb) {
      try {
        let res = await DegreeRequest.getOrganizations({
          name: this.form.organizationName,
          status: true,
        });
        let results = res.data.data.map((e) => {
          return {
            value: e.name,
            id: e.id,
          };
        });
        cb(results);
      } catch (error) {
      }
    },
    autoSelectFocus() {
      this.form.organizationName = "";
    },
    autoSelectBlur() {
      if (!this.form.organization_id) {
        this.form.organizationName = this.form.placeholder;
      }
    },
    autoSelectChoice(item) {
      this.form.placeholder = item.value;
      this.params.organization_id = item.id;
      // this.$refs.form.clearValidate("organization_id");
    },
    autoSelectClear() {
      this.params.organization_id = "";
      this.form.placeholder = "";
    },
    // async onChange () {
    //   this.code = "";
    //   let isRightFormat = false;
    //   const fileList = this.$refs.fileUpload.uploadFiles
    //   this.file = fileList.pop();
    //   const qrcodeReader = new BrowserQRCodeReader();
    //   const barcodeReader = new BrowserBarcodeReader();

    //   const imgData = await readAsDataURL(this.file.raw)
    //   if (!(this.file.name.includes(".png") ||
    //       this.file.name.includes(".jpg") ||
    //       this.file.name.includes(".jpeg") ||
    //       this.file.name.includes(".svg"))) {
    //         this.showError('Ảnh mã vạch không đúng định dạng.');
    //       }
    //   document.getElementById('img').src = imgData;
    //   const img = document.getElementById('img')

    //   try {
    //     const qr = await qrcodeReader.decodeFromImage(img);
    //     this.code = qr && qr.text;
    //     return;
    //   } catch (e) {}
    //   try {
    //     const bar = await barcodeReader.decodeFromImage(img);
    //     this.code = bar && bar.text;
    //     return;
    //   } catch (e) {}
    //   this.showError('Ảnh mã vạch không đúng định dạng.');
    // },
    async verifyCaptcha(res) {
      this.recaptcha = res;
      this.isDisabled = false;
    },
    async searchDegree() {
      if (this.isDisabled) {
        return;
      }
      this.$refs['form'].validate(async (valid) => {
        if (!valid) {
          console.log(valid)
          return;
        }
        this.data = [];
        let res = await DegreeRequest.getByCode({
          base_number: this.code,
          log_number: this.logNumber,
          organization_id: this.params.organization_id,
          name: this.form.name,
          token: this.recaptcha,
          birthday: this.form.birthday,
          year: this.form.year
        });
        this.data = res.degree;
        if (!this.data[0]) {
          this.data = [];
        }
        this.recaptcha = "";
        this.$refs.recaptcha.reset();
        this.isDisabled = true;
      });
    },
    async searchByUnit() {
      try {
        this.data = [];
        this.unit = this.params.organization_id;
        let {unit, student_code} = this;
        let res = await DegreeRequest.getByUnit({unit, student_code});
        this.data = res.degree;
        if (!this.data[0]) {
          this.data = [];
        }
      } catch (error) {
      }
    }
  },
  watch: {
    activeTab() {
      this.code = ''
      this.student_code = ''
      this.form.organizationName = ''
      this.params.organization_id = "";
      this.form.placeholder = ''
      this.data = [];
    }
  },
  computed: {
    ...mapState(['user']),
  },
  async mounted() {
  },
  beforeRouteEnter(to, from, next) {
    let search;
    let student_code;
    let code;
    let params;
    if (from.name !== 'SearchDegreeDetail') {
      search = {
        organizationName: "",
        placeholder: "",
      };
      params = {
        organization_id: "",
        status: true,
      },
        student_code = null;
      code = null
      next(vm => {
        vm.search = search;
        vm.params = params;
        vm.student_code = student_code;
        vm.code = code;
        vm.searchDegree();
        vm.searchByUnit()
      })
    }
    next();
  }
}
</script>

<style scoped lang="scss">
.el-link--primary {
  color: #409EFF;
}

.custom-input {
  width: 450px;
}

.row {
  margin-top: 120px;
  min-height: calc(100vh - 120px);
}

.title {
  margin-top: 35px;
  margin-bottom: 25px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #2C2C2C;
}

.search-box {
  border: 0px;
  margin: 20px 5px;
}

.ml-20 {
  margin-left: 20px;
}
</style>

<style lang="scss">
#search-degree {
  /*.row {*/
  /*  margin-top: 120px;*/
  /*  height: 100vh;*/
  /*}*/
  /*background-color: #F7F8FC;*/
  background: revert;
  height: 100vh;

  .el-input-group__append {
    background: #C0C4CC;
    color: #FFFFFF;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  .el-tabs--border-card {
    box-shadow: none;
    border: none;
  }

  .el-tabs--border-card > .el-tabs__content {
    border: 1px solid #EBEEF5;
    border-radius: 0px 5px 5px 5px;
  }

  .el-tabs--border-card > .el-tabs__header {
    background-color: #ffffff;
    border: none;
  }

  .el-tabs__nav-scroll {
    background-color: #F7F8FC;
  }

  .el-tabs__nav .is-top {
    background-color: #409EFF;
    color: #ffffff;
  }

  .el-tabs__item {
    margin-right: 15px;
    border: none;
    margin-top: 0px;
    margin-left: 0px;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
    border-radius: 5px 5px 0px 0px;
  }

  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #ffffff;
    color: #000000;
  }

  .el-tabs__nav-wrap {
    margin-bottom: 0px;
  }

  .el-form-item__label {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #2C2C2C;
  }

  .el-form-item {
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .el-form-item.is-required.el-form-item__label:after {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
  }

  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
    font-size: 16px;
  }

  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    display: none;
  }

  //.inline-input {
  //  display: inline-block;
  //  width: 45%;
  //}
  //.custom-form {
  //  display: block !important;
  //  width: 100%;
  //  input {
  //    width: 430px;
  //  }
  //}

  .custom-form {
    display: inline-block !important;
  }

  .custom-input-1 {
    width: 140px;
  }
  .custom-form-2 {
   float: right;
    .el-form-item__label {
      text-align: right !important;
    }
  }

  .custom-row {
    width: 591px !important;
  }
}
</style>
