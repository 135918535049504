import Request from './Request';
import _ from 'lodash';

class RoleManagementRequest extends Request {

  async getPermissions() {
    let url = '/admin/permission/list';
    const response = await this.get(url);
    return this.sortPermissions(this.filterData(response));
  }

  async getPermissionsByRoleId(roleId) {
    let url = `/admin/role/permissions/${roleId}`;
    const response = await this.get(url);
    return this.filterData(response);
  }

  async getPermissionsByUser() {
    let url = `/admin/role/permissions-by-user`;
    return await this.get(url);
  }

  async getRoles(params) {
    let url = '/admin/role/list';
    const response = await this.get(url, params);
    return this.filterData(response);
  }

  async getMenu() {
    let url = '/admin/menu/list';
    const response = await this.get(url);
    return this.filterData(response);
  }

  async createRole(params) {
    let url = '/admin/role/create';
    return await this.post(url, params, {
      'api-action': 'RoleManagementRequest-create'
    });
  }

  async updateRole(params) {
    let url = `/admin/role/update/${params.id}`;
    return await this.put(url, params, {
      'api-action': 'RoleManagementRequest-edit'
    })
  }

  async deleteRole(id, name) {
    let url = `/admin/role/${id}`;
    return await this.delete(url, {}, {
      'api-action': 'RoleManagementRequest-delete'
    });
  }

  async checkExist(params) {
    let url = '/admin/role/check-exist-name';
    return await this.get(url, params);
  }

  sortPermissions(data) {
    // to add new submenu

    // if (!_.isEmpty(data)) {
    //   data.sort( function( a , b){
    //     const comp_a = a.parent_id === null ? a.id : a.parent_id
    //     const comp_b = b.parent_id === null ? b.id : b.parent_id
    //     if(comp_a > comp_b) return 1;
    //     if(comp_a < comp_b) return -1;
    //     if(a.id > b.id) return 1;
    //     if(a.id < b.id) return -1;
    //     return 0;
    //   });
    // }
    return data;
  }
}

export default RoleManagementRequest.getInstance()
