<template>
  <el-row :gutter="20" class="upload-data">
    <el-col :span="24">
      <div class="grid-content bg-purple">
        <div class="wrap">
          <p class="title">Tải lên văn bằng</p>
          <template>
            <p
              class="download-template"
              v-for="certificate in masterdata.certificaties.slice(0, 7)"
            >
              <i
                class="el-icon-download"
                @click="downloadTemplate(certificate.name, certificate.id)"
                :class="certificatesForUser.indexOf(certificate.id) !== -1 ? '' : 'no-clickable'"
              ></i>
              <span
                v-if="certificatesForUser.indexOf(certificate.id) === -1"
                @click="downloadTemplate(certificate.name, certificate.id)"
                class="no-clickable"
              >
                <span v-if="certificate.id != 5 && certificate.id != 1 && certificate.id != 2 && certificate.id != 6 && certificate.id != 7">Tải về tệp mẫu danh sách văn bằng trình độ {{ certificate.name }}</span>
                <span v-if="certificate.id == 1">Tải về tệp mẫu danh sách văn bằng Trung học phổ thông</span>
                <span v-if="certificate.id == 2">Tải về tệp mẫu danh sách văn bằng trình độ Đại học</span>
                <span v-if="certificate.id == 5">Tải về tệp mẫu danh sách chứng chỉ</span>
                <span v-if="certificate.id == 6">Tải về tệp mẫu danh sách văn bằng tương đương trình độ Đại học</span>
                <span v-if="certificate.id == 7">Tải về tệp mẫu danh sách chứng chỉ ứng dụng CNTT</span>
              </span>
              <span
                v-else
                @click="downloadTemplate(certificate.name, certificate.id)"
                class="link-color"
              >
                <u v-if="certificate.id != 5 && certificate.id != 1 && certificate.id != 2 && certificate.id != 6 && certificate.id != 7">Tải về tệp mẫu danh sách văn bằng trình độ {{ certificate.name }}</u>
                <u v-if="certificate.id == 1">Tải về tệp mẫu danh sách văn bằng Trung học phổ thông</u>
                <u v-if="certificate.id == 2">Tải về tệp mẫu danh sách văn bằng trình độ Đại học</u>
                <u v-if="certificate.id == 5">Tải về tệp mẫu danh sách chứng chỉ</u>
                <u v-if="certificate.id == 6">Tải về tệp mẫu danh sách văn bằng tương đương trình độ Đại học</u>
                <u v-if="certificate.id == 7">Tải về tệp mẫu danh sách chứng chỉ ứng dụng CNTT</u>
              </span>
            </p>
          </template>
          <el-upload
            ref="fileUpload"
            class="upload-custom"
            drag
            action="post"
            :showFileList="false"
            :autoUpload="false"
            :onChange="onChange"
            v-if="!toggleFile"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Kéo thả hoặc
              <em>chọn tệp</em>
              <span class="upload-tip">xls, xlsx (Tối đa 5MB)</span>
            </div>
          </el-upload>
          <div class="process-upload-data" v-if="loading">
            <i class="icon-loading"></i>
            <p class="data-uploading" v-if="status === 'uploading'">Đang tải lên</p>
            <p class="data-processing" v-if="status === 'importing' || status === 'uploaded'">
              Đang xử lý
              <br />
              <span>Quá trình xử lý có thể mất vài phút hoặc vài tiếng.Vui lòng quay lại sau.</span>
            </p>
          </div>
          <div class="file-detail" v-if="showFile">
            <el-tag closable @close="handleClose()">{{file.name}}</el-tag>
            <p>{{changeFileSize(file.size)}}</p>
          </div>
          <div class="file-success-process" v-if="showSuccessStatus">
            <p>
              Xử lí hoàn tất:
              <img src="@/assets/images/icons/icon-file.png" />
              <span>{{ fileName }}</span>
            </p>
          </div>
          <el-row>
            <el-button
              type="info"
              class="custom-button"
              @click="removeFile()"
              :disabled="!isDisable"
            >Hủy</el-button>
            <el-button
              type="primary"
              class="custom-button"
              @click="submit()"
              :disabled="!file.name || loading"
            >Tải Lên</el-button>
          </el-row>
          <el-row class="upload-success" v-if="!loading">
            <div v-if="fileImported.status == 'success' && !fileErr">
              <p class="upload-title">Kết quả tải lên</p>
              <p>Thời điểm tải lên: {{fileImported.created_at | formatHour}} ngày {{fileImported.created_at | formatDate}}</p>
              <el-row class="mt2">
                <el-col :span="7"><div class="grid-content bg-purple">
                  <p class="upload-label"><span>Thành công:</span> Có {{fileImported.total_success}} văn bằng tải lên thành công</p>
                </div></el-col>
                <el-col :span="4"><div class="grid-content bg-purple">
                  <el-button
                    class="custom-btn"
                    type="primary"
                    @click="gotoLink({name: 'DegreeSuccess'})">Xem Danh Sách</el-button>
                </div></el-col>
              </el-row>
              <el-row class="mt2">
                <el-col :span="7"><div class="grid-content bg-purple">
                  <p class="upload-label"><span>Thất bại:</span> Có {{fileImported.total_error}} văn bằng tải lên thất bại</p>
                </div></el-col>
                <el-col :span="8"><div class="grid-content bg-purple">
                  <el-button
                    class="custom-btn"
                    type="primary"
                    @click="gotoLink({name: 'DegreeError'})">Xem Danh Sách</el-button>
                  <el-button
                    class="btn-link"
                    type="primary"
                    @click="exportErr()">Tải Tệp Tin Về Chỉnh Sửa</el-button>
                </div></el-col>
              </el-row>
            </div>
            <div class="title" v-if="fileImported.status == 'error' || fileErr">
              <p class="upload-title">Kết quả tải lên</p>
              Tải lên thất bại !
            </div>
          </el-row>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import DegreeRequest from "@/requests/DegreeCooperatorRequest";
const UPLOAD_UPLOADED = "uploaded";
const UPLOAD_UPLOADING = "uploading";
const UPLOAD_IMPORTING = "importing";
import { mapGetters, mapState } from "vuex";
import axios from "axios";

export default {
  name: "DegreeImport",
  data() {
    return {
      showFile: false,
      file: {},
      loading: false,
      isDisable: false,
      statusInterval: null,
      status: "",
      checkStatus: "",
      toastMessage: false,
      showSuccessStatus: false,
      fileName: "",
      certificatesForUser: [],
      fileImported: "",
      fileErr: false
    };
  },
  created() {
    this.getUploadStatus();
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    ...mapState(["masterdata"]),
    toggleFile() {
      if (this.loading || this.file.name !== undefined) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getStatus();
    this.getCertificateForUser();
  },
  methods: {
    async getCertificateForUser() {
      this.certificatesForUser = await DegreeRequest.getOrganizationCertificatesForUser();
    },
    handleClose() {
      this.showFile = false;
      this.file = {};
    },
    onChange() {
      this.showSuccessStatus = false;
      this.file = this.$refs.fileUpload.uploadFiles[0];
      this.fileName = this.file.name;
      this.showFile = true;
    },
    getStatus() {
      this.checkStatus = setInterval(() => {
        this.getUploadStatus();
      }, 10000);
    },
    async submit() {
      const ext = this.file.name.substr(this.file.name.lastIndexOf(".") + 1);
      if (ext !== "xlsx" && ext !== "csv" && ext !== "xls") {
        this.loading = false;
        this.showError("Tệp đã chọn không đúng định dạng.");
        return;
      }
      if (this.file.size / (1024 * 1024) >= 100) {
        this.showError("Tệp tin không được lớn hơn 100MB.");
        return;
      }
      try {
        await DegreeRequest.updateUploadingStatus();
        this.isDisable = true;
        const formData = new FormData();
        formData.append("file", this.file.raw);
        this.getStatus();
        this.showFile = false;
        this.loading = true;
        this.status = UPLOAD_UPLOADING;
        DegreeRequest.importDegree(formData)
          .then(() => {
            this.getUploadStatus();
            this.file = {};
            this.toastMessage = true;
            this.fileErr = false;
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              this.removeFile();
              this.showError(
                "Nội dung tệp tin đã bị thay đổi, vui lòng chọn lại tệp tin."
              );
              return;
            }
            if (err.response) {
              let mes = err.response.data.message;
              if (err.response.data.message.includes("Allowed memory size")) {
                mes = "Tệp tin không được lớn hơn 100MB.";
              }
              this.showError(mes);
            }
            this.showFile = true;
            this.loading = false;
            this.showSuccessStatus = false;
            this.fileErr = true;
          });
      } catch (err) {
        this.showError(err.response.data.message);
      }
    },
    async removeFile() {
      // cancel request
      if (window.source) {
        window.source.cancel("Operation canceled by the user.");
      }
      const $affectRow = await DegreeRequest.updateErrorStatus();
      this.showFile = false;
      this.isDisable = false;
      this.file = {};
      if ($affectRow.data == 1) {
        this.loading = false;
        this.showSuccessStatus = false;
        this.showFile = false;
        this.file = {};
      }
    },
    async downloadTemplate(templateName, certificateId) {
      if (
        certificateId &&
        this.certificatesForUser.indexOf(certificateId) === -1
      )
        return;
      const res = await DegreeRequest.downloadTemplate({ name: templateName });
      this.downloadFile(res, templateName);
    },
    async getUploadStatus() {
      try {
        const res = await DegreeRequest.getUploadStatus({
          user_id: this.user.id,
        });
        this.loading = res.status
          ? res.status === UPLOAD_IMPORTING ||
            res.status === UPLOAD_UPLOADED ||
            res.status === UPLOAD_UPLOADING
          : false;
        this.isDisable =
          res.status === UPLOAD_UPLOADING && res.user_id === this.user.id;

        this.status = res.status;
        this.showSuccessStatus = false;
        this.fileImported = res;

        if (this.status === "success") {
          if (this.toastMessage) {
            this.showSuccessStatus = true;
            this.toastMessage = false;
          }
          clearInterval(this.checkStatus);
        }
        if (this.status === "error") {
          if (this.toastMessage) {
            this.showError("Xử lý tệp tin thất bại.");
            this.toastMessage = false;
          }
          clearInterval(this.checkStatus);
        }
      } catch (err) {
        this.showError(err.response.data.message);
      }
    },
    changeFileSize(size) {
      let mb = size / (1024 * 1024);
      if (mb >= 1) {
        return mb.toFixed(1) + " MB";
      }
      return (size / 1024).toFixed(1) + " KB";
    },
    exportErr() {
      DegreeRequest.exportError({
        user_id: this.user.id,
      }).then(res => {
        this.downloadFile(res, "van_bang_sai_du_lieu");
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.checkStatus);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_variables";
.file-success-process {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  color: $color_mine_shaft;
  span {
    color: $color_hover_link;
  }
  img {
    padding: 0px 5px;
    margin-bottom: -2px;
  }
}
.upload-data {
  margin-top: 20px;
  .custom-button {
    width: 95px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
  }
  .upload-custom {
    margin-top: 30px;
    margin-bottom: 38px;
    /deep/ .el-upload-dragger {
      width: 100%;
      height: 244px;
      background-color: rgba(192, 196, 204, 0.1);
      font-size: 13px;
    }
    /deep/ .el-upload {
      width: 100%;
      position: relative;
    }
    .el-upload__text {
      color: $color_mine_shaft;
    }
    .upload-tip {
      font-size: 12px;
      position: absolute;
      color: #909399;
      top: 58%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .wrap {
    background-color: $color_white;
    padding: 22px 30px 28px 28px;
    .title {
      font-weight: bold;
      font-size: 16px;
      margin-top: 0px;
      font-style: normal;
      line-height: 18px;
      color: $color_mine_shaft;
      margin-bottom: 35px;
    }
    .download-template {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      margin-top: 0px;
      color: $color_mine_shaft;
      cursor: pointer;
      i {
        color: $color_apple;
        font-size: 22px;
        padding-right: 15px;
      }
    }
    .download-template.not-permission {
      opacity: 0.5;
      pointer-events: none;
      i {
        opacity: 0.5;
      }
    }
  }
  .process-upload-data {
    text-align: center;
    background: $color_mercury;
    margin: 20px 0px 30px 0px;
    padding: 50px 0px;
    i {
      color: $color_apple;
      font-size: 50px;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      animation: spin 0.6s infinite linear;
      -webkit-font-smoothing: antialiased;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    i.icon-loading:before {
      content: url("../../../assets/images/icons/loading.svg");
    }
    .data-uploading,
    .data-processing {
      margin-top: 20px;
      font-style: normal;
      font-weight: normal;
      color: $color_mine_shaft;
      font-size: 13px;
      line-height: 15px;
    }
    .data-processing {
      line-height: 28px;
      span {
        font-size: 13px;
        line-height: 16px;
        color: $color_oslo_gray;
      }
    }
  }
  .link-color {
    color: #409eff;
  }
  .no-clickable {
    cursor: auto;
  }
}
.upload-success {
  .upload-title {
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
  }
  .upload-label {
    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .btn-link {
    margin-left: 15px;
  }
  .custom-btn {
    background-color: #DCEDFF;
    color: #409EFF;
    border-color: #409EFF;
  }
}
</style>
