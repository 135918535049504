import Main from "@/pages/Admin/Layouts/Main"
import UserInfo from "@/pages/Admin/UserInfo"
import RouteEmailSetting from '@/router/admin/email-setting'
import RouteRoleManagement from '@/router/admin/role-management';
import RouteOrganization from '@/router/admin/organization';
import RouteNationalManagement from '@/router/admin/national-management';
import RouteDoetManagement from '@/router/admin/doet-management';
import RouteRanking from "@/router/admin/ranking";
import RouteLanguageLevel from "@/router/admin/language-level";
import RouteLanguage from "@/router/admin/language";
import RouteCertificate from '@/packages/certificate/routes';
import RouteAccount from '@/packages/account/routes';
import RouteDepartmentManagement from '@/router/admin/department-management';
import RouteEthnicity from '@/router/admin/ethnicity';
import RouteCooperator from '@/packages/cooperator/routes';
import RouteStatistical from '@/packages/statistical/routes';
import ProviderUnit from '@/router/admin/provider-unit';
import RouteHistory from '@/router/admin/history';
import TinhThanhPho from '@/pages/Admin/TinhThanhPho';
import HuyenXaPhuong from '@/pages/Admin/HuyenXaPhuong';
import NguoiKy from '@/pages/Admin/NguoiKy';
import ChucDanhNguoiKy from '@/pages/Admin/ChucDanhNguoiKy';
import PhanLoaiVanBang from '@/pages/Admin/PhanLoaiVanBang';
import MaVach from '@/pages/Admin/MaVach';
import MaQr from '@/pages/Admin/MaQr';

const Admin = [
    {
        // admin site
        path: '/admin/*',
        component: Main,
        meta: {
        auth: true
        },
        children: [
        // admin route
        {
            path: '/admin/tinh-thanh-pho',
            component: TinhThanhPho,
            name: 'TinhThanhPho',
        },
        {
          path: '/admin/huyen-xa-phuong',
          component: HuyenXaPhuong,
          name: 'HuyenXaPhuong',
        },
        {
          path: '/admin/nguoi-ky',
          component: NguoiKy,
          name: 'NguoiKy',
        },
        {
          path: '/admin/chuc-danh-nguoi-ky',
          component: ChucDanhNguoiKy,
          name: 'ChucDanhNguoiKy',
        },
        {
          path: '/admin/phan-loai-van-bang',
          component: PhanLoaiVanBang,
          name: 'PhanLoaiVanBang',
        },
        {
          path: '/admin/ma-vach',
          component: MaVach,
          name: 'MaVach',
        },
        {
          path: '/admin/ma-qr',
          component: MaQr,
          name: 'MaQr',
        },
        {
          path: '/admin/user-info',
          component: UserInfo,
          name: 'UserInfo',
        },
        ...RouteDepartmentManagement,
        ...RouteNationalManagement,
        ...RouteDoetManagement,
        ...RouteRoleManagement,
        ...RouteEmailSetting,
        ...RouteOrganization,
        ...RouteRanking,
        ...RouteLanguageLevel,
        ...RouteEthnicity,
        ...RouteLanguage,
        ...RouteAccount,
        ...RouteCertificate,
        ...RouteCooperator,
        ...ProviderUnit,
        ...RouteStatistical,
        ...RouteHistory,
        ]
    },
];

export default Admin;
