import AdminMenuRequest from "@/requests/AdminMenuRequest";

const state = {
  admin_menu: null,
};

const getters = {
  admin_menu(state) {
    return state.admin_menu;
  },
};

const actions = {
  async changeAdminMenu({commit}, admin_menu) {
    commit("SET_ADMIN_MENU", admin_menu);
  },
  async getAdminMenu({commit}) {
    const res = await AdminMenuRequest.getMenu();
    commit("SET_ADMIN_MENU", res.data);
  }
}

const mutations = {
  SET_ADMIN_MENU(state, admin_menu) {
    state.admin_menu = admin_menu;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
