import Request from './Request';

class RankingRequest extends Request {
    async getListRanking(params) {
        let url = '/admin/rankings/list';
        return await this.get(url, params);
    }
    async updateStatusRanking(params) {
        let url = '/admin/rankings/update-status';
        return await this.post(url, params);
    }
    async deleteRanking(id, code) {
        let url = '/admin/rankings/'+ id;
        return await this.delete(url, {}, {
            'api-action': 'RankingRequest-delete',
            'api-action-meta': code
        });
    }
    async deleteMultiRanking(params) {
        let url = '/admin/rankings/delete';
        return await this.post(url, params, {
            'api-action': 'RankingRequest-deleteAll',
            'api-action-meta': params.list.length
        });
    }
    async createRanking(params) {
        let url = '/admin/rankings/create';
        return await this.post(url, params, {
            'api-action': 'RankingRequest-create',
            'api-action-meta': params.code
        });
    }
    async showRanking(id) {
        let url = '/admin/rankings/'+id;
        return await this.get(url);
    }
    async updateRanking(params, id) {
        let url = '/admin/rankings/'+id;
        return await this.put(url, params, {
            'api-action': 'RankingRequest-edit',
            'api-action-meta': params.code
        });
    }
    async checkCode(params) {
        let url = '/admin/rankings/check-code';
        return await this.post(url, params);
    }
}
export default RankingRequest.getInstance()
