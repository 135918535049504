<template>
  <el-row>
    <el-col :span="24" class="group-search">
      <el-form label-width="140px" :model="params" label-position="left" ref="form">
        <p class="title-search">Danh sách tải lên thành công từ tệp danh sách</p>
        <el-row>
          <el-col :span="11" class="degree-search_code">
            <el-form-item label="Mã học viên">
              <el-input v-model="params.code_student" @keyup.enter.native="searchDegree"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2" class="degree-search_name">
            <el-form-item label="Họ và tên">
              <el-input v-model="params.name" @keyup.enter.native="searchDegree"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="degree-search_td">
            <el-form-item label="Trình độ">
              <el-select
                style="width: 100%;"
                v-model="params.certificate"
                clearable
                placeholder="Tất cả"
              >
                <el-option
                  :label="certificate.name"
                  :value="certificate.name"
                  v-for="certificate in getMasterData.certificaties"
                  :key="certificate.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2" class="degree-search_td">
            <el-form-item label="Ngày tải lên" prop="end_date">
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Ngày/tháng/năm"
                  format="dd/MM/yyyy"
                  style="width: 100%;"
                  v-model="params.start_date"
                  value-format="yyyy/MM/dd"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">~</el-col>
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Ngày/tháng/năm"
                  format="dd/MM/yyyy"
                  style="width: 100%;"
                  v-model="params.end_date"
                  value-format="yyyy/MM/dd"
                ></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="16" class="button-search">
            <el-form-item>
              <el-button type="primary" class="btn-search" @click="searchDegree">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <el-col :span="24" class="list-button">
      <div class="pagination-size">
        <p>Số bản ghi trên trang:</p>
        <el-dropdown @command="ChangePageSize">
          <span class="el-dropdown-link">
            {{ params.limit }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in listPaginate"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="check-all">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAll()"
        >Đã chọn ({{ numberChecked }}/{{ total }} văn bằng)</el-checkbox>
      </div>
      <el-button
        type="danger"
        class="btn-delete"
        :disabled="loading || (multipleSelection.length === 0 && !checkAll) || getData.length === 0"
        @click="handleConfirmDelete()"
      >Xóa</el-button>
      <el-button
        type="success"
        class="btn-add"
        :disabled="loading ||(multipleSelection.length === 0 && !checkAll) || getData.length === 0"
        @click="handleConfirmSend()"
      >Gửi Bộ GDĐT</el-button>
      <el-button type="warning" class="btn-export" @click="exportDegree()">Kết Xuất</el-button>
    </el-col>

    <el-card>
      <el-col :span="24" class="degree-table">
        <template>
          <el-table
            ref="table"
            :data="getData"
            @select="handleSelect"
            style="width: 100%"
            empty-text="Không có dữ liệu"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="Số vào sổ cấp bằng" :min-width="90">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="$router.push({name: 'DraftDegreeDetail',
                  query: {id: scope.row.id}})"
                >{{ scope.row.log_number }}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="code_student" label="Mã học viên"></el-table-column>
            <el-table-column prop="name" label="Họ và tên"></el-table-column>
            <el-table-column prop="graduation_year" label="Năm tốt nghiệp"></el-table-column>
            <el-table-column label="Xếp loại tốt nghiệp">
              <template scope="scope">{{ scope.row.ranking ? scope.row.ranking.type : '' }}</template>
            </el-table-column>
            <el-table-column prop="certificate" label="Trình độ"></el-table-column>
            <el-table-column prop="request_type" label="Loại yêu cầu"></el-table-column>
            <el-table-column prop="updated_at" label="Thời gian tải lên">
              <template scope="scope">{{ scope.row.created_at | formatHour }}</template>
            </el-table-column>
            <el-table-column prop="updated_at" label="Ngày tải lên">
              <template scope="scope">
                {{ scope.row.created_at | formatDate}}
                <el-tooltip
                  v-if="scope.row.status === status_error"
                  :content="'Yêu cầu gửi lên Bộ GDĐT thất bại tại thời điểm ' +
                  $options.filters.formatDatetimeES(scope.row.updated_at )"
                  placement="top-end"
                  effect="light"
                >
                  <i class="warning-change-pass"></i>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <custom-el-pagination
            :length="getData.length"
            :paginateFrom="paginateFrom"
            :paginateTo="paginateTo"
            :total="total"
            :pageSize="pageSize"
            :page="page"
            @handleCurrentChange="handleCurrentChange"
          ></custom-el-pagination>
        </template>
      </el-col>
    </el-card>
    <el-dialog class="custom_dialog" title="Xác Nhận" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules"  ref="form" >
        Bạn có chắc chắn muốn gửi {{numberChecked}} văn bằng không?
        <el-form-item label="Vui lòng nhập mật khẩu:" prop="password">
          <el-input type="password" v-model="form.password" name="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="el-button el-button--default el-button--small" @click="dialogFormVisible = false">Hủy</el-button>
        <el-button class="el-button el-button--default el-button--small" type="primary" @click="handleComfirmPassword()">Xác Nhận</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>

<script>
import DegreeCooperatorRequest from "@/requests/DegreeCooperatorRequest";
import CONST from "@/config/const";
import _ from "lodash";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "DegreeSuccess",
  props: {
    msg: String,
  },
  data() {
    return {
      getData: [],
      params: {
        limit: CONST.PAGINATION[0].value,
      },
      paramsAfterSearch: {},
      multipleSelection: [],
      page: 1,
      pageSize: CONST.PAGINATION[0].value,
      total: 0,
      paginateFrom: 1,
      paginateTo: CONST.PAGINATION[0].value,
      listPaginate: CONST.PAGINATION,
      lastPage: 2,
      checkAll: false,
      isIndeterminate: false,
      loading: false,
      numberChecked: 0,
      status_error: "error",
      responseTime: "",
      paramsSearch: {},
      dialogFormVisible: false,
      form : {
        password: ""
      },
      rules: {
        password: [
          {
            required: true,
            message: "Vui lòng nhập mật khẩu.",
            trigger: "blur",
          },
        ]
      },
    };
  },
  computed: {
    ...mapGetters(["getMasterData"]),
  },
  methods: {
    async getList(params) {
      this.paramsSearch = { ...params };
      const data = await DegreeCooperatorRequest.getDegree(params);
      this.getData = data.data;
      this.page = data.current_page;
      this.pageSize = parseInt(data.per_page);
      this.total = data.total;
      this.paginateFrom = data.from;
      this.paginateTo = data.to;
      this.lastPage = parseInt(data.last_page);
      this.paramsAfterSearch = _.clone(this.params);
    },
    searchDegree() {
      this.params.page = 1;
      this.paramsSearch = {...this.params};
      this.getList(this.params);
      this.multipleSelection = [];
      this.checkAll = false;
    },
    async handleComfirmPassword(){
      let valid = await this.$refs['form'].validate();
      if (!valid) {
        return;
      }
      const params = {
        password: this.form.password,
        response_time: this.responseTime,
        check_all: this.checkAll,
        list_drafts: this.multipleSelection,
        ...this.paramsAfterSearch,
      };
      this.loading = true;
      const fullLoading = this.$loading({
        lock: true,
        text: 'Đang trong quá trình xử lý gửi lên Bộ GDĐT, vui lòng chờ ít phút.',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      DegreeCooperatorRequest.sendDegree(params, this.numberChecked)
        .then((res) => {
          let totalSuccess = res.data.success;
          let totalErr = res.data.error;
          let message = `Gửi lên Bộ GD&ĐT thành công. Có ${totalSuccess} yêu cầu gửi thành công và ${totalErr} yêu cầu gửi thất bại.`;
          this.showSuccess(message);
          this.getList(this.params);
          this.multipleSelection = [];
          this.numberChecked = 0;
          this.checkAll = false;
          this.dialogFormVisible = false
          this.loading = false;
        })
        .catch((err) => {
          this.showError(err.response.data.message);
          this.loading = false;
        })
        .finally(() => {
          fullLoading.close();
        });
    },
    async handleConfirmSend(msg) {
      if (this.multipleSelection.length || this.checkAll) {
          this.dialogFormVisible = true
      }
    },
    async handleConfirmDelete() {
      if (this.multipleSelection.length || this.checkAll) {
        if (await this.showConfirmMixin('Bạn có chắc chắn muốn xóa ' + this.numberChecked + ' văn bằng không?')) {
          const params = {
            response_time: this.responseTime,
            check_all: this.checkAll ? 1 : 0,
            list_drafts: this.multipleSelection,
            ...this.paramsAfterSearch,
          };
          this.loading = true;
          DegreeCooperatorRequest.deleteDegree(params, this.numberChecked)
            .then(() => {
              this.showSuccess("Xóa thành công.");
              this.getList(this.params);
              this.multipleSelection = [];
              this.numberChecked = 0;
              this.checkAll = false;
              this.loading = false;
            })
            .catch((err) => {
              this.showError(err.response.data.message);
              this.loading = false;
            })
            .finally(() => {
              if (this.checkAll) this.checkAll = false;
            });
        }
      }
    },
    exportDegree() {
      DegreeCooperatorRequest.exportDegree(this.paramsSearch).then((res) => {
        this.downloadFile(res, "Van_bang_thanh_cong");
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      const param = Object.assign(this.paramsSearch, {
        page: this.page,
      });
      this.getList(param);
    },
    setStartDate() {
      this.params.start_date = moment(String(this.params.start_date)).format(
        "YYYY/MM/DD"
      );
    },
    setEndDate() {
      this.params.end_date = moment(String(this.params.end_date)).format("YYYY/MM/DD");
    },
    totalChecked() {
      if (this.checkAll) {
        this.numberChecked = this.total;
        this.numberChecked -= this.multipleSelection.length;
      } else {
        this.numberChecked = this.multipleSelection.length;
      }
    },
    handleCheckAll(val) {
      this.multipleSelection = [];
      if (!this.checkAll) {
        this.$refs.table.clearSelection();
      }
      this.totalChecked();
      this.responseTime = moment().utc().format();
    },
    handleSelect(selection, row) {
      if (this.multipleSelection.includes(row.id)) {
        this.multipleSelection.splice(
          this.multipleSelection.indexOf(row.id),
          1
        );
      } else {
        this.multipleSelection.push(row.id);
      }
      this.totalChecked();
    },
    ChangePageSize(e) {
      this.params.limit = e;
      this.params.page = 1;
      this.getList(this.params);
    },
  },
  watch: {
    multipleSelection: function (newval, oldVal) {
      if (this.checkAll) {
        this.isIndeterminate = newval.length > 0 && newval.length < this.total;
      } else {
        this.isIndeterminate = false;
      }
      this.totalChecked();
    },
    dialogFormVisible:function (){
      this.$refs['form'].resetFields()
    }
  },
  created() {
    this.getList(this.params);
  },
  updated() {
    this.getData.forEach((row) => {
      if (this.multipleSelection.includes(row.id)) {
        this.$refs.table.toggleRowSelection(row, !this.checkAll);
      } else {
        this.$refs.table.toggleRowSelection(row, this.checkAll);
      }
    });
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
.degree-search {
  .el-form-item {
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: $color_mine_shaft;
    }
  }
}
.check-all {
  pointer-events: none;
  .el-checkbox__input {
    position: absolute;
    top: 77px;
    left: 35px;
    pointer-events: auto;
  }
  .el-checkbox__label {
    padding-left: 0px;
  }
  .el-checkbox.is-checked {
    .el-checkbox__label {
      color: $color_mine_shaft;
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/_variables";
.line {
  text-align: center;
}
.list-button {
  position: relative;
  .pagination-size {
    top: -30px;
  }
  .check-all {
    position: absolute;
    left: 0px;
    top: 20px;
    .el-checkbox {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $color_mine_shaft;
    }
  }
}
.warning-change-pass {
  padding-right: 10px;
  &:before {
    content: url("../../../assets/images/icons/warning-change-pass.svg");
  }
}
.custom_dialog {
  text-align: center;
  display: flex;
  align-items: center;
  /deep/.el-dialog{
    margin-top: unset;
    display: inline-block;
    width: 420px;
    padding-bottom: 10px;
    vertical-align: middle;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    font-size: 18px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    text-align: left;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  /deep/.el-dialog__header {
    position: relative;
    padding: 15px 15px 10px;

    .el-dialog__title {
      font-weight: bold;
    }
  }
  /deep/.el-dialog__body {
    padding: 0px 15px 10px;
    .el-form{
      border: 0;
      .el-form-item__label, .el-form-item__content{
        width: 100% !important;
        text-align: left !important;
        margin-left: 0 !important;
      }
    }
  }
  /deep/.el-dialog__footer {
    padding: 0px 15px 0;
  }
}
</style>
