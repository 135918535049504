import Request from '@/requests/Request';

class AccountAdminRequest extends Request {
    prefix = '/admin/account-admins/';

    async show(id) {
        let url = this.prefix + id;
        return await this.get(url);
    }

    async search(data) {
        let url = this.prefix;
        return await this.get(url, data);
    }

    async update(id, data) {
        let url = this.prefix + id;
        return await this.put(url, data);
    }

    async patch(id, data) {
        let url = this.prefix + id;
        return await super.patch(url, data);
    }

    async destroy(id) {
        let url = this.prefix + id;
        return await this.delete(url, {}, {
          'api-action': 'UserRequest-delete-admin'
        });
    }

    async destroyMulti(data) {
        let url = this.prefix;
        return await this.delete(url, data, {
          'api-action': 'UserRequest-deleteAll-admin'
        });
    }
}

export default AccountAdminRequest.getInstance()
