<template>
  <el-container>
    <app-header></app-header>
    <el-container class="admin-contain">
      <left-slide class="sidebar-left scroll-custom" v-if="user.type_user === CONST.TYPE_ADMIN"></left-slide>
      <left-side-menu-user
          class="sidebar-left scroll-custom"
          v-else-if="user.type_user === CONST.TYPE_ELEMENTARY_SCHOOL">
      </left-side-menu-user>
      <el-main class="el-main-custom scroll-custom">
        <router-view/>
      </el-main>
    </el-container>
    <app-footer></app-footer>
  </el-container>
</template>

<script>
  import Footer from "@/pages/Admin/Layouts/Footer";
  import Header from "@/pages/Admin/Layouts/Header";
  import LeftSlide from "@/pages/Admin/Layouts/LeftSlide";
  import LeftSideMenuUser from "@/pages/Admin/Layouts/LeftSideMenuUser";
  import {mapState} from 'vuex';
  import CONST from '@/config/const';

  export default {
    name: 'Main',
    components: {
      "app-footer": Footer,
      "app-header": Header,
      "left-slide": LeftSlide,
      "left-side-menu-user": LeftSideMenuUser,
    },
    data() {
      return {
        CONST: CONST,
      }
    },
    computed: {
      ...mapState(['user']),
    },
    async mounted() {
    }
  }
</script>

<style>
  body {
    overflow: hidden;
    padding-right: 0px !important;
  }

  .scroll-custom {
    max-height: calc(100vh - 120px);
  }

  .scroll-custom::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    border-radius: 10px;
  }

  .scroll-custom::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  .scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #FFF;
    background-image: -webkit-gradient(linear,
    40% 0%,
    75% 84%,
    from(#c0c4cc),
    to(#c0c4cc),
    color-stop(.6, #c0c4cc))
  }

  .el-main-custom {
    max-height: calc(100vh - 120px);
    /*overflow-y: scroll;*/
    /*width: 100vw;*/
  }

  .admin-contain {
    overflow: hidden;
    margin-top: 120px;
  }

  .el-container {
    min-height: 100vh;
    /* height: auto; */
  }
</style>
