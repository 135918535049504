<template>
  <div class="national-management">
    <template>
      <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>Quản lý danh mục</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'NationalManager' }">Quốc tịch</el-breadcrumb-item>
          <el-breadcrumb-item>{{$route.name == "nation.edit" ? 'Chỉnh sửa' : 'Thêm mới'}}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <!-- Form Search -->
    <el-form :model="params" class="create-edit-form" ref="form" :rules="rules">
      <p class="title-form">{{ nationalId ? 'Chỉnh sửa' : 'Thêm mới' }} quốc tịch</p>
      <el-row>
        <el-col :span="14">
          <el-form-item label="Mã" class="label-custom" prop="code">
            <el-input
                maxlength='8'
                class="custom-input"
                v-model="params.code"
                name="code"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="14">
          <el-form-item label="Quốc tịch" class="label-custom" prop="name">
            <el-input
                class="custom-input"
                v-model="params.name"
                name="name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p class="status-form">TÌNH TRẠNG HOẠT ĐỘNG</p>
      <el-row>
        <el-col :span="10">
          <el-form-item label="Tình trạng" class="label-custom">
            <el-switch
                v-model="params.status"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="btn-group-form">
        <el-button type="danger" class="btn" v-if="nationalId" @click="delData()" :disabled="hasPermissionOnScreen(PERMISSION_DELETE, this)">Xóa</el-button>
        <el-button type="primary" plain class="btn" v-if="nationalId" @click="reset()" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Đặt Lại</el-button>
        <el-button type="primary" class="btn" @click="submit()" v-if="nationalId" :disabled="hasPermissionOnScreen(PERMISSION_UPDATE, this)">Lưu</el-button>
        <el-button type="primary" class="btn" @click="submit()" v-else  :disabled="hasPermissionOnScreen(PERMISSION_CREATE, this)">Lưu</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import NationalRequest from "@/requests/NationalRequest";
export default {
  name: "Create",
  data(){
    return {
      params: {
        code:"",
        name:"",
        status: true
      },
      rules: {
        code: [
          {
            required: true,
            message: "Vui lòng nhập mã.",
            trigger: "blur",
          },
          {
            max: 50,
            message: "Mã không vượt quá 50 ký tự.",
            trigger: "blur",
          },
          {
            required: true,
            validator:this.checkExistCode,
            trigger: 'blur'
          },
          {
            pattern: /^[a-zA-Z0-9.\-_]+$/,
            message: "Mã chỉ chứa chữ cái tiếng Anh và chữ số.",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Vui lòng nhập quốc tịch.",
            trigger: "blur",
          },
          {
            max: 250,
            message: "Quốc tịch không vượt quá 250 ký tự.",
            trigger: "blur",
          },
        ]
      },
      temp: {},
      nationalId: this.$route.params.id || "",
    }
  },
  created() {
    if (this.nationalId) {
      NationalRequest.show(this.nationalId).then((res) => {
        this.params = res.data;
        this.params.status = !!res.data.status
        this.temp = _.clone(res.data)
      })
    }
  },
  methods: {
    async submit() {
      let valid = await this.$refs['form'].validate();
      if (!valid) {
        return;
      }
      const params = {
        ...this.params,
        'status': this.params.status ? 1 : 0
      }
      if (this.nationalId) {
        NationalRequest.edit(this.nationalId, params).then(() => {
          this.showSuccess('Cập nhật quốc tịch thành công.');
          this.$router.push({name: 'NationalManager'})
        })
        return;
      }
      NationalRequest.create(params).then(() => {
        this.showSuccess('Thêm mới quốc tịch thành công.');
        this.$router.push({name: 'NationalManager'})
      })
    },
    reset() {
      this.params = _.clone(this.temp);
    },
    async delData() {
      let checkConfirm = await this.showConfirmMixin(`Bạn có chắc chắn muốn xóa không?`);
      if (checkConfirm) {
        NationalRequest.destroy(this.params.id, this.params.code).then(() => {
          this.showSuccess('Xóa thành công.')
          this.$router.push({name: 'NationalManager'})
        }).catch(() => {
          this.showError('Lỗi khi xóa.');
        });
      }
    },
    checkExistCode(rule, value, callback) {
      if (this.params.code === this.temp.code) {
        callback();
      }
      NationalRequest.checkExist({'code': this.params.code}).then((res) => {
        if (res.data) {
          callback(new Error("Mã đã tồn tại."));
        } else {
          callback();
        }
      }).catch(() => {
        callback();
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.status-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #909399;
  margin-bottom: 24px;
}
.form-custom {
  margin-top: 24px;
  padding: 17px;
  .custom-input {
    width: 382px;
    height: 39px;
  }
  .fl-right {
    float: right;
  }
}
.btn-custom {
  width: 95px;
  height: 40px;
}
.label-custom {
  /deep/.el-form-item__label {
    text-align: left !important;
  }
}
</style>
