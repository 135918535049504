<template>
  <el-row>
    <el-col :span="24" class="group-search">
      <el-form label-width="140px" label-position="left">
        <p class="title-search">Lịch sử hoạt động</p>
        <el-row>
          <el-col :span="11">
            <el-form-item label="Loại hoạt động">
              <el-select style="width: 100%;" v-model="params.action" clearable placeholder="Tất cả">
                <el-option v-for="(item, key) in listAction" :label="$t(`${item}`)" :value="item" :key="key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="Thời gian">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="Ngày/tháng/năm" format="dd/MM/yyyy" value-format="yyyy-MM-dd"
                                style="width: 100%;" v-model="params.start_date">
                </el-date-picker>
              </el-col>
              <el-col class="line" :span="2">~</el-col>
              <el-col :span="11">
                <el-date-picker type="date" placeholder="Ngày/tháng/năm" format="dd/MM/yyyy" value-format="yyyy-MM-dd"
                                style="width: 100%;" v-model="params.end_date">
                </el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Người dùng">
              <el-select style="width: 100%;" v-model="params.user" clearable placeholder="Tất cả">
                <el-option v-for="(item, key) in listUser" :label="item.username" :value="item.username" :key="key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2" class="button-search">
            <el-form-item>
              <el-button class="btn-search" @click="searchHistoryImport()">Tìm</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
    <el-col class="list-button">
      <div class="pagination-size">
        <p>Số bản ghi trên trang: </p>
        <el-dropdown @command="ChangePageSize">
              <span class="el-dropdown-link">
              {{ params.limit }}
              <i class="el-icon-caret-bottom"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in listPaginate"
              :key="item.key"
              :command="item.value"
            >{{ item.value }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-col>

    <el-card>
      <el-table
        :data="getData"
        style="width: 100%"
        empty-text="Không có dữ liệu">
        <el-table-column
          prop="auth_user_username"
          label="Người dùng">
        </el-table-column>
        <el-table-column
          prop="api_action"
          label="Loại hoạt động">
          <template scope="scope">
            {{ $t(`${scope.row.api_action}`)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="response_status"
          label="Trạng thái">
          <template scope="scope">
            {{ scope.row.response_status === "200" ? 'Thành công' : 'Thất bại' }}
          </template>
        </el-table-column>
        <el-table-column
          label="Thời gian">
          <template scope="scope">
            {{ scope.row.time_iso | formatHour}}
          </template>
        </el-table-column>
        <el-table-column
          label="Ngày">
          <template scope="scope">
            {{ scope.row.time_iso | formatDate}}
          </template>
        </el-table-column>
      </el-table>
      <custom-el-pagination
        :length="total"
        :paginateFrom="paginateFrom"
        :paginateTo="paginateTo"
        :total="total"
        :pageSize="pageSize"
        :page="params.page"
        @handleCurrentChange="handleCurrentChange"
      ></custom-el-pagination>
    </el-card>
  </el-row>
</template>
<script>
import CONST from "@/config/const";
import HistoryRequest from "@/requests/HistoryRequest";
import UserRequest from "@/requests/UserRequest";
import { mapState } from 'vuex';

export default {
  data() {
    return {
      getData: [],
      params: {
        limit: CONST.PAGINATION[0].value,
        page: 1
      },
      pageSize: CONST.PAGINATION[0].value,
      total: 0,
      paginateFrom: 1,
      paginateTo: CONST.PAGINATION[0].value,
      listPaginate: CONST.PAGINATION,
      lastPage:2,
      listAction: [
        'ImDegreeCooperatorRequest-import',
        'DegreeCooperatorRequest-send',
        'DegreeCooperatorRequest-delete',
        'ExDegreeCooperatorRequest-exportError',
        'ExDegreeCooperatorRequest-export',
        'DegreeErrorCooperatorRequest-delete',
        'ExDegreeCooperatorRequest-exportPending',
        'ExDegreeCooperatorRequest-exportApprove',
        'ExDegreeCooperatorRequest-exportReject'
      ],
      listUser:[]
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    async getList() {
      const param = Object.assign(this.params, {
        organization_id: this.user.organization_id
      });
      const data = await HistoryRequest.getLogCooperator(this.params);
      this.getData = data.data.data;
      this.total = data.data.total;
      this.paginateFrom = data.data.from;
      this.paginateTo = data.data.to;
      this.page = data.data.current_page;
      this.pageSize = parseInt(data.data.per_page);
      this.lastPage = data.data.last_page;
    },
    searchHistoryImport() {
      this.params.page = 1;
      this.getList(this.params);
    },
    ChangePageSize(e) {
      this.params.limit = e;
      this.params.page = 1;
      this.getList(this.params);
    },
    handleCurrentChange(val) {
      this.page = val;
      const param = Object.assign(this.params, {
        page: this.page
      });
      this.getList(param);
    },
    async getListUser() {
      const data = await UserRequest.getListUser({organization_id: this.user.organization_id});
      this.listUser = data.data;
    }
  },
  created() {
    this.getList();
    this.getListUser();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/style";
.line {
  text-align: center;
}
.list-button {
  position: relative;
  .pagination-size {
    top: -30px ;
  }
  .check-all {
    position: absolute;
    left: 0px;
    top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $color_mine_shaft;
  }
}
</style>
