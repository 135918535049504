import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'PRODUCTION'

let rootStore = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    init() {
      this.dispatch('reloadMasterData');
    }
  },
}

const resources = require.context('.', true, /^\.\/(?!index\.js$)/);
resources.keys().map(file => {

  const data = resources(file);

  const storeData = data.default || data;
  const {actions, mutations, getters, state} = storeData;

  rootStore = {
    ...rootStore,
    actions: {
      ...rootStore.actions,
      ...actions,
    },
    getters: {
      ...rootStore.getters,
      ...getters,
    },
    mutations: {
      ...rootStore.mutations,
      ...mutations,
    },
    state: {
      ...rootStore.state,
      ...state,
    },
  }

});

const presistedOptions = {
  paths: [
    'currentSymbol',
    'currentIndexSymbol',
    'common',
    'orderbook',
    'user',
    'admin_menu',
    'admin_role_permissions',
    'masterdata'
  ]
}
const plugins = [createPersistedState(presistedOptions)];
const store = new Vuex.Store({
  ...rootStore,
  strict: debug,
  plugins: plugins,
});

export default store;