<script>
  import { Form } from 'element-ui';
  import CONST from '@/config/const';

  export default {
    name: 'CustomElementForm',
    extends: Form,
    props: {
      labelWidth: {
        type: String,
        default: '140px'
      }
    },
  }
</script>
