import EmailSettings from "@/pages/Admin/EmailSettings";

const RouteEmailSetting = [
    {
      path: '/admin/email-settings',
      component: EmailSettings,
      name: 'EmailSettings',
    },
];

export default RouteEmailSetting;
