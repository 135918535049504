<template>
  <div id="forgot" class="login">
    <el-card>
      <router-link class="forgot-password" to="/login">
        <el-button class="back" icon="el-icon-back" native-type="button" size="medium"></el-button>
      </router-link>
      <h3>QUÊN MẬT KHẨU</h3>
      <h5>Điền địa chỉ email để nhận mật khẩu mới</h5>
      <el-form
        class="forgot"
        :model="model"
        :rules="rules"
        ref="form"
        label-width="0px"
        @submit.native.prevent="updateForgotPassword"
      >
        <label style="float: left;"><span style="color: #F56C6C">* </span>Địa chỉ email</label>
        <el-form-item prop="email">
          <el-input v-model="model.email"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button"
            type="primary"
            native-type="submit"
            block
          >Gửi</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import CONST from "@/config/const"
import UserRequest from "@/requests/UserRequest";
export default {
  name: "forgot",
  data() {
    var checkExistEmail = (rule, value, callback) => {
      const em = {
        email: value,
      };
      const res = UserRequest.checkExistEmail(em).then((res) => {
        if (res.isExist) {
          callback(new Error("Địa chỉ email không tồn tại."));
        } else {
          callback();
        }
      });
    };
    const emailValid = (email) => {
      const emailRegex = CONST.EMAIL_REGEX;
      return emailRegex.test(email);
    };
    const checkEmailValid = (rule, value, callback) => {
      !emailValid(this.model.email)
        ? callback(new Error(this.$t("Địa chỉ email không hợp lệ.")))
        : callback();
    };
    return {
      model: {
        email: "",
        router: "/change-password",
      },
      loading: false,
      rules: {
        email: [
          {
            required: true,
            message: this.$t("Vui lòng nhập địa chỉ email."),
            trigger: "blur",
          },
          {
            required: true,
            validator: checkEmailValid,
            trigger: "blur",
          },
          {
            required: true,
            validator: checkExistEmail,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async updateForgotPassword() {
      let valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      try {
        await UserRequest.updateKey(this.model);
        this.showSuccess("Gửi email thành công.");
      } catch (e) {
        this.showError("Địa chỉ email không tồn tại.");
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
#forgot .el-card {
  .el-card__body {
    position: relative;
    padding: 48px 69px 65px;
    .back {
      position: absolute;
      top: 16px;
      left: 18px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 26px;
      color: #909399;
      padding: 0px;
    }
    .back:hover {
      background: #fff;
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
form.forgot {
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #363740;
    margin-bottom: 16px;
  }
}
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../assets/background.jpg") no-repeat center fixed;
  background-position: bottom;
  -webkit-background-size: cover;
  background-position: bottom;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-button {
  width: 100%;
  margin-top: 23px;
  background: #409eff;
  height: 50px;
  border: 1px solid #409eff;
  border-radius: 5px;
}
.forgot-password {
  text-align: right;
  border: none;
}
.back {
  border: none;
}

$teal: rgb(0, 124, 137);
.el-button--primary {
}
.login .el-input__inner:hover {
  border-color: $teal;
}
.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;
  .el-input__icon {
    width: 30px;
  }
}
.el-form {
  border: none;
}
.login .el-card {
  padding-top: 0;
}
h3 {
  align-items: center;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20.7px;
  margin: 0px;
  color: #363740;
  display: flex;
  justify-content: center;
}
h5 {
  margin: 15px 0px 56px;
  align-items: center;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16.1px;
  color: #909399;
  display: flex;
  justify-content: center;
}
a {
  color: $teal;
  text-decoration: none;
}
.login .el-card {
  width: 618px;
  justify-content: center;
}
</style>

