import DegreeImport from "@/pages/Cooperator/Degree/DegreeImport";
import DegreeSuccess from "@/pages/Cooperator/Degree/DegreeSuccess";
import WrapPage from "@/pages/Admin/WrapPage";
import DegreeDetail from "@/pages/Cooperator/Degree/DegreeDetail";
import DraftDegreeDetail from "@/pages/Cooperator/Degree/DraftDegreeDetail";
import DegreeResultPending from "@/pages/Cooperator/Degree/DegreeResult";
import DegreeResultApproved from "@/pages/Cooperator/Degree/DegreeResult";
import DegreeResultRejected from "@/pages/Cooperator/Degree/DegreeResult";
import DegreeError from "@/pages/Cooperator/Degree/DegreeError";

const RouteDegree = [
  {
    path: '/co-op/degree-error',
    component: DegreeError,
    name: 'DegreeError',
  },
  {
    path: '/co-op/degree-success',
    component: DegreeSuccess,
    name: 'DegreeSuccess',
  },
  {
    path: '/co-op/degree-import',
    name: 'DegreeImport',
    component: DegreeImport,
  },
  {
    path: '/co-op/degree-result-pending',
    name: 'DegreeResultPending',
    component: DegreeResultPending,
  },
  {
    path: '/co-op/degree-result-rejected',
    name: 'DegreeResultRejected',
    component: DegreeResultRejected,
  },
  {
    path: '/co-op/degree-result-approved',
    name: 'DegreeResultApproved',
    component: DegreeResultApproved,
  },
  {
    path: '/co-op/degree-detail',
    name: 'DegreeDetail',
    component: DegreeDetail,
  },
  {
    path: '/co-op/draft-degree-detail',
    name: 'DraftDegreeDetail',
    component: DraftDegreeDetail,
  },
];

export default RouteDegree;
