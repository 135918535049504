import Request from '@/requests/Request';
import CONST from "@/config/const";

class CooperatorRequest extends Request {
  prefix = '/admin/degree-verify/';

  async get(data) {
    let url = this.prefix;
    return await super.get(url, data);
  }

  async getHistory(data) {
    let url = 'admin/crawl-history';
    return await super.get(url, data);
  }

  async show(id) {
    let url = this.prefix + encodeURIComponent(id);
    return await super.get(url);
  }

  async post(data) {
    let url = this.prefix;
    return await super.post(url, data);
  }

  async put(id, data) {
    let url = this.prefix + id;
    return await super.put(url, data);
  }

  async patch(id, data) {
    let url = this.prefix + id;
    return await super.patch(url, data);
  }

  async patchMulti(data, num= 0) {
    let url = this.prefix;
    let actionName = '';
    if (data._status === 1) {
      actionName = 'CooperatorRequest-Approved';
    } else {
      actionName = 'CooperatorRequest-Reject';
    }
    return await super.patch(url, data, {
      'api-action': actionName,
      'api-action-meta': num
    });
  }


  async delete(id) {
    let url = this.prefix + id;
    return await super.delete(url);
  }

  async destroy(data) {
    let url = this.prefix;
    return await super.delete(url, data);
  }

  async export(data) {
    let url = 'admin/export/degree-verify';
    let actionName = '';
    switch (data.status) {
      case 1:
        actionName = 'ExCooperatorRequest-AdminApproved-export';
        break;
      case 2:
        actionName = 'ExCooperatorRequest-AdminReject-export';
        break;
      default:
        actionName = 'ExCooperatorRequest-AdminPending-export';
    }
    return await super.get(url, data, {
      'api-action': actionName,
    });
  }

  async crawlDegree(data) {
    let url = 'admin/crawl-degree';
    return await super.post(url, data);
  }

  async triggerDegree() {
    let url = 'admin/trigger-crawl';
    return await super.get(url);
  }

}

export default CooperatorRequest.getInstance()
