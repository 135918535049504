<template>
	<el-row>
		<!-- Form Search -->
		<el-col class="group-search">
			<el-form label-position="left" :model="search">
				<p class="title-search">Quản lý trình độ</p>
				<el-row>
					<el-col :sm="24" :md="11">
						<el-form-item label="Mã">
							<el-input
								@keyup.enter.native="handleSearch()"
								v-model="search.code"
								placeholder
								maxlength="8"
							></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24" :md="{span: 11, push: 2}">
						<el-form-item label="Trình độ">
							<el-input
								@keyup.enter.native="handleSearch()"
								v-model="search.name"
								placeholder
								maxlength="250"
							></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24" class="button-search">
						<el-form-item>
							<el-button type="primary" class="btn" @click="handleSearch()">Tìm</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-col>
		<!-- Button Action -->
		<el-col class="list-button">
			<div class="pagination-size">
				<p>Số bản ghi trên trang:</p>
				<el-dropdown @command="handleCommand">
					<span class="el-dropdown-link">
						{{ search.pageSize }}
						<i class="el-icon-caret-bottom"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item
							v-for="item in listPaginate"
							:key="item.key"
							:command="item.value"
						>{{ item.value }}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div>
				<el-button
					type="success"
					class="btn"
					@click="$router.push({name: 'CertificateMangementCreate'})"
					:disabled="hasPermissionOnScreen(PERMISSION_CREATE)"
				>
					<span>Thêm Mới</span>
				</el-button>
				<el-button
					type="danger"
					class="btn"
					disabled
					v-show="multipleSelection.length === 0"
					@click="handleConfirm()"
				>Xóa</el-button>
				<el-button
					type="danger"
					class="btn"
					v-show="multipleSelection.length !== 0"
					@click="handleConfirm()"
					:disabled="hasPermissionOnScreen(PERMISSION_DELETE)"
				>Xóa</el-button>
			</div>
		</el-col>

		<el-card shadow="never" class="mt3" v-loading="loading">
			<!-- Table Data -->
			<el-table ref="table" @selection-change="handleSelectionChange" :data="data.data" style="width: 100%">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column property="code" label="Mã"></el-table-column>
				<el-table-column property="name" label="Trình độ"></el-table-column>
				<el-table-column label="Tình trạng">
					<template slot-scope="scope">
						<el-switch
							@change="handleStatus(scope.$index, scope.row, $event)"
							v-model="scope.row.status"
							:disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column align="right" label>
					<template slot-scope="scope">
						<el-button
							@click="$router.push({name: 'CertificateMangementEdit', params: {id: scope.row.id}})"
							type="info"
							icon="el-icon-edit"
							size="mini"
							circle
							:disabled="hasPermissionOnScreen(PERMISSION_UPDATE)"
						></el-button>
						<el-button
							@click="multipleSelection2 = [scope.row.id]; handleConfirm2(scope.row.code)"
							type="danger"
							icon="el-icon-delete"
							size="mini"
							circle
							:disabled="hasPermissionOnScreen(PERMISSION_DELETE)"
						></el-button>
					</template>
				</el-table-column>
			</el-table>
			<custom-el-pagination
				:length="data.total || 0"
				:paginateFrom="data.from || 1"
				:paginateTo="data.to || 3"
				:total="data.total || 0"
				:pageSize="data.per_page || 3"
				:page="data.current_page || 1"
				@handleCurrentChange="handleChangePage"
			></custom-el-pagination>
		</el-card>
	</el-row>
</template>

<script>
	import DegreeRequest from "../api/DegreeRequest";
	import CONST from "@/config/const";

	export default {
		name: "SearchCertificate",
		data() {
			return {
				loading: false,
				search: {
					code: "",
					name: "",
					pageSize: CONST.PAGINATION[0].value,
				},
				data: [],
				sortParams: [],
				multipleSelection: [],
				multipleSelection2: [],
				listPaginate: CONST.PAGINATION,
        paramsSearch: {},
			};
		},
		methods: {
			async handleSearch() {
				this.loading = true;
				try {
          this.paramsSearch = {...this.search}
					let res = await DegreeRequest.get(this.search);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			async handleConfirm() {
				if (this.multipleSelection.length) {
					if (await this.showConfirmMixin()) {
						this.handleDelete();
					}
				}
			},
			async handleConfirm2(code) {
				if (this.multipleSelection2.length) {
					if (await this.showConfirmMixin()) {
						this.handleDelete2(code);
					}
				}
			},
			async handleDelete() {
				this.loading = true;
				try {
					let res = await DegreeRequest.destroy(
						this.multipleSelection,
					);
					let param = {
						...this.paramsSearch,
						page:
							this.data.current_page < this.data.last_page
								? this.data.current_page
								: this.data.data.length !=
								  this.multipleSelection.length
								? this.data.last_page
								: this.data.last_page - 1,
					};
					res = await DegreeRequest.get(param);
					this.data = res.data;
					this.showSuccess("Xóa thành công.");
				} catch (error) {
					this.showError("Lỗi khi xóa.");
				}
				this.loading = false;
				this.multipleSelection = [];
			},
			async handleDelete2(code) {
				this.loading = true;
				try {
					let res = await DegreeRequest.destroy(
						this.multipleSelection2,
						code
					);
					let param = {
						...this.paramsSearch,
						page:
							this.data.current_page < this.data.last_page
								? this.data.current_page
								: this.data.data.length !=
								  this.multipleSelection2.length
								? this.data.last_page
								: this.data.last_page - 1,
					};
					res = await DegreeRequest.get(param);
					this.data = res.data;
					this.showSuccess("Xóa thành công.");
				} catch (error) {
					this.showError("Lỗi khi xóa.");
				}
				this.loading = false;
				this.multipleSelection2 = [];
			},
			async handleStatus(index, row, status) {
				try {
					let res = await DegreeRequest.patch(row.id, {
						status: row.status,
					});
					this.showSuccess("Thành công.");
				} catch (error) {
          this.showError("Thất bại.");
          row.status = !status;
        }
			},
			async handleChangePage(page) {
				this.loading = true;
				let param = { ...this.paramsSearch, page: page };
				try {
					let res = await DegreeRequest.get(param);
					this.data = res.data;
				} catch (error) {}
				this.loading = false;
			},
			handleSelectionChange(val) {
				let ids = val.map(function (i) {
					return i.id;
				});
				this.multipleSelection = ids;
			},
			async handleCommand(command) {
				this.search.pageSize = command;
				this.paramsSearch.pageSize = command;
				this.loading = true;
				try {
					let res = await DegreeRequest.get(this.paramsSearch);
					this.data = res.data;
				} catch (e) {}
				this.loading = false;
			},
		},
		async created() {
			this.loading = true;
			try {
			  this.paramsSearch = {...this.search}
				let res = await DegreeRequest.get(this.search);
				this.data = res.data;
			} catch (error) {}
			this.loading = false;
		},
	};
</script>

<style scoped>
</style>
