import CreateDepartment from "@/pages/Admin/departments/CreateDepartment";
import ListDepartment from "@/pages/Admin/departments/ListDepartment";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteDepartmentManagement = [
  {
    path: '/admin/department-management',
    component: WrapPage,
    name: 'DepartmentMan',
    children: [
      {
        path: 'list',
        component: ListDepartment,
        name: 'DepartmentMan',
      },
      {
        path: 'create',
        component: CreateDepartment,
        name: 'department.create',
      },
      {
        path: 'edit/:id',
        component: CreateDepartment,
        name: 'department.edit',
      },
    ]
  },
];

export default RouteDepartmentManagement;