import LanguageList from "@/pages/Admin/Languages/LanguageList";
import LanguageCreateUpdate from "@/pages/Admin/Languages/LanguageCreateUpdate";
import WrapPage from "@/pages/Admin/WrapPage";

const RouteLanguage = [
    {
        path: '/admin/languages',
        component: WrapPage,
        name: 'Language',
        children: [
          {
            path: '',
            name: 'Language',
            component: LanguageList,
          }, {
            path: 'create',
            name: 'LanguageCreate',
            component: LanguageCreateUpdate,
          }, {
            path: ':id/edit',
            name: 'LanguageEdit',
            component: LanguageCreateUpdate,
          },
        ],
      },
];

export default RouteLanguage;