import SearchPending from "./views/SearchPending";
import SearchApproved from "./views/SearchApproved";
import SearchReject from "./views/SearchReject";
import CrawlHistory from "./views/CrawlHistory";
import DetailDegree from "./views/DegreeDetail";
import WrapPage from "@/pages/Admin/WrapPage";

const CooperatorCertificate = [
  {
    path: "/admin/cooperator-pending",
    component: WrapPage,
    name: 'CooperatorPending',
    children: [
      {
        path: '',
        component: SearchPending,
        name: 'CooperatorPending',
      },
      {
        path: 'detail',
        component: DetailDegree,
        name: 'PendingDegree',
      },
    ],
  },
  {
    path: "/admin/cooperator-approved",
    component: WrapPage,
    name: 'CooperatorApproved',
    children: [
      {
        path: '',
        components:{
          wrap_keep_alive:  SearchApproved
        },
        name: 'CooperatorApproved',
      },
      {
        path: 'detail',
        component: DetailDegree,
        name: 'ApprovedDegree',
      },
    ],
  },
  {
    path: "/admin/cooperator-reject",
    component: WrapPage,
    name: 'CooperatorReject',
    children: [
      {
        path: '',
        component: SearchReject,
        name: 'CooperatorReject',
      },
      {
        path: 'detail',
        component: DetailDegree,
        name: 'RejectDegree',
      },
    ],
  },
  {
    path: "/admin/crawl-history",
    component: WrapPage,
    name: 'CrawlHistory',
    children: [
      {
        path: '',
        component: CrawlHistory,
        name: 'CrawlHistory'
      }
    ]
  },
];

export default CooperatorCertificate;
